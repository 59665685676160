import { useSelector } from 'react-redux';
import { Route, Routes } from 'react-router';
import { useLocation } from 'react-router-dom';
import ForbiddenPage from 'ringlead_utils/components/ForbiddenPage';
import NotFoundPage from 'ringlead_utils/components/NotFoundPage';
import RingLeadLoadable from 'ringlead_utils/components/RingLeadLoadable/RingLeadLoadable';

import { hasFeatureAccess } from '../../admin/helpers/AccessHelper';

const TaskList = RingLeadLoadable({
	loader: () => import('../../dop/components/tasks/TaskList'),
});

const TaskDetail = RingLeadLoadable({
	loader: () => import('../../dop/components/tasks/detail/TaskDetail'),
});

const DeduplicationResolution = RingLeadLoadable({
	loader: () => import('../../dop/components/resolution/deduplication/DeduplicationResolution'),
});

const EnrichmentResolution = RingLeadLoadable({
	loader: () => import('../../dop/components/resolution/enrichment/EnrichmentResolution'),
});

const MassDeleteResolution = RingLeadLoadable({
	loader: () => import('../../dop/components/resolution/mass_delete/MassDeleteResolution'),
});

const MassUpdateResolution = RingLeadLoadable({
	loader: () => import('../../dop/components/resolution/mass_update/MassUpdateResolution'),
});

const ListImportResolution = RingLeadLoadable({
	loader: () => import('../../dop/components/resolution/list_import/ListImportResolution'),
});

function Resolution() {
	const { pathname } = useLocation();

	if (pathname.startsWith('/enrichment') || pathname.startsWith('/normalization')) {
		return <EnrichmentResolution />;
	}

	if (pathname.startsWith('/deduplication')) {
		return <DeduplicationResolution />;
	}

	if (pathname.startsWith('/mass-delete')) {
		return <MassDeleteResolution />;
	}

	if (pathname.startsWith('/mass-update')) {
		return <MassUpdateResolution />;
	}

	if (pathname.startsWith('/list-import')) {
		return <ListImportResolution />;
	}

	return null;
}

function TaskRoutes({ features, permission }) {
	const hasPermissions = useSelector(
		state =>
			!!features.find(feature => hasFeatureAccess(state.auth.user, [feature], null)) &&
			hasFeatureAccess(state.auth.user, null, permission)
	);

	if (!hasPermissions) {
		return <ForbiddenPage features={features} permissions={permission} />;
	}

	return (
		<Routes>
			<Route index element={<TaskList />} />
			<Route path=":taskId/resolution/:page?" element={<Resolution />} />
			<Route path=":taskId/*" element={<TaskDetail />} />
			<Route path="*" element={<NotFoundPage />} />
		</Routes>
	);
}

export default TaskRoutes;
