import { LOCATION_CHANGE } from 'app/Router';
import concat from 'lodash-es/concat';
import filter from 'lodash-es/filter';
import find from 'lodash-es/find';
import includes from 'lodash-es/includes';
import map from 'lodash-es/map';
import pickBy from 'lodash-es/pickBy';
import reduce from 'lodash-es/reduce';

import { MasterRecordRulesConstants } from '../Constants';

const initialState = {
	masterRecordRules: [],
	fetching: true,
	errors: {},
};

export default (state = initialState, action = {}) => {
	switch (action.type) {
		case LOCATION_CHANGE:
			return initialState;

		case MasterRecordRulesConstants.LOAD_MASTER_RECORD_RULES_SUCCESS:
			return { ...state, masterRecordRules: action.data, fetching: false };

		case MasterRecordRulesConstants.LOAD_MASTER_RECORD_RULES_FAIL:
			return { ...state, masterRecordRules: [], fetching: false };

		case MasterRecordRulesConstants.SYNC_MASTER_RECORD_RULES_SUCCESS:
			return {
				...state,
				masterRecordRules: [...action.data, ...action.invalidRules],
				errors: reduce(
					action.invalidRules,
					(result, rule, index) => ({ ...result, [rule.tmpId]: action.errors[index] || {} }),
					{}
				),
			};

		case MasterRecordRulesConstants.CREATE_MASTER_RECORD_RULE_SUCCESS:
			return {
				...state,
				masterRecordRules: find(state.masterRecordRules, ['tmpId', action.rule.tmpId])
					? map(state.masterRecordRules, masterRecordRule =>
							masterRecordRule.tmpId === action.rule.tmpId
								? { ...masterRecordRule, ...action.data }
								: masterRecordRule
						)
					: concat([], state.masterRecordRules, action.data),
				errors: pickBy(state.errors, (_v, k) => k !== action.rule.tmpId),
			};

		case MasterRecordRulesConstants.CREATE_MASTER_RECORD_RULE_FAIL:
			return {
				...state,
				masterRecordRules: includes(map(state.masterRecordRules, 'tmpId'), action.rule.tmpId)
					? map(state.masterRecordRules, masterRecordRule =>
							masterRecordRule.tmpId === action.rule.tmpId
								? { ...masterRecordRule, ...action.rule }
								: masterRecordRule
						)
					: concat([], state.masterRecordRules, action.rule),
				errors: { ...state.errors, [action.rule.tmpId]: action.data },
			};

		case MasterRecordRulesConstants.PATCH_MASTER_RECORD_RULE_SUCCESS:
			return {
				...state,
				masterRecordRules: map(state.masterRecordRules, masterRecordRule =>
					action.data.id === masterRecordRule.id ? { ...masterRecordRule, ...action.data } : masterRecordRule
				),
				errors: pickBy(state.errors, (_v, k) => k !== action.rule.id.toString()),
			};

		case MasterRecordRulesConstants.PATCH_MASTER_RECORD_RULE_FAIL:
			return {
				...state,
				masterRecordRules: map(state.masterRecordRules, masterRecordRule =>
					action.rule.id === masterRecordRule.id ? { ...masterRecordRule, ...action.rule } : masterRecordRule
				),
				errors: { ...state.errors, [action.rule.id]: action.data },
			};

		case MasterRecordRulesConstants.REORDER_MASTER_RECORD_RULES:
			return {
				...state,
				masterRecordRules: map(state.masterRecordRules, masterRecordRule => {
					const actionRule = find(
						action.rules,
						r => (masterRecordRule.id || masterRecordRule.tmpId) === (r.id || r.tmpId)
					);
					return actionRule ? { ...masterRecordRule, priority: actionRule.priority } : masterRecordRule;
				}),
			};

		case MasterRecordRulesConstants.DELETE_MASTER_RECORD_RULE_SUCCESS:
			return {
				...state,
				masterRecordRules: filter(
					state.masterRecordRules,
					masterRecordRule => masterRecordRule.id !== action.id && masterRecordRule.tmpId !== action.id
				),
				errors: pickBy(state.errors, (_v, k) => action.id.toString() !== k),
			};

		case MasterRecordRulesConstants.DELETE_MASTER_RECORD_RULE_FAIL:
			return state;

		default:
			return state;
	}
};
