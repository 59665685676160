import { Component } from 'react';
import { connect } from 'react-redux';
import { Navigate, ScrollRestoration } from 'react-router-dom';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import { LDProvider } from 'launchdarkly-react-client-sdk';
import size from 'lodash-es/size';
import withLocation from 'ringlead_utils/decorators/withLocation';

import { environment } from '../environments/environment';

import { getPusherPrivateChannelName } from './auth/selectors/PusherSelectors';
import { logout, updateUser } from './auth/services/authSlice';
import { enrichmentApi } from './dop/services/enrichment';
import pusher from './integrations/pusher/pusher';
import { featureApi } from './licensing/services/feature';
import Footer from './navigation/components/Footer';
import Navbar from './navigation/components/Navbar';
import WelcomeModal from './navigation/components/WelcomeModal';
import { withErrorBoundary } from './utils/components/ErrorBoundary';
import NProgressBar from './utils/components/NProgressBar';
import Preloader from './utils/components/Preloader';
import Routing from './utils/routes/Routes';
import LDClientClose from './LDClientClose';

const Main = styled('main', {
	shouldForwardProp: prop => prop !== 'open',
})(({ theme, open }) => ({
	flexGrow: 1,
	padding: theme.spacing(3),
	transition: theme.transitions.create(
		'margin',
		open
			? {
					easing: theme.transitions.easing.easeOut,
					duration: theme.transitions.duration.enteringScreen,
				}
			: {
					easing: theme.transitions.easing.sharp,
					duration: theme.transitions.duration.leavingScreen,
				}
	),
	marginBottom: 48,
	width: open ? 'calc(100% - 240px)' : 'calc(100% - 65px)',
}));

const DrawerHeader = styled(Box)(({ theme }) => ({
	display: 'flex',
	alignItems: 'center',
	padding: '0 8px',
	...theme.mixins.toolbar,
	justifyContent: 'flex-end',
}));

const LD_OPTIONS = { privateAttributes: ['email'] };

@withLocation
@connect(state => ({
	token: state.auth.token,
	user: state.auth.user,
	pusherChannelId: getPusherPrivateChannelName(state),
	open: state.navbar.open,
}))
@withErrorBoundary
class Root extends Component {
	componentDidMount() {
		const { token, pusherChannelId } = this.props;

		if (!token) {
			this.props.dispatch(logout());
		} else {
			if (pusherChannelId) {
				pusher.subscribe(pusherChannelId).bind('increment_feature_usage', this.incrementFeatureUsage);
			}

			this.props.dispatch(featureApi.endpoints.getFeatures.initiate());
			this.props.dispatch(enrichmentApi.endpoints.getEnrichmentPackages.initiate());
			this.props.dispatch(enrichmentApi.endpoints.getEnrichmentFieldNames.initiate());
		}
	}

	componentDidUpdate(prevProps) {
		const { token, pusherChannelId } = this.props;

		if (token && pusherChannelId && pusherChannelId && !prevProps.pusherChannelId) {
			pusher.subscribe(pusherChannelId).bind('increment_feature_usage', this.incrementFeatureUsage);
		}
	}

	componentWillUnmount() {
		const { token, pusherChannelId } = this.props;

		if (token) {
			pusher.unsubscribe('public');
			if (pusherChannelId) {
				pusher.subscribe(pusherChannelId).unbind('increment_feature_usage', this.incrementFeatureUsage);
				pusher.unsubscribe(pusherChannelId);
			}
		}
	}

	shouldComponentUpdate(nextProps) {
		return (
			this.props.open !== nextProps.open ||
			this.props.token !== nextProps.token ||
			size(this.props.user) !== size(nextProps.user)
		);
	}

	incrementFeatureUsage = data => {
		const { user } = this.props;

		if (!user || !user.usage_and_limits_state || !user.usage_and_limits_state[data.feature_name]) {
			return;
		}

		this.props.dispatch(
			updateUser({
				usage_and_limits_state: {
					...user.usage_and_limits_state,
					[data.feature_name]: {
						...user.usage_and_limits_state[data.feature_name],
						used: user.usage_and_limits_state[data.feature_name].used + data.used,
					},
				},
			})
		);
	};

	render() {
		if (!this.props.token) {
			return (
				<Navigate
					to={
						this.props.location.pathname !== '/'
							? `/auth/login?next=${this.props.location.pathname}`
							: '/auth/login'
					}
				/>
			);
		}

		if (size(this.props.user) === 0) {
			return <Preloader />;
		}

		const { user, open } = this.props;

		const ldContext = {
			kind: 'user',
			key: user.id.toString(),
			avatar: user.avatar,
			firstName: user.first_name,
			lastName: user.last_name,
			Organization: user.organization.name,
			email: user.email,
		};

		return (
			<LDProvider
				clientSideID={environment.launchDarklyClientSideID}
				options={LD_OPTIONS}
				timeout={5}
				context={ldContext}
			>
				<Box display="flex">
					<ScrollRestoration />
					<NProgressBar />
					<Navbar />
					<Main open={open}>
						<DrawerHeader />
						{Routing}
					</Main>
					<Footer />
					<WelcomeModal />
					<LDClientClose />
				</Box>
			</LDProvider>
		);
	}
}

export default Root;
