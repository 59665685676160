import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import type { RootState } from 'app/utils/stores/store-tmp-type';

function WelcomeModal() {
	const [open, setOpen] = useState<boolean>(false);

	const isFirstLogin = useSelector((state: RootState) => {
		const { user } = state.auth;
		return user && user.is_first_login && !user.is_masked;
	});

	useEffect(() => {
		if (isFirstLogin) {
			setOpen(true);
		}
	}, []);

	return (
		<Dialog open={open} onClose={() => setOpen(false)}>
			<DialogTitle>Welcome!</DialogTitle>
			<DialogContent>
				<DialogContentText>
					Welcome to RingLead!
					<br />
					To learn how to setup and use RingLead features and functions, click the Help Center link at the top
					of the window.
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button onClick={() => setOpen(false)} color="primary">
					OK
				</Button>
			</DialogActions>
		</Dialog>
	);
}

export default WelcomeModal;
