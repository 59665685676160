import { Route, Routes } from 'react-router';
import ForbiddenPage from 'ringlead_utils/components/ForbiddenPage';
import NotFoundPage from 'ringlead_utils/components/NotFoundPage';
import RingLeadLoadable from 'ringlead_utils/components/RingLeadLoadable/RingLeadLoadable';

import useHasFeatureAccess from '../../admin/hooks/useHasFeatureAccess';

const TaskList = RingLeadLoadable({
	loader: () => import('../../dop/components/tasks/TaskList'),
});

const Discovery = RingLeadLoadable({
	loader: () => import('../../dop/components/tasks/discovery/searches/Discovery'),
});

function DiscoverySearchRoutes() {
	const hasPermissions = useHasFeatureAccess(null, 'read:discovery_task');

	if (!hasPermissions) {
		return <ForbiddenPage permissions={['read:discovery_task']} />;
	}

	return (
		<Routes>
			<Route index element={<TaskList />} />
			<Route path=":taskId" element={<Discovery />} />
			<Route path="*" element={<NotFoundPage />} />
		</Routes>
	);
}

export default DiscoverySearchRoutes;
