import { Route, Routes } from 'react-router';
import useHasFeatureAccess from 'app/admin/hooks/useHasFeatureAccess';
import ForbiddenPage from 'ringlead_utils/components/ForbiddenPage';
import NotFoundPage from 'ringlead_utils/components/NotFoundPage';
import RingLeadLoadable from 'ringlead_utils/components/RingLeadLoadable/RingLeadLoadable';

const RoutingWorkFlowList = RingLeadLoadable({
	loader: () => import('../../routing/components/routing/workflows/List'),
});

const RoutingWorkFlowDetails = RingLeadLoadable({
	loader: () => import('../../routing/components/routing/workflows/Details'),
});

function RoutingWorkflowRoutes() {
	const hasPermissions = useHasFeatureAccess(null, 'read:workflow');

	if (!hasPermissions) {
		return <ForbiddenPage permissions={['read:workflow']} />;
	}

	return (
		<Routes>
			<Route index element={<RoutingWorkFlowList />} />
			<Route path=":dtId/:activeTab?" element={<RoutingWorkFlowDetails />} />
			<Route path="*" component={<NotFoundPage />} />
		</Routes>
	);
}

export default RoutingWorkflowRoutes;
