import { LOCATION_CHANGE } from 'app/Router';
import concat from 'lodash-es/concat';
import filter from 'lodash-es/filter';
import find from 'lodash-es/find';
import map from 'lodash-es/map';
import pickBy from 'lodash-es/pickBy';
import reduce from 'lodash-es/reduce';

import { MatchingRulesConstants } from '../Constants';

const initialState = {
	matchingRules: [],
	fetching: true,
	errors: {},
};

export default (state = initialState, action = {}) => {
	switch (action.type) {
		case LOCATION_CHANGE:
			return initialState;

		case MatchingRulesConstants.LOAD_MATCHING_RULES_SUCCESS:
			return { ...state, matchingRules: action.data, fetching: false };

		case MatchingRulesConstants.LOAD_MATCHING_RULES_FAIL:
			return { ...state, matchingRules: [], fetching: false };

		case MatchingRulesConstants.SYNC_MATCHING_RULES_SUCCESS:
			return {
				...state,
				matchingRules: [...action.data, ...action.invalidRules],
				errors: reduce(
					action.invalidRules,
					(result, rule, index) => ({ ...result, [rule.tmpId]: action.errors[index] || {} }),
					{}
				),
			};

		case MatchingRulesConstants.CREATE_MATCHING_RULE_SUCCESS:
			return {
				...state,
				matchingRules: find(state.matchingRules, ['tmpId', action.rule.tmpId])
					? map(state.matchingRules, matchingRule =>
							matchingRule.tmpId === action.rule.tmpId
								? { ...matchingRule, ...action.data }
								: matchingRule
						)
					: concat([], state.matchingRules, action.data),
				errors: pickBy(state.errors, (_v, k) => k !== action.rule.tmpId),
			};

		case MatchingRulesConstants.CREATE_MATCHING_RULE_FAIL:
			return {
				...state,
				matchingRules: find(state.matchingRules, ['tmpId', action.rule.tmpId])
					? map(state.matchingRules, matchingRule =>
							matchingRule.tmpId === action.rule.tmpId
								? { ...matchingRule, ...action.rule }
								: matchingRule
						)
					: concat([], state.matchingRules, action.rule),
				errors: { ...state.errors, [action.rule.tmpId]: action.data },
			};

		case MatchingRulesConstants.PATCH_MATCHING_RULE_SUCCESS:
			return {
				...state,
				matchingRules: map(state.matchingRules, matchingRule =>
					matchingRule.id === action.data.id ? { ...matchingRule, ...action.data } : matchingRule
				),
				errors: pickBy(state.errors, (_v, k) => k !== action.rule.id.toString()),
			};

		case MatchingRulesConstants.PATCH_MATCHING_RULE_FAIL:
			return {
				...state,
				matchingRules: map(state.matchingRules, matchingRule =>
					matchingRule.id === action.rule.id ? { ...matchingRule, ...action.rule } : matchingRule
				),
				errors: { ...state.errors, [action.rule.id]: action.data },
			};

		case MatchingRulesConstants.REORDER_MATCHING_RULES:
			return {
				...state,
				matchingRules: map(state.matchingRules, matchingRule => {
					const actionRule = find(
						action.rules,
						r => (matchingRule.id || matchingRule.tmpId) === (r.id || r.tmpId)
					);
					return actionRule ? { ...matchingRule, order: actionRule.order } : matchingRule;
				}),
			};

		case MatchingRulesConstants.DELETE_MATCHING_RULE_SUCCESS:
			return {
				...state,
				matchingRules: filter(
					state.matchingRules,
					matchingRule => matchingRule.id !== action.id && matchingRule.tmpId !== action.id
				),
				errors: pickBy(state.errors, (_v, k) => k !== action.id.toString()),
			};

		case MatchingRulesConstants.DELETE_MATCHING_RULE_FAIL:
			return state;

		default:
			return state;
	}
};
