import { createApi } from '@reduxjs/toolkit/query/react';
import baseQueryWithNProgress from 'app/utils/services/baseQueryWithNProgress';

import type { CreatePackagePayload, CustomPackage } from '../types/packageTypes';

export const packageApi = createApi({
	reducerPath: 'packageApi',
	baseQuery: baseQueryWithNProgress,
	endpoints: builder => ({
		getPackages: builder.query<CustomPackage[], void>({
			query: () => '/data_exchange/packages/',
		}),
		createPackage: builder.mutation<CustomPackage, CreatePackagePayload>({
			query: ({ mode, name, selectedVendorsApis, jsonString, file }) => {
				let body;
				if (mode === 'create') {
					body = {
						vendors_api: selectedVendorsApis,
						package_name: name,
						mode,
					};
				} else {
					body = new FormData();
					body.append('vendors_api', JSON.stringify(selectedVendorsApis));
					body.append('package_name', name);
					body.append('file', file);
					body.append('jsonString', jsonString);
					body.append('mode', mode);
				}

				return {
					url: '/data_exchange/packages/',
					method: 'POST',
					body,
					formData: true,
				};
			},

			async onQueryStarted(_, { dispatch, queryFulfilled }) {
				try {
					const { data } = await queryFulfilled;
					dispatch(
						packageApi.util.updateQueryData('getPackages', undefined, draft => {
							draft.push(data);
						})
					);
				} catch {
					/* empty */
				}
			},
		}),
		copyPackage: builder.mutation<CustomPackage, { id: number; name: string }>({
			query: ({ id, name }) => ({
				url: `/data_exchange/packages/${id}/copy/`,
				method: 'POST',
				body: { package_name: name },
			}),
			async onQueryStarted(_, { dispatch, queryFulfilled }) {
				try {
					const { data } = await queryFulfilled;
					dispatch(
						packageApi.util.updateQueryData('getPackages', undefined, draft => {
							draft.unshift(data);
						})
					);
				} catch {
					/* empty */
				}
			},
		}),
		removePackage: builder.mutation<void, number>({
			query: id => ({
				url: `/data_exchange/packages/${id}/`,
				method: 'DELETE',
			}),
			onQueryStarted(id, { dispatch, queryFulfilled }) {
				const patchResult = dispatch(
					packageApi.util.updateQueryData('getPackages', undefined, draft =>
						draft.filter(pkg => pkg.id !== id)
					)
				);
				queryFulfilled.catch(patchResult.undo);
			},
		}),
	}),
});

export const { useGetPackagesQuery, useCreatePackageMutation, useCopyPackageMutation, useRemovePackageMutation } =
	packageApi;
