import { useSelector } from 'react-redux';
import { Route, Routes } from 'react-router';
import useHasFeatureAccess from 'app/admin/hooks/useHasFeatureAccess';
import type { RootState } from 'app/utils/stores/store-tmp-type';
import ForbiddenPage from 'ringlead_utils//components/ForbiddenPage';
import NotFoundPage from 'ringlead_utils/components/NotFoundPage';
import RingLeadLoadable from 'ringlead_utils/components/RingLeadLoadable/RingLeadLoadable';

const Licensing = RingLeadLoadable({
	loader: () => import('../../licensing/components/Licensing'),
});

const OrganizationSettings = RingLeadLoadable({
	loader: () => import('../../licensing/components/Settings'),
});

function LicensingRoutes() {
	const isAdmin = useSelector(
		(state: RootState) => state.auth.user.organization.is_beta || state.auth.user?.is_admin
	);
	const hasOrganizationPermissions = useHasFeatureAccess(null, 'read:organization', null);

	if (!isAdmin || !hasOrganizationPermissions) {
		return <ForbiddenPage permissions={['read:organization']} />;
	}

	return (
		<Routes>
			<Route index element={<Licensing />} />
			<Route path="settings" element={<OrganizationSettings />} />
			<Route path="*" element={<NotFoundPage />} />
		</Routes>
	);
}

export default LicensingRoutes;
