import { LOCATION_CHANGE } from 'app/Router';
import concat from 'lodash-es/concat';
import includes from 'lodash-es/includes';
import map from 'lodash-es/map';
import reject from 'lodash-es/reject';
import sortBy from 'lodash-es/sortBy';

import { CustomPackagesConstants } from '../Constants';

const initialState = {
	customPackages: [],
	fetching: true,
	updatingPackage: false,
	enrichmentFields: [],
	error: null,
};

export default (state = initialState, action = {}) => {
	switch (action.type) {
		case LOCATION_CHANGE:
			if (includes(action.payload.location.pathname, 'resolution')) {
				return state;
			}

			return { ...initialState, customPackages: state.customPackages };

		case CustomPackagesConstants.CUSTOM_PACKAGES_LIST_SUCCESS:
			return { ...state, customPackages: action.data, fetching: false };

		case CustomPackagesConstants.CUSTOM_PACKAGES_LIST_FAIL:
			return { ...state, customPackages: [], fetching: false };

		case CustomPackagesConstants.CUSTOM_PACKAGE_RETRIEVE_SUCCESS:
			return {
				...state,
				customPackages: concat([], reject(state.customPackages, ['id', action.data.id]), action.data),
				fetching: false,
			};

		case CustomPackagesConstants.CUSTOM_PACKAGE_RETRIEVE_FAIL:
			return { ...state, fetching: false };

		case CustomPackagesConstants.CUSTOM_PACKAGE_UPDATE:
			return {
				...state,
				updatingPackage: true,
			};

		case CustomPackagesConstants.CUSTOM_PACKAGE_UPDATE_SUCCESS:
			return {
				...state,
				customPackages: map(state.customPackages, customPackage =>
					customPackage.id === action.data.id ? action.data : customPackage
				),
				updatingPackage: false,
				error: null,
			};

		case CustomPackagesConstants.CUSTOM_PACKAGE_UPDATE_FAIL:
			return {
				...state,
				updatingPackage: false,
				error: action.data,
			};

		case CustomPackagesConstants.CUSTOM_PACKAGE_VENDOR_API_UPDATE_SUCCESS:
			return {
				...state,
				customPackages: map(state.customPackages, customPackage =>
					customPackage.id === action.packageId
						? {
								...customPackage,
								package_vendors_api: map(customPackage.package_vendors_api, packageVendorApi =>
									packageVendorApi.id === action.data.id
										? {
												...packageVendorApi,
												...action.data,
												vendor_api: { ...packageVendorApi.vendor_api, ...action.vendorApiData },
											}
										: packageVendorApi
								),
							}
						: customPackage
				),
				fetching: false,
			};

		case CustomPackagesConstants.ENRICHMENT_FIELDS_GET_SUCCESS:
			return { ...state, enrichmentFields: sortBy(action.data, 'label') };

		case CustomPackagesConstants.ENRICHMENT_FIELDS_GET_FAIL:
			return { ...state, enrichmentFields: [] };

		case CustomPackagesConstants.ENRICHMENT_FIELD_CREATE_SUCCESS:
			return { ...state, enrichmentFields: sortBy(concat([], state.enrichmentFields, [action.data]), 'label') };

		case CustomPackagesConstants.ENRICHMENT_FIELD_CREATE_FAIL:
			return state;

		default:
			return state;
	}
};
