import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SearchIcon from '@mui/icons-material/Search';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import { styled } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import { amplitudeTrackEvent } from 'app/integrations/amplitude/amplitude';
import type { RootState } from 'app/utils/stores/store-tmp-type';
import { useFlags } from 'launchdarkly-react-client-sdk';
import DebounceInput from 'ringlead_utils/ui-kit/DebounceInput/DebounceInput';

import { getNavigationMenuLinks, type MenuItem, type MenuItems } from '../Helper';
import { toggleOpen } from '../services/navbarSlice';

import NavbarHeader from './NavbarHeader';
import NavItems from './NavItems';

interface NavbarDrawerProps {
	open: boolean;
}

function getFilteredItem(items: MenuItem[], search: string): MenuItem[] {
	return items.filter(item => item.label.toLowerCase().includes(search.toLowerCase()));
}

function getFilteredItems(items: MenuItems[], search: string): MenuItems[] {
	return items.filter(item => {
		const parent = item.label.toLowerCase().includes(search.toLowerCase());
		let itemItems = null;
		if (item.items?.length) {
			const subItems = getFilteredItem(item.items, search);
			itemItems = parent && !subItems.length ? item.items : subItems;
		}

		return parent || !!itemItems?.length;
	});
}

const StyledDrawer = styled(Drawer)(({ theme, open }) => ({
	width: open ? 240 : 64,
	'& .MuiDrawer-paper': {
		width: open ? 240 : 64,
		transition: theme.transitions.create('width', {
			easing: theme.transitions.easing.sharp,
			duration: open ? theme.transitions.duration.enteringScreen : theme.transitions.duration.leavingScreen,
		}),
	},
}));

const searchInputSlotProps = {
	input: {
		startAdornment: (
			<InputAdornment position="start">
				<IconButton size="small" edge="start">
					<SearchIcon fontSize="small" />
				</IconButton>
			</InputAdornment>
		),
	},
	htmlInput: {
		sx: { py: 0.4 },
	},
	inputLabel: {
		shrink: true,
	},
};

function NavbarDrawer({ open }: NavbarDrawerProps) {
	const dispatch = useDispatch();
	const flags = useFlags();
	const user = useSelector((state: RootState) => state.auth.user);

	const [search, setSearch] = useState('');

	const items = useMemo(() => getNavigationMenuLinks(user, flags), [user, flags]);
	const links = useMemo(() => (search ? getFilteredItems(items, search) : items), [items, search]);

	useEffect(() => {
		if (search) {
			amplitudeTrackEvent('QuickSearched', {
				hasResults: Boolean(links),
				searchTerm: search,
				source: window.amplitudeSource,
				activity_group: 'Navigation',
			});
		}
	}, [search, links]);

	const handleOpenSearch = () => {
		dispatch(toggleOpen());
		setSearch('');
	};

	return (
		<nav>
			<StyledDrawer variant="permanent" open={open}>
				<NavbarHeader open={open} />
				<Toolbar>
					{open ? (
						<DebounceInput
							type="search"
							variant="outlined"
							value={search}
							size="small"
							fullWidth
							wait={200}
							placeholder="Search"
							onChange={e => setSearch(e.target.value)}
							slotProps={searchInputSlotProps}
						/>
					) : (
						<IconButton onClick={handleOpenSearch} edge="start" size="large">
							<SearchIcon fontSize="small" />
						</IconButton>
					)}
				</Toolbar>
				<Divider />
				<NavItems links={links} depth={0} hash="" />
			</StyledDrawer>
		</nav>
	);
}

export default NavbarDrawer;
