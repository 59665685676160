import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import KeyboardArrowLeftRoundedIcon from '@mui/icons-material/KeyboardArrowLeftRounded';
import KeyboardArrowRightRoundedIcon from '@mui/icons-material/KeyboardArrowRightRounded';
import AppBar from '@mui/material/AppBar';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import useHasFeatureAccess from 'app/admin/hooks/useHasFeatureAccess';
import type { RootState } from 'app/utils/stores/store-tmp-type';

import { toggleOpen } from '../services/navbarSlice';

import AdaLiveChat from './AdaLiveChat';
import AdminMenu from './AdminMenu';
import CreditsUsage from './CreditsUsage';
import MaskedLogin from './MaskedLogin';
import NavbarDrawer from './NavbarDrawer';
import Notifications from './Notifications';
import PageHead from './PageHead';
import SupportMenu from './SupportMenu';
import UserMenu from './UserMenu';
import UserSettingsMenu from './UserSettingsMenu';

const StyledAppBar = styled(AppBar)(({ theme }) => ({
	background: `linear-gradient(to right, ${theme.palette.primary.main} 0%, ${theme.palette.secondary.main} 100%)`,
	transition: theme.transitions.create('width'),
}));

const StyledIconButton = styled(IconButton)(({ theme }) => ({
	zIndex: 1250,
	position: 'fixed',
	left: '225px',
	transition: theme.transitions.create('left'),
	top: '116px',
	border: `1px solid ${theme.palette.text.disabled}`,
	color: theme.palette.text.primary,
	backgroundColor: theme.palette.common.white,
	'&:hover': {
		// you want this to be the same as the backgroundColor above
		backgroundColor: theme.palette.common.white,
	},
}));

const StyledIconButtonShift = styled(StyledIconButton)(({ theme }) => ({
	left: '50px',
	color: theme.palette.common.white,
	backgroundColor: theme.palette.primary.main,
	'&:hover': {
		// you want this to be the same as the backgroundColor above
		backgroundColor: theme.palette.primary.main,
	},
}));

function NavBar() {
	const dispatch = useDispatch();
	const isStaff = useSelector((state: RootState) => state.auth.user.is_staff);
	const isAdmin = useSelector(
		(state: RootState) =>
			state.auth.user.organization?.is_beta || state.auth.user.is_staff || state.auth.user.is_admin
	);
	const open = useSelector((state: RootState) => state.navbar.open);

	const SwitchMenuButton = open ? StyledIconButton : StyledIconButtonShift;
	const MenuToggleIcon = open ? KeyboardArrowLeftRoundedIcon : KeyboardArrowRightRoundedIcon;
	const hasReadNotificationsPermissions = useHasFeatureAccess(null, 'read:popup_notification');

	const handleDrawer = useCallback(() => {
		dispatch(toggleOpen());
	}, [dispatch]);

	return (
		<>
			<Tooltip key={open ? 'open' : 'closed'} title={`${open ? 'Close' : 'Open'} Navigation`} placement="bottom">
				<SwitchMenuButton
					id="switch-menu-button"
					size="small"
					disableRipple
					disableFocusRipple
					onClick={handleDrawer}
				>
					<MenuToggleIcon fontSize="inherit" />
				</SwitchMenuButton>
			</Tooltip>
			<StyledAppBar elevation={0} sx={{ width: open ? 'calc(100% - 240px)' : 'calc(100% - 64px)' }}>
				<Toolbar>
					<PageHead />
					{isStaff && <MaskedLogin />}
					{hasReadNotificationsPermissions && <Notifications />}
					<CreditsUsage />
					<SupportMenu />
					<AdaLiveChat />
					{isAdmin && <AdminMenu />}
					<UserSettingsMenu />
					<UserMenu />
				</Toolbar>
			</StyledAppBar>
			<NavbarDrawer open={open} />
		</>
	);
}

export default NavBar;
