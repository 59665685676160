import { Link } from 'react-router-dom';
import Avatar, { avatarClasses } from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import LogoImage from 'images/favicon-24x24.ico';

interface NavbarHeaderProps {
	open: boolean;
}

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
	color: theme.palette.common.white,
	backgroundColor: theme.palette.primary.main,
	maxHeight: theme.spacing(8),
	position: 'sticky',
	top: '0',
	left: '0',
	zIndex: '2',
}));

const StyledAvatar = styled(Avatar)(({ theme }) => ({
	backgroundColor: theme.palette.common.white,
	marginRight: theme.spacing(2),
	[`& .${avatarClasses.img}`]: {
		width: theme.spacing(3),
		height: theme.spacing(3),
	},
}));

const StyledTypography = styled(Typography)({
	display: 'flex',
	alignItems: 'center',
	flex: 1,
	justifyContent: 'center',
	textDecoration: 'none',
}) as typeof Typography;

function NavbarHeader({ open }: NavbarHeaderProps) {
	return (
		<StyledToolbar disableGutters>
			<StyledTypography component={Link} to="/">
				<StyledAvatar alt="Ringlead Logo" src={LogoImage} />
				{open && (
					<Box display="flex" flexWrap="wrap" flex={0.93}>
						<Typography variant="h5" color="white">
							RingLead
						</Typography>
						<Typography variant="caption" color="white" sx={{ fontSize: '0.65rem' }}>
							A ZoomInfo Company | Nasdaq: ZI
						</Typography>
					</Box>
				)}
			</StyledTypography>
		</StyledToolbar>
	);
}

export default NavbarHeader;
