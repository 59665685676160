import { createApi } from '@reduxjs/toolkit/query/react';
import baseQueryWithNProgress from 'app/utils/services/baseQueryWithNProgress';

import type { ApiKey } from '../types/apiKey';

export const apiKeyApi = createApi({
	reducerPath: 'apiKeyApi',
	baseQuery: baseQueryWithNProgress,
	endpoints: builder => ({
		getApiKeys: builder.query<ApiKey[], void>({
			query: () => '/account/api-keys/',
		}),
		createApiKey: builder.mutation<ApiKey & { key: string }, Partial<ApiKey>>({
			query: body => ({
				url: '/account/api-keys/',
				method: 'POST',
				body,
			}),
			async onQueryStarted(_, { dispatch, queryFulfilled }) {
				try {
					const { data } = await queryFulfilled;
					dispatch(apiKeyApi.util.updateQueryData('getApiKeys', undefined, draft => [...draft, data]));
				} catch {
					/* empty */
				}
			},
		}),
		editApiKey: builder.mutation<ApiKey, Partial<ApiKey> & { id: number }>({
			query: ({ id, ...body }) => ({
				url: `/account/api-keys/${id}/`,
				method: 'PATCH',
				body,
			}),
			onQueryStarted({ id, ...data }, { dispatch, queryFulfilled }) {
				const patchResult = dispatch(
					apiKeyApi.util.updateQueryData('getApiKeys', undefined, draft => {
						draft.forEach(apiKey => {
							if (apiKey.id === id) {
								Object.assign(apiKey, data);
							}
						});
					})
				);
				queryFulfilled.catch(patchResult.undo);
			},
		}),
		deleteApiKey: builder.mutation<unknown, number>({
			query: id => ({
				url: `/account/api-keys/${id}/`,
				method: 'DELETE',
			}),
			onQueryStarted(id, { dispatch, queryFulfilled }) {
				const patchResult = dispatch(
					apiKeyApi.util.updateQueryData('getApiKeys', undefined, draft =>
						draft.filter(apiKey => apiKey.id !== id)
					)
				);
				queryFulfilled.catch(patchResult.undo);
			},
		}),
	}),
});

export const { useGetApiKeysQuery, useCreateApiKeyMutation, useEditApiKeyMutation, useDeleteApiKeyMutation } =
	apiKeyApi;
