import { createApi } from '@reduxjs/toolkit/query/react';
import baseQueryWithNProgress from 'app/utils/services/baseQueryWithNProgress';

import type { ScoreModel } from '../types/scoreTypes';

export const scoreModelApi = createApi({
	reducerPath: 'scoreModelApi',
	baseQuery: baseQueryWithNProgress,
	tagTypes: ['MultiDimensionDetails'],
	endpoints: builder => ({
		getScoreModels: builder.query<ScoreModel[], void>({
			query: () => '/score/',
		}),
		getScoreModel: builder.query<ScoreModel, number>({
			query: id => `/score/${id}/`,
		}),
		getScoreModelMultiDimensionDetails: builder.query<
			{ row_segment?: string[]; column_segment?: string[] },
			number
		>({
			query: id => `/score/${id}/multi_dimension_details/`,
			providesTags: (result, error, arg) => [{ type: 'MultiDimensionDetails', id: arg }],
		}),
		createScoreModel: builder.mutation<
			ScoreModel,
			| Partial<
					Omit<ScoreModel, 'id' | 'connection' | 'created' | 'updated'> & {
						connection: number;
					}
			  >
			| {
					name: string;
					connection: number;
					settings: {
						file_delimiter: string;
						file_charset: string;
					};
					file: File;
			  }
		>({
			query: body => {
				if ('file' in body) {
					const formData = new FormData();
					formData.append('name', body.name);
					formData.append('file', body.file);
					formData.append('connection', body.connection.toString());
					formData.append('settings', JSON.stringify(body.settings));

					return {
						url: '/score/',
						method: 'POST',
						body: formData,
						formData: true,
					};
				}

				return {
					url: '/score/',
					method: 'POST',
					body,
				};
			},
			async onQueryStarted(_, { dispatch, queryFulfilled }) {
				try {
					const { data } = await queryFulfilled;
					dispatch(
						scoreModelApi.util.updateQueryData('getScoreModels', undefined, draft => {
							draft.push(data);
						})
					);
				} catch {
					// do nothing
				}
			},
		}),
		editScoreModel: builder.mutation<
			ScoreModel,
			Partial<Omit<ScoreModel, 'settings'>> & {
				id: number;
				resetDetails?: boolean;
				settings?: Partial<ScoreModel['settings']>;
			}
		>({
			query: ({ id, resetDetails: _, ...body }) => ({
				url: `/score/${id}/`,
				method: 'PATCH',
				body,
			}),
			onQueryStarted({ id, resetDetails: _, ...patch }, { dispatch, queryFulfilled }) {
				const patchResultList = dispatch(
					scoreModelApi.util.updateQueryData('getScoreModels', undefined, draft => {
						draft.forEach(scoreModel => {
							if (scoreModel.id === id) {
								Object.assign(scoreModel, patch);
							}
						});
					})
				);
				const patchResultDetails = dispatch(
					scoreModelApi.util.updateQueryData('getScoreModel', id, draft => {
						Object.assign(draft, patch);
					})
				);

				queryFulfilled.catch(() => {
					patchResultList.undo();
					patchResultDetails.undo();
				});
			},
			invalidatesTags: (result, error, arg) =>
				arg.resetDetails ? [{ type: 'MultiDimensionDetails', id: arg.id }] : [],
		}),
		copyScoreModel: builder.mutation<ScoreModel, { id: number; name: string }>({
			query: ({ id, ...body }) => ({
				url: `/score/${id}/_copy/`,
				method: 'POST',
				body,
			}),
			async onQueryStarted(_, { dispatch, queryFulfilled }) {
				try {
					const { data } = await queryFulfilled;
					dispatch(
						scoreModelApi.util.updateQueryData('getScoreModels', undefined, draft => {
							draft.push(data);
						})
					);
				} catch {
					// do nothing
				}
			},
		}),
		deleteScoreModel: builder.mutation<unknown, number>({
			query: id => ({
				url: `/score/${id}/`,
				method: 'DELETE',
			}),
			onQueryStarted(id, { dispatch, queryFulfilled }) {
				const patchResult = dispatch(
					scoreModelApi.util.updateQueryData('getScoreModels', undefined, draft =>
						draft.filter(scoreModel => scoreModel.id !== id)
					)
				);
				queryFulfilled.catch(patchResult.undo);
			},
		}),
	}),
});

export const {
	useGetScoreModelsQuery,
	useGetScoreModelQuery,
	useGetScoreModelMultiDimensionDetailsQuery,
	useCreateScoreModelMutation,
	useEditScoreModelMutation,
	useCopyScoreModelMutation,
	useDeleteScoreModelMutation,
} = scoreModelApi;
