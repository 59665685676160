import difference from 'lodash-es/difference';
import differenceBy from 'lodash-es/differenceBy';
import filter from 'lodash-es/filter';
import forEach from 'lodash-es/forEach';
import includes from 'lodash-es/includes';
import map from 'lodash-es/map';
import min from 'lodash-es/min';
import sortBy from 'lodash-es/sortBy';
import union from 'lodash-es/union';
import uniq from 'lodash-es/uniq';
import SalesforceFieldMapping from 'ringlead_utils/helpers/SalesforceFieldMapping';

import { getObjects } from '../components/resolution/list_import/utils';
import { TaskTypes } from '../constants/TaskConstants';

import { getChildObjects } from './taskHelper';

const crmFieldNameSelector = 'sf_field.name';

export const getResolutionStorageKey = (task, setting) =>
	`resolution_settings_${task.task_type}_${task.connection ? task.connection.id : ''}_${setting}`;

export const getLimit = task =>
	min([parseInt(localStorage.getItem(getResolutionStorageKey(task, 'limit')), 10) || 5, 500]);

export const getTableOrientation = task =>
	localStorage.getItem(getResolutionStorageKey(task, 'table_orientation')) || 'v';

export const getMapping = (task, fields, survivingFieldValueRules) => {
	if (task.task_type !== TaskTypes.DEDUPLICATION || !task.is_cross_object) {
		return [];
	}

	return new SalesforceFieldMapping(
		task.source_object,
		task.target_object,
		filter(fields, ['table', task.source_object]),
		filter(fields, ['table', task.target_object]),
		survivingFieldValueRules
	).mapping;
};

export const getShowing = task => {
	const empty = localStorage.getItem(getResolutionStorageKey(task, 'showing_empty')) === 'true';
	const identical = includes(
		[undefined, null, 'true'],
		localStorage.getItem(getResolutionStorageKey(task, 'showing_identical'))
	);
	const readonly = includes(
		[undefined, null, 'true'],
		localStorage.getItem(getResolutionStorageKey(task, 'showing_readonly'))
	);

	switch (task.task_type) {
		case TaskTypes.LIST_IMPORT:
		case TaskTypes.NORMALIZATION:
		case TaskTypes.ENRICHMENT:
			return { empty, identical };
		case TaskTypes.MASS_DELETE:
		case TaskTypes.MASS_UPDATE:
			return { empty, readonly };
		case TaskTypes.DEDUPLICATION:
			return { empty, readonly, identical };
		default:
			return {};
	}
};

const getCleanseObjects = task => {
	switch (task.task_type) {
		case TaskTypes.MASS_DELETE:
		case TaskTypes.MASS_UPDATE:
			return union([task.object_name], getChildObjects(task));
		case TaskTypes.DEDUPLICATION:
			return union([task.source_object, task.target_object]);
		default:
			return [];
	}
};

const getCleanseFieldsToShow = (task, fields, fieldToShowParam) => {
	const fieldToShow = fieldToShowParam;
	forEach(getCleanseObjects(task), object => {
		fieldToShow[object] = fieldToShow[object] || {};

		forEach(filter(fields, ['table', object]), field => {
			if (Object.prototype.hasOwnProperty.call(fieldToShow[object], field.name)) {
				return;
			}

			fieldToShow[object][field.name] = (!field.custom && field.updateable) || field.required;
		});
	});

	return fieldToShow;
};

const getEnrichFieldsToShow = (task, fieldMapping, enrichFields, fieldToShowParam) => {
	const fieldToShow = fieldToShowParam;
	fieldToShow.unmapped = fieldToShow.unmapped || {};

	forEach(
		differenceBy(
			enrichFields,
			uniq(map(filter(fieldMapping, 'data_append_field'), 'data_append_field')),
			v => v.name || v
		),
		unm => {
			if (Object.prototype.hasOwnProperty.call(fieldToShow.unmapped, unm.name)) {
				return;
			}

			fieldToShow.unmapped[unm.name] = true;
		}
	);

	fieldToShow[task.object_name] = fieldToShow[task.object_name] || {};

	forEach(fieldMapping, mapping => {
		if (Object.prototype.hasOwnProperty.call(fieldToShow[task.object_name], mapping.sf_field.name)) {
			return;
		}

		fieldToShow[task.object_name][mapping.sf_field.name] =
			mapping.data_append_field ||
			(!mapping.sf_field.custom && mapping.sf_field.updateable) ||
			mapping.sf_field.required;
	});

	return fieldToShow;
};

const getListImportFieldsToShow = (task, fieldMapping, mappedObjects, enrichFields, fieldToShowParam) => {
	const fieldToShow = fieldToShowParam;

	fieldToShow.unmapped = fieldToShow.unmapped || {};

	forEach(
		differenceBy(
			enrichFields,
			uniq(map(filter(fieldMapping, 'data_append_field'), 'data_append_field')),
			v => v.name || v
		),
		unmappedField => {
			if (Object.prototype.hasOwnProperty.call(fieldToShow.unmapped, unmappedField.name)) {
				return;
			}

			fieldToShow.unmapped[unmappedField.name] = true;
		}
	);

	forEach(getObjects(task, mappedObjects), object => {
		fieldToShow[object.name] = fieldToShow[object.name] || {};

		forEach(fieldMapping, mapping => {
			if (Object.prototype.hasOwnProperty.call(fieldToShow[object.name], mapping.sf_field.name)) {
				return;
			}

			fieldToShow[object.name][mapping.sf_field.name] =
				mapping.data_append_field ||
				(!mapping.sf_field.custom && mapping.sf_field.updateable) ||
				mapping.sf_field.required;
		});
	});

	return fieldToShow;
};

export const getFieldToShow = (task, extra) => {
	let fieldToShow = localStorage.getItem(getResolutionStorageKey(task, 'field_to_show'));

	if (fieldToShow) {
		fieldToShow = JSON.parse(fieldToShow);
	}

	fieldToShow = fieldToShow || {};

	switch (task.task_type) {
		case TaskTypes.DEDUPLICATION:
		case TaskTypes.MASS_DELETE:
		case TaskTypes.MASS_UPDATE:
			return getCleanseFieldsToShow(task, extra.fields, fieldToShow);
		case TaskTypes.NORMALIZATION:
		case TaskTypes.ENRICHMENT:
			return getEnrichFieldsToShow(task, extra.fieldMapping, extra.enrichFields, fieldToShow);
		case TaskTypes.LIST_IMPORT:
			return getListImportFieldsToShow(
				task,
				extra.fieldMapping,
				extra.mappedObjects,
				extra.enrichFields,
				fieldToShow
			);
		default:
			return fieldToShow;
	}
};

const getCleanseFieldsSort = (task, fields, fieldSortParam) => {
	const fieldSort = fieldSortParam;

	forEach(getCleanseObjects(task), object => {
		const currentFields = filter(fields, ['table', object]);
		const allCurrentFields = map(currentFields, 'name');

		if (!fieldSort || !fieldSort[object] || difference(allCurrentFields, fieldSort[object]) > 0) {
			fieldSort[object] = map(sortBy(currentFields, 'label'), 'name');
		}
	});

	return fieldSort;
};

const getEnrichFieldsSort = (task, fieldMapping, fieldSortParam) => {
	const fieldSort = fieldSortParam;

	const allCrmFields = map(fieldMapping, crmFieldNameSelector);

	if (!fieldSort || !fieldSort[task.object_name] || difference(allCrmFields, fieldSort[task.object_name]) > 0) {
		fieldSort[task.object_name] = map(sortBy(fieldMapping, 'sf_field.label'), crmFieldNameSelector);
	}

	return fieldSort;
};

const getListImportFieldsSort = (task, fieldMapping, mappedObjects, fieldSortParam) => {
	const fieldSort = fieldSortParam;

	forEach(getObjects(task, mappedObjects), object => {
		const allCrmFields = map(fieldMapping, crmFieldNameSelector);

		if (!fieldSort || !fieldSort[object.name] || difference(allCrmFields, fieldSort[object.name]) > 0) {
			fieldSort[object.name] = map(sortBy(fieldMapping, 'sf_field.label'), crmFieldNameSelector);
		}
	});
	return fieldSort;
};

export const getFieldsSort = (task, extra) => {
	let fieldSort = localStorage.getItem(getResolutionStorageKey(task, 'field_sort'));

	if (fieldSort) {
		fieldSort = JSON.parse(fieldSort);
	}

	fieldSort = fieldSort || {};

	switch (task.task_type) {
		case TaskTypes.DEDUPLICATION:
		case TaskTypes.MASS_DELETE:
		case TaskTypes.MASS_UPDATE:
			return getCleanseFieldsSort(task, extra.fields, fieldSort);
		case TaskTypes.NORMALIZATION:
		case TaskTypes.ENRICHMENT:
			return getEnrichFieldsSort(task, extra.fieldMapping, fieldSort);
		case TaskTypes.LIST_IMPORT:
			return getListImportFieldsSort(task, extra.fieldMapping, extra.mappedObjects, fieldSort);
		default:
			return fieldSort;
	}
};
