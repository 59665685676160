import { Route, Routes } from 'react-router';
import useHasFeatureAccess from 'app/admin/hooks/useHasFeatureAccess';
import ForbiddenPage from 'ringlead_utils/components/ForbiddenPage';
import NotFoundPage from 'ringlead_utils/components/NotFoundPage';
import RingLeadLoadable from 'ringlead_utils/components/RingLeadLoadable/RingLeadLoadable';

const ModelList = RingLeadLoadable({
	loader: () => import('../../score/components/models/List'),
});

const ModelDetail = RingLeadLoadable({
	loader: () => import('../../score/components/models/Details'),
});

function ScoreRoutes() {
	const hasPermissions = useHasFeatureAccess(['SCORE'], 'read:model');

	if (!hasPermissions) {
		return <ForbiddenPage features={['SCORE']} permissions={['read:model']} />;
	}

	return (
		<Routes>
			<Route index element={<ModelList />} />
			<Route path=":modelId" element={<ModelDetail />} />
			<Route path="*" element={<NotFoundPage />} />
		</Routes>
	);
}

export default ScoreRoutes;
