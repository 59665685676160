import { LOCATION_CHANGE } from 'app/Router';
import concat from 'lodash-es/concat';
import find from 'lodash-es/find';
import includes from 'lodash-es/includes';
import isArray from 'lodash-es/isArray';
import map from 'lodash-es/map';
import reject from 'lodash-es/reject';
import uniqBy from 'lodash-es/uniqBy';

import { ConnectionConstants } from '../Constants';

const initialState = {
	connections: [],
	fetching: true,
	lists: [],
	fetchingLists: true,
};

export default (state = initialState, action = {}) => {
	switch (action.type) {
		case LOCATION_CHANGE:
			if (
				(includes(action.payload.location.pathname, 'objects') ||
					includes(action.payload.location.pathname, 'connections')) &&
				!includes(action.payload.location.pathname, 'auth')
			) {
				return { ...initialState, connections: state.connections, fetching: state.fetching };
			}

			return initialState;

		case ConnectionConstants.CONNECTION_LIST_SUCCESS:
			return {
				...state,
				fetching: false,
				connections: uniqBy(
					concat([], reject(state.connections, ['connection_type', action.connectionType]), action.data),
					connection => [connection.id, connection.connection_type].join()
				),
			};

		case ConnectionConstants.CONNECTION_LIST_FAIL:
			return {
				...state,
				connections: reject(state.connections, ['connection_type', action.connectionType]),
				fetching: false,
			};

		case ConnectionConstants.CONNECTION_UPDATE_SUCCESS: {
			const connections = isArray(action.data) ? action.data : [action.data];

			return {
				...state,
				connections: map(state.connections, connection => {
					const actionData = find(connections, ['id', connection.id]);

					return actionData ? { ...connection, ...actionData } : connection;
				}),
			};
		}

		case ConnectionConstants.CONNECTION_UPDATE_FAIL:
			return state;

		case ConnectionConstants.CONNECTION_CRM_LIST_SUCCESS:
			return { ...state, lists: action.data, fetchingLists: false };

		case ConnectionConstants.CONNECTION_CRM_LIST_FAIL:
			return { ...state, lists: [], fetchingLists: false };

		default:
			return state;
	}
};
