import { useLocation } from 'react-router-dom';

/**
 * @deprecated This used as hack for class components; more preferred migrate to functional component and use hook
 */
function withLocation(Component) {
	return function withLocationWrapper(props) {
		return <Component {...props} location={useLocation()} />;
	};
}

export default withLocation;
