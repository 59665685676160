import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DeleteSweepIcon from '@mui/icons-material/DeleteSweep';
import NotificationsActiveOutlinedIcon from '@mui/icons-material/NotificationsActiveOutlined';
import Badge from '@mui/material/Badge';
import IconButton from '@mui/material/IconButton';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Menu from '@mui/material/Menu';
import Tooltip from '@mui/material/Tooltip';
import pusher from 'app/integrations/pusher/pusher';
import { NotificationTypes } from 'app/notification/NotificationConstants';
import { getNotifications, isNotificationToShow } from 'app/notification/NotificationHelper';
import {
	notificationApi,
	useGetNotificationsQuery,
	useLazyGetNotificationQuery,
	useReadAllNotificationsMutation,
	useRemoveNotificationMutation,
} from 'app/notification/services/notification';
import type { Notification } from 'app/notification/types/notification';
import type { AppDispatch, RootState } from 'app/utils/stores/store-tmp-type';
import SuccessNotificationSound from 'audio/intuition561.mp3';
import FailedNotificationSound from 'audio/notification.mp3';
import dayjs from 'dayjs';
import { bindMenu, bindTrigger, usePopupState } from 'material-ui-popup-state/hooks';

import NotificationModal from '../../notification/components/NotificationModal';

const slotProps = {
	list: {
		sx: { p: 0 },
	},
	transition: {
		mountOnEnter: true,
		unmountOnExit: true,
	},
};

function Notifications() {
	const dispatch = useDispatch<AppDispatch>();
	const { currentData } = useGetNotificationsQuery();
	const notifications = useMemo(() => getNotifications(currentData ?? []), [currentData]);
	const notificationSoundOn = useSelector((state: RootState) => state.auth.user.notification_sound_on);
	const isMasked = useSelector((state: RootState) => state.auth.user.is_masked);
	const [getNotification] = useLazyGetNotificationQuery();
	const [readAllNotifications] = useReadAllNotificationsMutation();
	const [removeNotification] = useRemoveNotificationMutation();
	const popupState = usePopupState({ variant: 'popover', popupId: 'notifications', disableAutoFocus: true });
	const [selectedNotifications, setSelectedNotifications] = useState<Notification[] | null>(null);
	const [modalOpenType, setModalOpenType] = useState<'auto' | 'manual'>('manual');

	useEffect(() => {
		pusher.bind(
			'notificate',
			(notification: { id: number; deleted: boolean } | Pick<Notification, 'id' | 'unread' | 'type'>) => {
				if ('deleted' in notification) {
					dispatch(
						notificationApi.util.updateQueryData('getNotifications', undefined, draft =>
							draft ? draft.filter(n => n.id !== notification.id) : draft
						)
					);
					return;
				}

				if (
					notificationSoundOn &&
					(!notifications.map(n => n.id).includes(notification.id) || notification.unread) &&
					'Audio' in window
				) {
					try {
						new Audio(
							notification.type === NotificationTypes.TASK_ERROR
								? SuccessNotificationSound
								: FailedNotificationSound
						)
							.play()
							.then(() => {
								/* empty */
							})
							.catch(() => {
								/* empty */
							});
					} catch {
						/* empty */
					}
				}

				getNotification(notification.id);
			}
		);

		return () => {
			pusher.unbind('notificate');
		};
	}, []);

	useEffect(() => {
		if (popupState.isOpen && !isMasked && notifications.filter(notification => notification.unread).length > 0) {
			readAllNotifications();
		}
	}, [popupState.isOpen, isMasked, notifications, readAllNotifications]);

	useEffect(() => {
		const autoModalNotifications = notifications.filter(notification => isNotificationToShow(notification));
		if (autoModalNotifications.length > 0) {
			setModalOpenType('auto');
			setSelectedNotifications(autoModalNotifications);
		}
	}, [notifications]);

	const openNotification = useCallback((notification: Notification) => {
		setModalOpenType('manual');
		setSelectedNotifications([notification]);
	}, []);

	const closeNotification = useCallback(() => {
		setSelectedNotifications(null);
		if (modalOpenType === 'auto') {
			if (!isMasked) {
				readAllNotifications();
			}
			setModalOpenType('manual');
		}
	}, [modalOpenType, isMasked]);

	return (
		<>
			<Tooltip title="Notifications" enterDelay={300}>
				<IconButton id="nav-notifications" color="inherit" size="large" {...bindTrigger(popupState)}>
					<Badge
						overlap="rectangular"
						badgeContent={notifications.filter(notification => notification.unread).length}
						color="secondary"
						showZero
					>
						<NotificationsActiveOutlinedIcon />
					</Badge>
				</IconButton>
			</Tooltip>
			<Menu
				id="notifications"
				{...(notifications.length > 0 ? bindMenu(popupState) : { open: false })}
				slotProps={slotProps}
			>
				{[...notifications]
					.sort((prev, next) => new Date(next.created).getTime() - new Date(prev.created).getTime())
					.map(notification => (
						<ListItem
							key={notification.id}
							divider
							secondaryAction={
								<IconButton
									value={notification.id}
									aria-label="Delete"
									color="error"
									onClick={() => removeNotification(notification.id)}
									size="large"
								>
									<DeleteSweepIcon />
								</IconButton>
							}
						>
							<ListItemButton
								disableRipple
								onClick={() => openNotification(notification)}
								sx={{ p: 0, '&:hover': { backgroundColor: 'transparent' } }}
							>
								<ListItemText
									// eslint-disable-next-line react/no-danger
									primary={<span dangerouslySetInnerHTML={{ __html: notification.title }} />}
									secondary={dayjs(notification.start).format('MMM DD, HH:mmA').toUpperCase()}
								/>
							</ListItemButton>
						</ListItem>
					))}
			</Menu>
			{selectedNotifications && (
				<NotificationModal
					notifications={selectedNotifications}
					openType={modalOpenType}
					onClose={closeNotification}
				/>
			)}
		</>
	);
}

export default Notifications;
