import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Tooltip from '@mui/material/Tooltip';
import type { RootState } from 'ringlead_utils/stores/store-tmp-type';

function PageHead() {
	const breadcrumbs = useSelector((state: RootState) => state.breadcrumbs.breadcrumbs);

	return (
		<Breadcrumbs separator="›">
			{breadcrumbs.map(breadcrumb =>
				typeof breadcrumb === 'string' ? (
					<Tooltip key={breadcrumb} title={breadcrumb} placement="bottom">
						<span>{breadcrumb}</span>
					</Tooltip>
				) : (
					<Tooltip key={breadcrumb.label} title={breadcrumb.label} placement="bottom">
						<Link to={breadcrumb.url} color="inherit">
							{breadcrumb.label}
						</Link>
					</Tooltip>
				)
			)}
		</Breadcrumbs>
	);
}

export default PageHead;
