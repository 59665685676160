import { createApi } from '@reduxjs/toolkit/query/react';
import baseQueryWithNProgress from 'app/utils/services/baseQueryWithNProgress';

import type { Log, LogMetadata, Task } from '../types/logTypes';

export const auditLogApi = createApi({
	reducerPath: 'auditLogApi',
	baseQuery: baseQueryWithNProgress,
	endpoints: builder => ({
		getLogsMetadata: builder.query<LogMetadata, void>({
			query: () => ({
				url: '/logs/',
				method: 'OPTIONS',
			}),
			keepUnusedDataFor: 60 * 60 * 3, // 3 hour cache; rarely changed
		}),
		getAllTasks: builder.query<Task[], void>({
			query: () => '/core/tasks/',
		}),
		getLogs: builder.query<
			{ count: number; next: string | null; previous: string | null; results: Log[] },
			Record<string, unknown>
		>({
			query: params => ({
				url: '/logs/',
				params,
			}),
			keepUnusedDataFor: 0,
		}),
		getLogsGroupBy: builder.query<Log[], { groupby: string } & Record<string, unknown>>({
			query: params => ({
				url: '/logs/',
				params,
			}),
			keepUnusedDataFor: 0,
		}),
	}),
});

export const { useGetLogsMetadataQuery, useGetAllTasksQuery, useGetLogsQuery, useGetLogsGroupByQuery } = auditLogApi;
