import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Menu, { type MenuProps } from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useLogoutMutation } from 'app/auth/services/auth';
import type { RootState } from 'app/utils/stores/store-tmp-type';
import { bindMenu, bindTrigger, usePopupState } from 'material-ui-popup-state/hooks';
import { HELP_CENTER_URL } from 'ringlead_utils/HelpCenterConstants';

const anchorOrigin: MenuProps['anchorOrigin'] = { vertical: 'bottom', horizontal: 'center' };
const transformOrigin: MenuProps['transformOrigin'] = { vertical: 'top', horizontal: 'center' };

function UserMenu() {
	const user = useSelector((state: RootState) => state.auth.user);
	const popupState = usePopupState({ variant: 'popover', popupId: 'userMenu' });
	const [logout] = useLogoutMutation();

	return (
		<>
			<IconButton color="inherit" size="large" {...bindTrigger(popupState)}>
				<Avatar alt={`${user.first_name} ${user.last_name}`} src={user.avatar} sx={{ width: 32, height: 32 }} />
			</IconButton>
			{/* need for walkme settings */}
			<div id="walkme-identifier" style={{ display: 'none' }}>
				{user.email}
			</div>
			<Menu
				anchorOrigin={anchorOrigin}
				transformOrigin={transformOrigin}
				disableAutoFocusItem
				{...bindMenu(popupState)}
				onClick={popupState.close}
			>
				<MenuItem component={Link} to="/user/">
					<ListItemText primary="Profile" secondary={`(${user.email})`} />
				</MenuItem>
				<MenuItem component={Link} to="/user/settings/">
					<ListItemText primary="Settings" />
				</MenuItem>
				<MenuItem component="a" href={HELP_CENTER_URL} target="_blank">
					<ListItemText primary="ZoomInfo University" />
				</MenuItem>
				<MenuItem onClick={() => logout()}>
					<ListItemText primary="Logout" />
					<ListItemIcon sx={{ minWidth: 'unset !important' }}>
						<PowerSettingsNewIcon fontSize="small" />
					</ListItemIcon>
				</MenuItem>
			</Menu>
		</>
	);
}

export default UserMenu;
