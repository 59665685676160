import { LOCATION_CHANGE } from 'app/Router';

import { RoutingWorkFlowConstants } from '../Constants';

const initialState = {
	ETMAccountFields: {},
	fetching: true,
};

export default (state = initialState, action = {}) => {
	switch (action.type) {
		case LOCATION_CHANGE:
			return initialState;

		case RoutingWorkFlowConstants.ETM_FIELDS_REQUEST:
			return { ...state, fetching: true };

		case RoutingWorkFlowConstants.ETM_FIELDS_SUCCESS:
			return { ...state, fetching: false, ETMAccountFields: action.data };

		case RoutingWorkFlowConstants.ETM_FIELDS_FAIL:
			return { ...state, fetching: false };

		default:
			return state;
	}
};
