import { LOCATION_CHANGE } from 'app/Router';

import { MeetingSchedulerConstants } from '../Constants';

const initialState = {
	schedulerSettings: {},
	fetching: false,
};

export default (state = initialState, action = {}) => {
	switch (action.type) {
		case LOCATION_CHANGE:
			return initialState;

		case MeetingSchedulerConstants.MEETING_SCHEDULER_REQUEST:
			return { ...state, fetching: true };

		case MeetingSchedulerConstants.MEETING_SCHEDULER_SETTINGS_CREATE_SUCCESS:
		case MeetingSchedulerConstants.MEETING_SCHEDULER_RETRIEVE_SUCCESS:
			return { ...state, fetching: false, schedulerSettings: action.data };

		case MeetingSchedulerConstants.MEETING_SCHEDULER_FETCH_FORM_FIELDS_SUCCESS:
		case MeetingSchedulerConstants.MEETING_SCHEDULER_UPDATE_SUCCESS:
			return { ...state, schedulerSettings: action.data };

		case MeetingSchedulerConstants.MEETING_SCHEDULER_UPDATE_FAIL:
			return state;

		case MeetingSchedulerConstants.MEETING_SCHEDULER_FETCH_FORM_FIELDS_FAIL:
		case MeetingSchedulerConstants.MEETING_SCHEDULER_TEAM_MEMBER_SUCCESS:
		case MeetingSchedulerConstants.MEETING_SCHEDULER_TEAM_MEMBER_FAIL:
		case MeetingSchedulerConstants.MEETING_SCHEDULER_SETTINGS_CREATE_FAIL:
		case MeetingSchedulerConstants.MEETING_SCHEDULER_RETRIEVE_FAIL:
		case MeetingSchedulerConstants.MEETING_SCHEDULER_CALENDAR_INVITE_SUCCESS:
		case MeetingSchedulerConstants.MEETING_SCHEDULER_CALENDAR_INVITE_FAIL:
			return { ...state, fetching: false };

		default:
			return state;
	}
};
