import { useSelector } from 'react-redux';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import OrganizationAdminList from 'app/licensing/components/OrganizationAdminList';
import { useGetConfigurationQuery } from 'app/utils/services/common';
import type { RootState } from 'app/utils/stores/store-tmp-type';

interface ForbiddenPageProps {
	features?: string[];
	permissions?: string[];
}

const StyledPaper = styled(Paper)(({ theme }) => ({
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	padding: theme.spacing(2, 3, 3),
}));

function ForbiddenPage({ features, permissions }: ForbiddenPageProps) {
	const user = useSelector((state: RootState) => state.auth.user);
	const { betaFeatures } = useGetConfigurationQuery(undefined, {
		selectFromResult: result => ({ betaFeatures: result.data?.beta_features ?? [] }),
	});

	if (
		features &&
		features.length > 0 &&
		user.expired_features.some(expiredFeature => features.includes(expiredFeature))
	) {
		return (
			<StyledPaper>
				<Typography component="h1" variant="h4" sx={{ mb: 2 }}>
					Expired Feature
				</Typography>
				<Typography component="h5" variant="body1" align="center">
					To renew this feature, please contact RingLead sales at{' '}
					<Link href="mailto:sales@ringlead.com" target="_blank" rel="noopener noreferrer" underline="hover">
						sales@ringlead.com
					</Link>
				</Typography>
			</StyledPaper>
		);
	}

	if (
		features &&
		features.length > 0 &&
		user.blocked_features.some(blockedFeature => features.includes(blockedFeature))
	) {
		return (
			<StyledPaper>
				<Typography component="h1" variant="h4" sx={{ mb: 2 }}>
					Insufficient User Access
				</Typography>
				<Typography component="h5" variant="body1" align="center">
					Your RingLead user does not have access to this feature. Please contact your administrator
				</Typography>
				<OrganizationAdminList />
			</StyledPaper>
		);
	}

	if (
		permissions &&
		permissions.length > 0 &&
		!Object.keys(user.permissions).some(permission => permissions.includes(permission))
	) {
		return (
			<StyledPaper>
				<Typography component="h1" variant="h4" sx={{ mb: 2 }}>
					Restricted access
				</Typography>
				<Typography component="h5" variant="body1" align="center">
					This feature is not enabled for you to access by your RingLead Admin(s). Please contact them to
					enable this in the{' '}
					<Link
						href="https://help.zoominfo.com/s/article/Overview-of-RingLead-User-Roles-and-Permissions"
						target="_blank"
						rel="noopener noreferrer"
						underline="hover"
					>
						Roles and Permissions
					</Link>{' '}
					feature of RingLead.
				</Typography>
				<OrganizationAdminList />
			</StyledPaper>
		);
	}

	return (
		<StyledPaper>
			<Typography component="h1" variant="h4" sx={{ mb: 2 }}>
				{betaFeatures.some(betaFeature => features?.includes(betaFeature)) ? 'Beta' : 'Unavailable'} Feature
			</Typography>
			<Typography component="h5" variant="body1" align="center">
				To purchase this feature, please contact RingLead sales at{' '}
				<Link href="mailto:sales@ringlead.com" target="_blank" rel="noopener noreferrer" underline="hover">
					sales@ringlead.com
				</Link>
			</Typography>
		</StyledPaper>
	);
}

export default ForbiddenPage;
