import { commonApi } from 'ringlead_utils/services/common';

export const featureApi = commonApi.injectEndpoints({
	endpoints: builder => ({
		getFeatures: builder.query<Record<string, string>, void>({
			query: () => '/licensing/features/',
		}),
	}),
});

export const { useGetFeaturesQuery } = featureApi;
