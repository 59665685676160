import { Route, Routes } from 'react-router';
import useHasFeatureAccess from 'app/admin/hooks/useHasFeatureAccess';
import ForbiddenPage from 'app/utils/components/ForbiddenPage';
import NotFoundPage from 'ringlead_utils/components/NotFoundPage';
import RingLeadLoadable from 'ringlead_utils/components/RingLeadLoadable/RingLeadLoadable';

const TemplatesOverview = RingLeadLoadable({
	loader: () => import('../../settings/components/templates/TemplatesOverview'),
});

const TemplatesList = RingLeadLoadable({
	loader: () => import('../../settings/components/templates/TemplatesList'),
});

const TemplateDetails = RingLeadLoadable({
	loader: () => import('app/settings/components/templates/TemplateDetails'),
});

function TemplateRoutes() {
	const hasPermissions = useHasFeatureAccess(null, 'read:template');

	if (!hasPermissions) {
		return <ForbiddenPage permissions={['read:template']} />;
	}

	return (
		<Routes>
			<Route index element={<TemplatesOverview />} />
			<Route path=":type">
				<Route index element={<TemplatesList />} />
				<Route path=":id" element={<TemplateDetails />} />
			</Route>
			<Route path="*" element={<NotFoundPage />} />
		</Routes>
	);
}

export default TemplateRoutes;
