import { useMemo } from 'react';
import useHasFeatureAccess from 'app/admin/hooks/useHasFeatureAccess';

import { ICON_TYPES } from '../Constants';

import IconWithMenu from './IconWithMenu';

function AdminMenu() {
	const hasReadOrgApiKeysPermissions = useHasFeatureAccess(['API_ACCESS'], 'read:api_key');

	const items = useMemo(
		() => [
			{
				label: 'Licensing Center',
				href: '/licensing/',
			},
			{
				label: 'User Management',
				href: '/user-management/',
			},
			{
				label: 'Auth Manage',
				href: '/auth-manage/',
			},
			...(hasReadOrgApiKeysPermissions
				? [
						{
							label: 'API Access',
							href: '/auth-api-access/',
						},
					]
				: []),
			{
				label: 'Organization settings',
				href: '/licensing/settings/',
			},
			{
				label: 'Notification Settings',
				href: '/notifications/',
			},
		],
		[hasReadOrgApiKeysPermissions]
	);

	return <IconWithMenu iconTitle="Administration" iconType={ICON_TYPES.ADMIN} items={items} />;
}

export default AdminMenu;
