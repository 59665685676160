import { Route, Routes } from 'react-router';
import useHasFeatureAccess from 'app/admin/hooks/useHasFeatureAccess';
import ForbiddenPage from 'ringlead_utils/components/ForbiddenPage';
import NotFoundPage from 'ringlead_utils/components/NotFoundPage';
import RingLeadLoadable from 'ringlead_utils/components/RingLeadLoadable/RingLeadLoadable';

const ConfigurationList = RingLeadLoadable({
	loader: () => import('../../segmentation/components/configurations/List'),
});

const ConfigurationDetail = RingLeadLoadable({
	loader: () => import('../../segmentation/components/configurations/Details'),
});

function SegmentationRoutes() {
	const hasPermissions = useHasFeatureAccess(['SEGMENTATION'], 'read:config');

	if (!hasPermissions) {
		return <ForbiddenPage features={['SEGMENTATION']} permissions={['read:config']} />;
	}

	return (
		<Routes>
			<Route index element={<ConfigurationList />} />
			<Route path=":cfgId" element={<ConfigurationDetail />} />
			<Route path="*" element={<NotFoundPage />} />
		</Routes>
	);
}

export default SegmentationRoutes;
