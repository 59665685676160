import { useCallback } from 'react';
import LiveHelpOutlinedIcon from '@mui/icons-material/LiveHelpOutlined';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

function AdaLiveChat() {
	const handleIconClick = useCallback(() => {
		if (window.adaEmbed) {
			window.adaEmbed.toggle();
		}
	}, []);

	return (
		<Tooltip title="Live Chat" enterDelay={300}>
			<IconButton id="nav-live-chat" color="inherit" aria-haspopup="true" onClick={handleIconClick} size="large">
				<LiveHelpOutlinedIcon />
			</IconButton>
		</Tooltip>
	);
}

export default AdaLiveChat;
