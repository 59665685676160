import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import PersonAddDisabledIcon from '@mui/icons-material/PersonAddDisabled';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import { alpha, styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import { useMaskLoginMutation, useMaskLogoutMutation } from 'app/auth/services/auth';
import type { RootState } from 'app/utils/stores/store-tmp-type';

const StyledTextField = styled(TextField)(({ theme }) => ({
	fontFamily: theme.typography.fontFamily,
	marginRight: theme.spacing(2),
	marginLeft: theme.spacing(1),
	borderRadius: theme.shape.borderRadius,
	backgroundColor: alpha(theme.palette.common.white, 0.15),
	'&:hover': {
		backgroundColor: alpha(theme.palette.common.white, 0.25),
	},
}));

function MaskedLogin() {
	const [email, setEmail] = useState<string>('');
	const [maskLogin, { isSuccess: isSuccessLogin, data: dataLogin, error }] = useMaskLoginMutation();
	const [maskLogout, { isSuccess: isSuccessLogout, data: dataLogout }] = useMaskLogoutMutation();
	const isMasked = useSelector((state: RootState) => state.auth.user.is_masked);
	const isSuccess = !isMasked ? isSuccessLogin : isSuccessLogout;
	const data = !isMasked ? dataLogin : dataLogout;

	const maskError = useMemo(() => {
		if (error && 'data' in error) {
			return (error.data as { error: string }).error;
		}

		return null;
	}, [error]);

	useEffect(() => {
		if (isSuccess && data) {
			const { custom_domain: customDomain } = data.user.organization;
			const parts = document.location.hostname.split('.');
			if (customDomain && parts.length > 2 && parts[0] !== customDomain) {
				const domain = `${customDomain}.${parts.slice(-2).join('.')}/`;

				window.location.replace(
					`${window.location.href.substring(0, window.location.href.indexOf('://'))}://${domain}`
				);
			} else {
				window.location.reload();
			}
		}
	}, [isSuccess, data]);

	return isMasked ? (
		<Tooltip title="Masked logout" enterDelay={300}>
			<IconButton color="inherit" onClick={() => maskLogout()} size="large">
				<PersonAddDisabledIcon />
			</IconButton>
		</Tooltip>
	) : (
		<StyledTextField
			variant="standard"
			slotProps={{
				formHelperText: { sx: { color: '#fff !important', mx: 1.5 } },
				input: {
					disableUnderline: true,
					sx: { color: 'common.white' },
					startAdornment: (
						<InputAdornment position="start">
							<IconButton
								disableRipple
								disableFocusRipple
								sx={{ color: 'common.white', backgroundColor: 'transparent' }}
								onClick={() => maskLogin({ email: email.trim() })}
								size="large"
							>
								<PersonAddIcon />
							</IconButton>
						</InputAdornment>
					),
				},
			}}
			error={!!maskError}
			helperText={maskError}
			placeholder="Email for mask…"
			value={email}
			onChange={e => setEmail(e.target.value)}
			onKeyDown={ev => {
				if (ev.key === 'Enter') {
					maskLogin({ email: email.trim() });
				}
			}}
		/>
	);
}

export default MaskedLogin;
