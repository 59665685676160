import { LOCATION_CHANGE } from 'app/Router';
import concat from 'lodash-es/concat';
import reject from 'lodash-es/reject';
import uniqueId from 'lodash-es/uniqueId';

import { DiscoveryConstants } from '../Constants';

const initialState = {
	fetchingIELists: true,
	IELists: [],
	fetchingListItems: true,
	listItems: [],
	fetchingCsvFields: false,
	csvFields: [],
	csvFileError: null,
	locked: false,
	error: null,
	usedIELists: [],
	dynamicListPreviewMode: false,
};

export default (state = initialState, action = {}) => {
	switch (action.type) {
		case LOCATION_CHANGE:
			return initialState;

		case DiscoveryConstants.DISCOVERY_GET_IELISTS:
			return { ...state, fetchingIELists: true };

		case DiscoveryConstants.DISCOVERY_GET_IELISTS_SUCCESS:
			return { ...state, IELists: action.data, fetchingIELists: false };

		case DiscoveryConstants.DISCOVERY_GET_IELISTS_FAIL:
			return { ...state, fetchingIELists: false };

		case DiscoveryConstants.DISCOVERY_UPDATE_IELIST_SUCCESS:
		case DiscoveryConstants.DISCOVERY_RETRIEVE_IELIST_SUCCESS:
		case DiscoveryConstants.DISCOVERY_IMPORT_SF_SUCCESS:
			return {
				...state,
				IELists: concat([], reject(state.IELists, ['id', action.data.id]), action.data),
				csvFileError: null,
				locked: false,
				fetchingIELists: false,
			};

		case DiscoveryConstants.DISCOVERY_IMPORT_IELIST_SUCCESS:
		case DiscoveryConstants.DISCOVERY_IMPORT_CSV_SUCCESS:
			return {
				...state,
				listItems: action.data,
				csvFileError: null,
				locked: false,
			};

		case DiscoveryConstants.DISCOVERY_DELETE_IELIST_SUCCESS:
			return { ...state, IELists: reject(state.IELists, ['id', action.id]) };

		case DiscoveryConstants.DISCOVERY_IMPORT_SUBMIT_FORM:
			return { ...state, locked: true };

		case DiscoveryConstants.DISCOVERY_COPY_IELIST_SUCCESS:
			return { ...state, IELists: concat(state.IELists, action.data) };

		case DiscoveryConstants.DISCOVERY_GET_CSV_HEADERS:
			return { ...state, csvFields: [], fetchingCsvFields: true, csvFileError: null };

		case DiscoveryConstants.DISCOVERY_GET_CSV_HEADERS_SUCCESS:
			return { ...state, csvFields: action.data, fetchingCsvFields: false, csvFileError: null };

		case DiscoveryConstants.DISCOVERY_GET_CSV_HEADERS_FAIL:
			return { ...state, csvFields: [], fetchingCsvFields: false, csvFileError: action.data };

		case DiscoveryConstants.DISCOVERY_IMPORT_CSV_FAIL:
			return { ...state, csvFileError: action.data, locked: false };

		case DiscoveryConstants.DISCOVERY_IMPORT_IELIST_FAIL:
		case DiscoveryConstants.DISCOVERY_IMPORT_SF_FAIL:
			return { ...state, locked: false };

		case DiscoveryConstants.DISCOVERY_GET_IELIST_ITEMS:
			return { ...state, fetchingListItems: true };

		case DiscoveryConstants.DISCOVERY_GET_IELIST_ITEMS_SUCCESS:
			return {
				...state,
				listItems: (action.data || []).map(item => {
					if (!item.id) {
						item.id = item.Id ? item.Id : uniqueId('tmp_');
					}
					return item;
				}),
				fetchingListItems: false,
			};

		case DiscoveryConstants.DISCOVERY_UPDATE_IELIST_ITEM_SUCCESS: {
			const list = action.newField
				? state.listItems
						.filter(el => el.id !== action.id)
						.map(item => ({ ...item, record: { ...item.record, [action.newField]: '' } }))
				: state.listItems.filter(el => el.id !== action.id);

			const IELists = action.newField
				? state.IELists.map(el =>
						el.id === action.listId
							? {
									...el,
									settings: {
										...(el.settings || {}),
										fields: [...(el.settings?.fields || []), action.newField],
									},
								}
							: el
					)
				: state.IELists;

			return { ...state, IELists, listItems: concat(list, action.data) };
		}

		case DiscoveryConstants.DISCOVERY_CREATE_IELIST_ITEM_SUCCESS:
			return {
				...state,
				listItems: concat(state.listItems, [action.data]),
			};

		case DiscoveryConstants.DISCOVERY_DELETE_IELIST_ITEM_SUCCESS:
			return {
				...state,
				listItems: reject(state.listItems, ['id', action.id]),
				IELists: state.IELists.map(el =>
					el.id === action.listId
						? {
								...el,
								items_count: el.items_count - 1,
							}
						: el
				),
			};

		case DiscoveryConstants.DISCOVERY_COPY_IELIST_ITEM_SUCCESS:
			return {
				...state,
				listItems: concat(state.listItems, action.data),
			};

		case DiscoveryConstants.DISCOVERY_CREATE_IELIST_FAIL:
		case DiscoveryConstants.DISCOVERY_UPDATE_IELIST_FAIL:
			return { ...state, error: action.data };

		case DiscoveryConstants.DISCOVERY_CREATE_IELIST_SUCCESS:
		case DiscoveryConstants.DISCOVERY_DELETE_IELIST_ITEM_FAIL:
		case DiscoveryConstants.DISCOVERY_COPY_IELIST_FAIL:
		case DiscoveryConstants.DISCOVERY_COPY_IELIST_ITEM_FAIL:
			return state;

		case DiscoveryConstants.DISCOVERY_GET_USED_IELISTS_SUCCESS:
		case DiscoveryConstants.DISCOVERY_UPDATE_USED_IELISTS_SUCCESS:
			return {
				...state,
				usedIELists: action.data,
			};

		case DiscoveryConstants.DISCOVERY_GET_USED_IELISTS_FAIL:
		case DiscoveryConstants.DISCOVERY_UPDATE_USED_IELISTS_FAIL:
			return {
				...state,
				usedIELists: [],
			};

		case DiscoveryConstants.DISCOVERY_IELIST_SET_PREVIEW_MODE:
			return {
				...state,
				dynamicListPreviewMode: action.data,
			};

		default:
			return state;
	}
};
