import { createInstance, type Types } from '@amplitude/analytics-browser';

import { environment } from '../../../environments/environment';

const apiKey =
	environment.serverName !== 'release' ? '32975cb7e808264c73bea49bad1603ef' : '77cd2a146cc77c4445a21e5a6d5917a2';
const proxyServerURL =
	environment.serverName !== 'release' ? 'https://events-stg.zoominfo.com' : 'https://events.zoominfo.com';
const amplitudeProjectName = 'RingLead';
const platformName = environment.serverName !== 'release' ? 'RingLead staging' : 'RingLead';

const options: Types.BrowserOptions = {
	autocapture: false,
	identityStorage: 'none',
	cookieOptions: {
		domain: `.${document.location.hostname.split('.').slice(-2).join('.')}`,
		sameSite: 'Lax',
	},
};

export const mainInstance = createInstance();
export const proxyInstance = createInstance();

export const initAmplitude = () => {
	mainInstance.init(apiKey, options);
	proxyInstance.init(`${apiKey}_1`, {
		...options,
		instanceName: 'proxy',
		serverUrl: proxyServerURL,
	});
};

export const amplitudeTrackEvent = (eventType: string, eventProperties: Record<string, unknown>) => {
	const eventData = {
		PDM_PLATFORM: platformName,
		PDM_APPLICATION: 'Web',
		PDM_APPLICATION_ATTRIBUTE: null,
		PDM_PRODUCT_CATEGORY: amplitudeProjectName,
		PDM_PRODUCT_TYPE: amplitudeProjectName,
		...eventProperties,
	};
	mainInstance.track(eventType, eventData);
	proxyInstance.track(eventType, eventData);
};
