import { createApi } from '@reduxjs/toolkit/query/react';
import type { DiscoveryIEList } from 'app/dop/types/discoveryIEListTypes';
import baseQueryWithNProgress from 'app/utils/services/baseQueryWithNProgress';

export const discoveryIEListApi = createApi({
	reducerPath: 'discoveryIEListApi',
	baseQuery: baseQueryWithNProgress,
	endpoints: builder => ({
		getDiscoveryIEListFields: builder.query<string[], { taskId: number; listId: number }>({
			query: ({ taskId, listId }) => `discovery/${taskId}/ie_lists/${listId}/fields/`,
		}),
		getDiscoveryIEList: builder.query<DiscoveryIEList, { taskId: number; listId: number }>({
			query: ({ taskId, listId }) => `discovery/${taskId}/ie_lists/${listId}/`,
		}),
		updateDiscoveryIEList: builder.mutation<
			DiscoveryIEList,
			{ taskId: number; listId: number; body: Partial<DiscoveryIEList> }
		>({
			query: ({ taskId, listId, body }) => ({
				url: `discovery/${taskId}/ie_lists/${listId}/`,
				method: 'PATCH',
				body,
			}),
			async onQueryStarted({ taskId, listId }, { dispatch, queryFulfilled }) {
				try {
					const { data } = await queryFulfilled;
					dispatch(
						discoveryIEListApi.util.updateQueryData('getDiscoveryIEList', { taskId, listId }, () => data)
					);
				} catch {
					// do nothing
				}
			},
		}),
	}),
});

export const { useGetDiscoveryIEListFieldsQuery, useGetDiscoveryIEListQuery, useUpdateDiscoveryIEListMutation } =
	discoveryIEListApi;
