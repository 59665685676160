import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { bindDialog, type PopupState } from 'material-ui-popup-state/hooks';

interface ModalRenewProps {
	popupState: PopupState;
}

function ModalRenew({ popupState }: ModalRenewProps) {
	return (
		<Dialog {...bindDialog(popupState)}>
			<DialogTitle>Renew License</DialogTitle>
			<DialogContent>
				<DialogContentText>
					Please call 1(866) 904-9666, to learn more about your licensing options, and to avoid any service
					interruptions.
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button onClick={popupState.close} color="primary" autoFocus>
					Close
				</Button>
			</DialogActions>
		</Dialog>
	);
}

export default ModalRenew;
