import { createApi } from '@reduxjs/toolkit/query/react';
import baseQueryWithNProgress from 'app/utils/services/baseQueryWithNProgress';

import type { Invite, InviteCreateRequest } from '../types/invite';

export const inviteApi = createApi({
	reducerPath: 'inviteApi',
	baseQuery: baseQueryWithNProgress,
	endpoints: builder => ({
		getInvites: builder.query<Invite[], void>({
			query: () => ({
				url: '/licensing/invitations/',
				params: { pending: '1' },
			}),
		}),
		getInvite: builder.query<Invite, string>({
			query: uuid => `/licensing/invitations/${uuid}/`,
		}),
		createInvites: builder.mutation<Invite[], InviteCreateRequest[]>({
			query: body => ({
				url: '/licensing/invitations/',
				method: 'POST',
				body,
			}),
			async onQueryStarted(_, { dispatch, queryFulfilled }) {
				try {
					const { data } = await queryFulfilled;
					dispatch(inviteApi.util.updateQueryData('getInvites', undefined, draft => [...draft, ...data]));
				} catch {
					/* empty */
				}
			},
		}),
		deleteInvite: builder.mutation<unknown, number>({
			query: id => ({
				url: `/licensing/invitations/${id}/`,
				method: 'DELETE',
			}),
			onQueryStarted(id, { dispatch, queryFulfilled }) {
				const patchResult = dispatch(
					inviteApi.util.updateQueryData('getInvites', undefined, draft =>
						draft.filter(invite => invite.id !== id)
					)
				);
				queryFulfilled.catch(patchResult.undo);
			},
		}),
	}),
});

export const { useGetInvitesQuery, useGetInviteQuery, useCreateInvitesMutation, useDeleteInviteMutation } = inviteApi;
