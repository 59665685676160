import { LOCATION_CHANGE } from 'app/Router';
import concat from 'lodash-es/concat';
import filter from 'lodash-es/filter';
import find from 'lodash-es/find';
import includes from 'lodash-es/includes';
import map from 'lodash-es/map';
import pickBy from 'lodash-es/pickBy';
import reduce from 'lodash-es/reduce';

import { GroupFiltersConstants } from '../Constants';

const initialState = {
	groupFilters: [],
	fetching: true,
	errors: {},
};

export default (state = initialState, action = {}) => {
	switch (action.type) {
		case LOCATION_CHANGE:
			return initialState;

		case GroupFiltersConstants.LOAD_GROUP_FILTERS_SUCCESS:
			return { ...state, groupFilters: action.data, fetching: false };

		case GroupFiltersConstants.LOAD_GROUP_FILTERS_FAIL:
			return { ...state, groupFilters: [], fetching: false };

		case GroupFiltersConstants.SYNC_GROUP_FILTERS_SUCCESS:
			return {
				...state,
				groupFilters: [...action.data, ...action.invalidGroupFilters],
				errors: reduce(
					action.invalidGroupFilters,
					(result, groupFilter, index) => ({ ...result, [groupFilter.tmpId]: action.errors[index] || {} }),
					{}
				),
			};

		case GroupFiltersConstants.CREATE_GROUP_FILTER_SUCCESS:
			return {
				...state,
				groupFilters: find(state.groupFilters, ['tmpId', action.filter.tmpId])
					? map(state.groupFilters, groupFilter =>
							groupFilter.tmpId === action.filter.tmpId ? { ...groupFilter, ...action.data } : groupFilter
						)
					: concat([], state.groupFilters, action.data),
				errors: pickBy(state.errors, (_v, k) => k !== action.filter.tmpId),
			};

		case GroupFiltersConstants.CREATE_GROUP_FILTER_FAIL:
			return {
				...state,
				groupFilters: includes(map(state.groupFilters, 'tmpId'), action.filter.tmpId)
					? map(state.groupFilters, groupFilter =>
							action.filter.tmpId === groupFilter.tmpId
								? { ...groupFilter, ...action.filter }
								: groupFilter
						)
					: concat([], state.groupFilters, action.filter),
				errors: { ...state.errors, [action.filter.tmpId]: action.data },
			};

		case GroupFiltersConstants.PATCH_GROUP_FILTER_SUCCESS:
			return {
				...state,
				groupFilters: map(state.groupFilters, groupFilter =>
					action.data.id === groupFilter.id ? { ...groupFilter, ...action.data } : groupFilter
				),
				errors: pickBy(state.errors, (_v, k) => k !== action.filter.id.toString()),
			};

		case GroupFiltersConstants.PATCH_GROUP_FILTER_FAIL:
			return {
				...state,
				groupFilters: map(state.groupFilters, groupFilter =>
					action.filter.id === groupFilter.id ? { ...groupFilter, ...action.filter } : groupFilter
				),
				errors: { ...state.errors, [action.filter.id]: action.data },
			};

		case GroupFiltersConstants.REORDER_GROUP_FILTERS:
			return {
				...state,
				groupFilters: map(state.groupFilters, ff => {
					const actionFilter = find(action.filters, f => (ff.id || ff.tmpId) === (f.id || f.tmpId));
					return actionFilter ? { ...ff, priority: actionFilter.priority } : ff;
				}),
			};

		case GroupFiltersConstants.DELETE_GROUP_FILTER_SUCCESS:
			return {
				...state,
				groupFilters: filter(
					state.groupFilters,
					groupFilter => groupFilter.id !== action.id && groupFilter.tmpId !== action.id
				),
				errors: pickBy(state.errors, (_v, k) => action.id.toString() !== k),
			};

		case GroupFiltersConstants.DELETE_GROUP_FILTER_FAIL:
			return state;

		default:
			return state;
	}
};
