import { Route, Routes } from 'react-router';
import NotFoundPage from 'ringlead_utils/components/NotFoundPage';
import RingLeadLoadable from 'ringlead_utils/components/RingLeadLoadable/RingLeadLoadable';

import { HELP_CENTER_PAGES } from '../HelpCenterConstants';

import ConnectionsRoutes from './ConnectionsRoutes';
import DiscoveryRoutes from './DiscoveryRoutes';
import LicensingRoutes from './LicensingRoutes';
import NormalizeRoutes from './NormalizeRoutes';
import PackageManagementRoutes from './PackageManagementRoutes';
import RoutingRoutes from './RoutingRoutes';
import ScoreRoutes from './ScoreRoutes';
import SegmentationRoutes from './SegmentationRoutes';
import TaskRoutes from './TaskRoutes';
import TemplateRoutes from './TemplateRoutes';
import UserRoutes from './UserRoutes';

const UserInfo = RingLeadLoadable({
	loader: () => import('../../admin/components/users/profile/UserInfo'),
});

const UserSettings = RingLeadLoadable({
	loader: () => import('../../admin/components/users/profile/UserSettings'),
});

const HelpPage = RingLeadLoadable({
	loader: () => import('ringlead_utils/components/HelpPage'),
});

const AuditLogList = RingLeadLoadable({
	loader: () => import('../../audit_logs/components/audit_logs/AuditLogList'),
});

const AuditLogReports = RingLeadLoadable({
	loader: () => import('../../audit_logs/components/reports/AuditLogReports'),
});

const Dashboard = RingLeadLoadable({
	loader: () => import('../../dop/components/dashboard/overview/OverviewDashboard'),
});

const ReportCard = RingLeadLoadable({
	loader: () => import('../../dop/components/dashboard/report_card/ReportCard'),
});

const ListScheduledTasks = RingLeadLoadable({
	loader: () => import('../../settings/components/scheduling/ListScheduledTasks'),
});

const SystemDictionaries = RingLeadLoadable({
	loader: () => import('../../settings/components/system_dictionaries/SystemDictionaries'),
});

const ReplaceValuesList = RingLeadLoadable({
	loader: () => import('../../settings/components/replace_values/ReplaceValuesList'),
});

const OAuthManagement = RingLeadLoadable({
	loader: () => import('../../admin/components/oauth/Management'),
});

const Settings = RingLeadLoadable({
	loader: () => import('../../notification/components/Settings'),
});

const MyTasks = RingLeadLoadable({
	loader: () => import('../../dop/components/dashboard/tasks/MyTasks'),
});

const APIAccess = RingLeadLoadable({
	loader: () => import('../../admin/components/api_access/APIAccess'),
});

const VendorKeys = RingLeadLoadable({
	loader: () => import('../../vendors/components/vendor_keys/list/VendorKeys'),
});

export default (
	<Routes>
		<Route index element={<MyTasks />} />
		<Route path="activity_logs" element={<AuditLogList />} />
		<Route path="activity_reports/*" element={<AuditLogReports />} />
		<Route path="report-card" element={<ReportCard />} />
		<Route path="performance-dashboard" element={<Dashboard />} />
		<Route path="user">
			<Route index element={<UserInfo />} />
			<Route path="settings" element={<UserSettings />} />
		</Route>
		{HELP_CENTER_PAGES.map(page => (
			<Route key={page.url} path={page.url} element={<HelpPage />} />
		))}
		<Route
			path="intelligent_forms/*"
			element={<TaskRoutes features={['INTELLIGENT_FORMS']} permission="read:intelligent_form" />}
		/>
		<Route
			path="deduplication/*"
			element={
				<TaskRoutes
					features={[
						'SALESFORCE_DEDUPLICATION',
						'MARKETO_DEDUPLICATION',
						'ELOQUA_DEDUPLICATION',
						'HUBSPOT_DEDUPLICATION',
						'DYNAMICS_DEDUPLICATION',
					]}
					permission="read:deduplicate_task"
				/>
			}
		/>
		<Route
			path="enrichment/*"
			element={
				<TaskRoutes
					features={[
						'SALESFORCE_ENRICHMENT',
						'MARKETO_ENRICHMENT',
						'ELOQUA_ENRICHMENT',
						'PARDOT_ENRICHMENT',
						'HUBSPOT_ENRICHMENT',
						'DYNAMICS_ENRICHMENT',
						'SNOWFLAKE_ENRICHMENT',
					]}
					permission="read:enrich_task"
				/>
			}
		/>
		<Route
			path="normalization/*"
			element={
				<TaskRoutes
					features={[
						'SALESFORCE_NORMALIZATION',
						'MARKETO_NORMALIZATION',
						'ELOQUA_NORMALIZATION',
						'PARDOT_NORMALIZATION',
						'HUBSPOT_NORMALIZATION',
						'DYNAMICS_NORMALIZATION',
					]}
					permission="read:normalize/segment_task"
				/>
			}
		/>
		<Route
			path="mass-delete/*"
			element={
				<TaskRoutes
					features={[
						'SALESFORCE_MASS_DELETE',
						'MARKETO_MASS_DELETE',
						'PARDOT_MASS_DELETE',
						'ELOQUA_MASS_DELETE',
						'HUBSPOT_MASS_DELETE',
						'DYNAMICS_MASS_DELETE',
					]}
					permission="read:mass_delete_task"
				/>
			}
		/>
		<Route
			path="mass-update/*"
			element={
				<TaskRoutes
					features={[
						'SALESFORCE_MASS_UPDATE',
						'MARKETO_MASS_UPDATE',
						'PARDOT_MASS_UPDATE',
						'ELOQUA_MASS_UPDATE',
						'HUBSPOT_MASS_UPDATE',
						'DYNAMICS_MASS_UPDATE',
					]}
					permission="read:mass_update_task"
				/>
			}
		/>
		<Route
			path="list-import/*"
			element={
				<TaskRoutes
					features={[
						'SALESFORCE_LIST_IMPORT',
						'ELOQUA_LIST_IMPORT',
						'HUBSPOT_LIST_IMPORT',
						'DYNAMICS_LIST_IMPORT',
					]}
					permission="read:list_import_task"
				/>
			}
		/>
		<Route
			path="web-submission/*"
			element={
				<TaskRoutes
					features={[
						'SALESFORCE_W2L',
						'SF_ONLY_PREVENTION',
						'W2L_MARKETO',
						'ELOQUA_W2L',
						'PARDOT_W2L',
						'HUBSPOT_W2L',
						'DYNAMICS_W2L',
					]}
					permission="read:web_submission_task"
				/>
			}
		/>
		<Route
			path="webapi/*"
			element={<TaskRoutes features={['ENRICHMENT_API']} permission="read:enrich_api_task" />}
		/>
		<Route
			path="unique-entry/*"
			element={<TaskRoutes features={['ENRICHMENT_API']} permission="read:unique_entry_task" />}
		/>
		<Route path="discovery/*" element={<DiscoveryRoutes />} />
		<Route path="system-dictionaries" element={<SystemDictionaries />} />
		<Route path="licensing/*" element={<LicensingRoutes />} />
		<Route path="connections">{ConnectionsRoutes}</Route>
		<Route path="replace-values" element={<ReplaceValuesList />} />
		<Route path="scheduling" element={<ListScheduledTasks />} />
		<Route path="notifications" element={<Settings />} />
		<Route path="templates/*" element={<TemplateRoutes />} />
		<Route path="user-management/*" element={<UserRoutes />} />
		<Route path="auth-api-access" element={<APIAccess />} />
		<Route path="auth-manage" element={<OAuthManagement />} />
		<Route path="vendor-manage/vendor-keys" element={<VendorKeys />} />
		<Route path="segmentation/*" element={<SegmentationRoutes />} />
		<Route path="score/*" element={<ScoreRoutes />} />
		<Route path="normalize/*" element={<NormalizeRoutes />} />
		<Route path="routing/*" element={<RoutingRoutes />} />
		<Route path="package-management/*" element={<PackageManagementRoutes />} />
		<Route path="*" element={<NotFoundPage />} />
	</Routes>
);
