import { createApi } from '@reduxjs/toolkit/query/react';
import baseQueryWithNProgress from 'app/utils/services/baseQueryWithNProgress';

import type { SegmentConfig, SegmentLabel } from '../types/segmentTypes';

const BASE_URI = '/segmentation/';

export const segmentConfigApi = createApi({
	reducerPath: 'segmentConfigApi',
	baseQuery: baseQueryWithNProgress,
	tagTypes: ['SegmentLabel'],
	endpoints: builder => ({
		getSegmentLabels: builder.query<SegmentLabel[], void>({
			query: () => '/segmentation/labels/',
			providesTags: ['SegmentLabel'],
		}),
		getSegmentConfigs: builder.query<SegmentConfig[], void>({
			query: () => BASE_URI,
		}),
		getSegmentConfig: builder.query<SegmentConfig, number>({
			query: id => `/segmentation/${id}/`,
		}),
		editSegmentLabel: builder.mutation<SegmentLabel, Omit<SegmentLabel, 'is_output'>>({
			query: ({ id, ...body }) => ({
				url: `/segmentation/labels/${id}/`,
				method: 'PATCH',
				body,
			}),
			async onQueryStarted(_, { dispatch, queryFulfilled }) {
				try {
					const { data } = await queryFulfilled;
					dispatch(
						segmentConfigApi.util.updateQueryData('getSegmentLabels', undefined, draft => {
							draft.forEach(segmentLabel => {
								if (segmentLabel.id === data.id) {
									Object.assign(segmentLabel, data);
								}
							});
						})
					);
				} catch {
					// do nothing
				}
			},
		}),
		createSegmentConfig: builder.mutation<
			SegmentConfig,
			| { label: string; template: number | null; is_score_type: boolean }
			| { label: string; file_delimiter: string; file_charset: string; is_score_type: boolean; file: File }
		>({
			query: body => {
				const params: Record<string, string | number> = { label: body.label };

				if ('file' in body) {
					const formData = new FormData();
					formData.append('file', body.file);
					formData.append('file_delimiter', body.file_delimiter);
					formData.append('file_charset', body.file_charset);
					formData.append('is_score_type', body.is_score_type.toString());

					return {
						url: BASE_URI,
						method: 'POST',
						body: formData,
						formData: true,
						params,
					};
				}

				if (body.template) {
					params.template = body.template;
				}

				return {
					url: BASE_URI,
					method: 'POST',
					body: { is_score_type: body.is_score_type },
					params,
				};
			},
			async onQueryStarted({ label }, { dispatch, queryFulfilled }) {
				try {
					const { data } = await queryFulfilled;
					dispatch(
						segmentConfigApi.util.updateQueryData('getSegmentLabels', undefined, draft => {
							draft.push({ id: data.label, name: label, is_output: true });
						})
					);
					dispatch(
						segmentConfigApi.util.updateQueryData('getSegmentConfigs', undefined, draft => {
							draft.push(data);
						})
					);
				} catch {
					// do nothing
				}
			},
		}),
		editSegmentConfig: builder.mutation<SegmentConfig, Partial<SegmentConfig> & { id: number }>({
			query: ({ id, ...body }) => ({
				url: `/segmentation/${id}/`,
				method: 'PATCH',
				body,
			}),
			async onQueryStarted(_, { dispatch, queryFulfilled }) {
				try {
					const { data } = await queryFulfilled;
					dispatch(
						segmentConfigApi.util.updateQueryData('getSegmentConfigs', undefined, draft => {
							draft.forEach(segmentConfig => {
								if (segmentConfig.id === data.id) {
									Object.assign(segmentConfig, data);
								}
							});
						})
					);
					dispatch(
						segmentConfigApi.util.updateQueryData('getSegmentConfig', data.id, draft => {
							Object.assign(draft, data);
						})
					);
				} catch {
					// do nothing
				}
			},
		}),
		copySegmentConfig: builder.mutation<SegmentConfig, { id: number; label: string }>({
			query: ({ id, ...body }) => ({
				url: `/segmentation/${id}/_copy/`,
				method: 'POST',
				body,
			}),
			async onQueryStarted(_, { dispatch, queryFulfilled }) {
				try {
					const { data } = await queryFulfilled;
					dispatch(
						segmentConfigApi.util.updateQueryData('getSegmentConfigs', undefined, draft => {
							draft.push(data);
						})
					);
				} catch {
					// do nothing
				}
			},
			invalidatesTags: ['SegmentLabel'],
		}),
		deleteSegmentConfig: builder.mutation<unknown, number>({
			query: id => ({
				url: `/segmentation/${id}/`,
				method: 'DELETE',
			}),
			onQueryStarted(id, { dispatch, queryFulfilled }) {
				const patchResult = dispatch(
					segmentConfigApi.util.updateQueryData('getSegmentConfigs', undefined, draft =>
						draft.filter(segmentConfig => segmentConfig.id !== id)
					)
				);
				queryFulfilled.catch(patchResult.undo);
			},
		}),
	}),
});

export const {
	useGetSegmentLabelsQuery,
	useGetSegmentConfigsQuery,
	useGetSegmentConfigQuery,
	useEditSegmentLabelMutation,
	useCreateSegmentConfigMutation,
	useEditSegmentConfigMutation,
	useCopySegmentConfigMutation,
	useDeleteSegmentConfigMutation,
} = segmentConfigApi;
