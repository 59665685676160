import { useEffect } from 'react';
import { useLDClient } from 'launchdarkly-react-client-sdk';

function LDClientClose() {
	const ldClient = useLDClient();

	useEffect(
		() => () => {
			ldClient?.close();
		},
		[ldClient]
	);

	return null;
}

export default LDClientClose;
