import { useDispatch } from 'react-redux';
import { createBrowserRouter, Route, RouterProvider, Routes } from 'react-router-dom';

import ConfirmationModalContextProvider from './utils/components/ConfirmModal/modalConfirmationContext';
import NotFoundPage from './utils/components/NotFoundPage';
import RingLeadLoadable from './utils/components/RingLeadLoadable/RingLeadLoadable';
import RingLeadSnackbarProvider from './utils/components/Snackbar/RingLeadSnackbarProvider';
import Root from './Root';

const Login = RingLeadLoadable({
	loader: () => import('./auth/components/LoginHandler'),
});

const ForgotPassword = RingLeadLoadable({
	loader: () => import('./auth/components/ForgotPassword'),
});

const ConfirmResetPassword = RingLeadLoadable({
	loader: () => import('./auth/components/ConfirmResetPassword'),
});

const Register = RingLeadLoadable({
	loader: () => import('./auth/components/Register'),
});

export const LOCATION_CHANGE = '@@router/LOCATION_CHANGE';

const router = createBrowserRouter([
	{
		path: '*',
		element: (
			<RingLeadSnackbarProvider>
				<ConfirmationModalContextProvider>
					<Routes>
						<Route path="auth">
							<Route path="login" element={<Login />} />
							<Route path="forgot_password">
								<Route index element={<ForgotPassword />} />
								<Route path=":UID/:token" element={<ConfirmResetPassword />} />
							</Route>
							<Route path="register/:UUID" element={<Register />} />
							<Route path="*" element={<NotFoundPage />} />
						</Route>
						<Route path="*" element={<Root />} />
					</Routes>
				</ConfirmationModalContextProvider>
			</RingLeadSnackbarProvider>
		),
	},
]);

function Router() {
	const dispatch = useDispatch();

	// TODO: remove once get rid of such actions in reducers
	router.subscribe(state => {
		if (state.blockers.size === 0) {
			dispatch({
				type: LOCATION_CHANGE,
				payload: { location: state.location },
			});
		}
	});

	return <RouterProvider router={router} />;
}

export default Router;
