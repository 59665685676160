import { Route, Routes } from 'react-router';
import useHasFeatureAccess from 'app/admin/hooks/useHasFeatureAccess';
import ForbiddenPage from 'ringlead_utils/components/ForbiddenPage';
import NotFoundPage from 'ringlead_utils/components/NotFoundPage';
import RingLeadLoadable from 'ringlead_utils/components/RingLeadLoadable/RingLeadLoadable';

const RoutingWorkingHours = RingLeadLoadable({
	loader: () => import('../../routing/components/workinghours/List'),
});

const WorkingHoursDetailsPage = RingLeadLoadable({
	loader: () => import('../../routing/components/workinghours/WhDetailsContainer'),
});

function RoutingWorkingHoursRoutes() {
	const hasPermissions = useHasFeatureAccess(null, 'read:weekly_working_hours');

	if (!hasPermissions) {
		return <ForbiddenPage permissions={['read:weekly_working_hours']} />;
	}

	return (
		<Routes>
			<Route index element={<RoutingWorkingHours />} />
			<Route path=":id" element={<WorkingHoursDetailsPage />} />
			<Route path="*" element={<NotFoundPage />} />
		</Routes>
	);
}

export default RoutingWorkingHoursRoutes;
