import { useSelector } from 'react-redux';
import CreditCardOutlinedIcon from '@mui/icons-material/CreditCardOutlined';
import IconButton from '@mui/material/IconButton';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Menu from '@mui/material/Menu';
import Tooltip from '@mui/material/Tooltip';
import { useGetFeaturesQuery } from 'app/licensing/services/feature';
import type { RootState } from 'app/utils/stores/store-tmp-type';
import { bindMenu, bindTrigger, usePopupState } from 'material-ui-popup-state/hooks';

const slotProps = {
	list: {
		sx: { p: 0, minWidth: 500 },
	},
};

function CreditsUsage() {
	const { currentData: features = {} } = useGetFeaturesQuery();
	const popupState = usePopupState({ variant: 'popover', popupId: 'creditsUsage', disableAutoFocus: true });
	const user = useSelector((state: RootState) => state.auth.user);

	const creditsList = Object.entries(user.usage_and_limits_state)
		.map(([key, value]) => {
			if (key.endsWith('_CREDIT') && value) {
				return {
					label: (features[key] || key).replace(' Credit', '').replace(' Credits', ''),
					credit: value,
				};
			}

			return null;
		})
		.filter(
			(
				credit
			): credit is {
				label: string;
				credit: {
					value: number | null;
					used: number;
				};
			} => !!credit?.credit
		)
		.sort((a, b) => a.label.localeCompare(b.label));

	if (creditsList.length === 0) {
		return null;
	}

	return (
		<>
			<Tooltip title="Credits Usage" enterDelay={300}>
				<IconButton id="nav-credits-usage" color="inherit" size="large" {...bindTrigger(popupState)}>
					<CreditCardOutlinedIcon />
				</IconButton>
			</Tooltip>
			<Menu id="credits-usage" {...bindMenu(popupState)} slotProps={slotProps}>
				{creditsList.map(credit => (
					<ListItem key={credit.label} divider dense sx={{ outline: 'none' }}>
						<ListItemText>{credit.label}</ListItemText>
						<ListItemText sx={{ textAlign: 'right' }}>
							{credit.credit.value === null
								? '∞ Left'
								: `${(credit.credit.value - credit.credit.used).toLocaleString()} of ${(
										credit.credit.value || 0
									).toLocaleString()} Left`}
						</ListItemText>
					</ListItem>
				))}
			</Menu>
		</>
	);
}

export default CreditsUsage;
