import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

const StyledPaper = styled(Paper)(({ theme }) => ({
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	padding: theme.spacing(2, 3, 3),
}));

function NotFoundPage() {
	return (
		<StyledPaper>
			<Typography component="h1" variant="h4">
				Page Not Found
			</Typography>
			<Typography component="h5" variant="body1" align="center">
				Sorry, but the page you are looking for has not been found. Try checking the URL for error, then hit the
				refresh button on your browser or try found something else in our app.
			</Typography>
		</StyledPaper>
	);
}

export default NotFoundPage;
