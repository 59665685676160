import { useCallback } from 'react';
import { Link, useMatch } from 'react-router-dom';
import BusinessIcon from '@mui/icons-material/Business';
import BusinessCenterOutlinedIcon from '@mui/icons-material/BusinessCenterOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import IconButton from '@mui/material/IconButton';
import Menu, { type MenuProps } from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';
import { amplitudeTrackEvent } from 'app/integrations/amplitude/amplitude';
import { bindMenu, bindTrigger, usePopupState } from 'material-ui-popup-state/hooks';
import parseForId from 'ringlead_utils/helpers/parseForId';

import { ICON_TYPES } from '../Constants';

interface Item {
	label: string;
	href: string;
	help?: string;
}

interface IconMenuItemProps {
	iconTitle: string;
	item: Item;
}

interface IconWithMenuProps {
	iconTitle: string;
	iconType: string;
	items: Item[];
}

const IconTypes = {
	[ICON_TYPES.ADMIN]: BusinessIcon,
	[ICON_TYPES.SUPER_ADMIN]: BusinessCenterOutlinedIcon,
	[ICON_TYPES.SETTINGS]: SettingsOutlinedIcon,
};

const anchorOrigin: MenuProps['anchorOrigin'] = { vertical: 'bottom', horizontal: 'center' };
const transformOrigin: MenuProps['transformOrigin'] = { vertical: 'top', horizontal: 'center' };

function IconMenuItem({ iconTitle, item }: IconMenuItemProps) {
	const match = useMatch(item.href);

	const handleMenuItemOpen = useCallback(() => {
		const tool = `${iconTitle} - ${item.label}`;
		amplitudeTrackEvent('PageNavigation', {
			source: window.amplitudeSource,
			tool,
			activity_group: 'Navigation',
		});
		window.amplitudeSource = tool;
	}, [iconTitle, item]);

	return (
		<MenuItem
			id={`${parseForId(iconTitle)}-${parseForId(item.label)}`}
			component={Link}
			to={item.href}
			onClick={handleMenuItemOpen}
			selected={!!match}
		>
			{item.help ? (
				<Tooltip title={item.help} placement="right">
					<span>{item.label}</span>
				</Tooltip>
			) : (
				item.label
			)}
		</MenuItem>
	);
}

function IconWithMenu({ iconTitle, iconType, items }: IconWithMenuProps) {
	const popupState = usePopupState({
		variant: 'popover',
		popupId: `nav-${parseForId(iconTitle)}`,
		disableAutoFocus: true,
	});
	const Icon = IconTypes[iconType];

	return (
		<>
			<Tooltip title={iconTitle} enterDelay={300}>
				<IconButton
					id={`nav-${parseForId(iconTitle)}`}
					color="inherit"
					size="large"
					{...bindTrigger(popupState)}
				>
					<Icon />
				</IconButton>
			</Tooltip>
			<Menu
				id={`nav-${parseForId(iconTitle)}-menu`}
				anchorOrigin={anchorOrigin}
				transformOrigin={transformOrigin}
				{...bindMenu(popupState)}
				onClick={popupState.close}
			>
				{items.map(item => (
					<IconMenuItem key={item.label} iconTitle={iconTitle} item={item} />
				))}
			</Menu>
		</>
	);
}

export default IconWithMenu;
