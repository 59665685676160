import { LOCATION_CHANGE } from 'app/Router';

import { CustomPackageVendorConstants } from '../Constants';

const initialState = {
	fetchingPackageVendorApi: false,
	updatingPackageVendorApi: false,
	packageVendorApi: null,
	vendorApiSample: {},
	vendorApiLiveSample: {},
	fetchingVendorApiLiveSample: false,
	vendorApiLiveQuery: [],
	fetchingVendorApiLiveQuery: false,
	guessedEnrichElements: {},
};

export default (state = initialState, action = {}) => {
	switch (action.type) {
		case LOCATION_CHANGE:
			return initialState;

		case CustomPackageVendorConstants.PACKAGE_VENDOR_API_GET:
			return { ...state, fetchingPackageVendorApi: true, packageVendorApi: null };

		case CustomPackageVendorConstants.PACKAGE_VENDOR_API_GET_SUCCESS:
			return { ...state, fetchingPackageVendorApi: false, packageVendorApi: action.data };

		case CustomPackageVendorConstants.PACKAGE_VENDOR_API_GET_FAIL:
			return { ...state, fetchingPackageVendorApi: false, packageVendorApi: null };

		case CustomPackageVendorConstants.PACKAGE_VENDOR_API_UPDATE:
			return { ...state, updatingPackageVendorApi: true };

		case CustomPackageVendorConstants.PACKAGE_VENDOR_API_UPDATE_SUCCESS:
			return {
				...state,
				fetchingPackageVendorApi: false,
				updatingPackageVendorApi: false,
				packageVendorApi: { ...(state.packageVendorApi || {}), ...action.data },
			};

		case CustomPackageVendorConstants.PACKAGE_VENDOR_API_UPDATE_FAIL:
			return {
				...state,
				fetchingPackageVendorApi: false,
				updatingPackageVendorApi: false,
			};

		case CustomPackageVendorConstants.PACKAGE_VENDOR_API_SAMPLE_GET_SUCCESS:
			return { ...state, vendorApiSample: action.data };

		case CustomPackageVendorConstants.PACKAGE_VENDOR_API_SAMPLE_GET_FAIL:
			return { ...state, vendorApiSample: {} };

		case CustomPackageVendorConstants.PACKAGE_VENDOR_API_LIVE_QUERY_GET:
			return {
				...state,
				fetchingVendorApiLiveQuery: true,
				...(action.keepResults ? {} : { vendorApiSample: {} }),
			};

		case CustomPackageVendorConstants.PACKAGE_VENDOR_API_LIVE_QUERY_GET_SUCCESS:
			return { ...state, vendorApiLiveQuery: action.data, fetchingVendorApiLiveQuery: false };

		case CustomPackageVendorConstants.PACKAGE_VENDOR_API_LIVE_QUERY_GET_FAIL:
			return { ...state, vendorApiLiveQuery: [], fetchingVendorApiLiveQuery: false };

		case CustomPackageVendorConstants.PACKAGE_VENDOR_API_LIVE_SAMPLE_GET:
			return { ...state, fetchingVendorApiLiveSample: true };

		case CustomPackageVendorConstants.PACKAGE_VENDOR_API_LIVE_SAMPLE_GET_SUCCESS:
			return { ...state, vendorApiLiveSample: action.data, fetchingVendorApiLiveSample: false };

		case CustomPackageVendorConstants.PACKAGE_VENDOR_API_LIVE_SAMPLE_GET_FAIL:
			return { ...state, vendorApiLiveSample: {}, fetchingVendorApiLiveSample: false };

		case CustomPackageVendorConstants.PACKAGE_VENDOR_API_GUESS_ENRICH_ELEMENT_GET_SUCCESS:
			return { ...state, guessedEnrichElements: action.data };

		case CustomPackageVendorConstants.PACKAGE_VENDOR_API_GUESS_ENRICH_ELEMENT_GET_FAIL:
			return { ...state, guessedEnrichElements: {} };

		default:
			return state;
	}
};
