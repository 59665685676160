import { createApi } from '@reduxjs/toolkit/query/react';
import pusher from 'app/integrations/pusher/pusher';
import baseQueryWithNProgress from 'app/utils/services/baseQueryWithNProgress';

import { ConnectionStatuses } from '../constants/ConnectionConstants';
import type { Connection, ConnectionRelatedTasks, ConnectionType } from '../types/connectionTypes';

export const connectionApi = createApi({
	reducerPath: 'connectionApi',
	baseQuery: baseQueryWithNProgress,
	tagTypes: [
		'ConnectionTable',
		'ConnectionChildTable',
		'ConnectionField',
		'ConnectionFieldPicklistValue',
		'ConnectionForm',
	],
	endpoints: builder => ({
		getConnections: builder.query<Connection[], string>({
			query: connectionType => `/connection/${connectionType}/`,
			async onCacheEntryAdded(_, { dispatch, updateCachedData, cacheDataLoaded, cacheEntryRemoved }) {
				const listener = ({ id, ...patch }: Connection) => {
					updateCachedData(draft => {
						draft.forEach(connection => {
							if (connection.id === id) {
								Object.assign(connection, patch);
							}
						});
					});

					if (patch.status === ConnectionStatuses.ACTIVE) {
						dispatch(
							connectionApi.util.invalidateTags([
								{ type: 'ConnectionTable', id },
								{ type: 'ConnectionChildTable', id },
								{ type: 'ConnectionField', id },
								{ type: 'ConnectionFieldPicklistValue', id },
								{ type: 'ConnectionForm', id },
							])
						);
					}
				};

				try {
					await cacheDataLoaded;

					pusher.bind('connection_status_update', listener);
				} catch {
					/* empty */
				}
				await cacheEntryRemoved;
				pusher.unbind('connection_status_update', listener);
			},
		}),
		getConnection: builder.query<Connection, { connectionType: string; connectionId: number }>({
			query: ({ connectionType, connectionId }) => `/connection/${connectionType}/${connectionId}/`,
			async onCacheEntryAdded(_, { dispatch, updateCachedData, cacheDataLoaded, cacheEntryRemoved }) {
				const listener = ({ id, ...patch }: Connection) => {
					updateCachedData(draft => {
						if (draft.id === id) {
							Object.assign(draft, patch);
						}
					});

					if (patch.status === ConnectionStatuses.ACTIVE) {
						dispatch(
							connectionApi.util.invalidateTags([
								{ type: 'ConnectionTable', id },
								{ type: 'ConnectionChildTable', id },
								{ type: 'ConnectionField', id },
								{ type: 'ConnectionFieldPicklistValue', id },
								{ type: 'ConnectionForm', id },
							])
						);
					}
				};

				try {
					await cacheDataLoaded;

					pusher.bind('connection_status_update', listener);
				} catch {
					/* empty */
				}
				await cacheEntryRemoved;
				pusher.unbind('connection_status_update', listener);
			},
		}),
		getRelatedTasks: builder.query<ConnectionRelatedTasks, { connectionType: string; connectionId: number }>({
			query: ({ connectionType, connectionId }) =>
				`/connection/${connectionType}/${connectionId}/_related_tasks/`,
		}),
		createConnection: builder.mutation<Connection, Partial<Connection> & { connection_type: ConnectionType }>({
			query: ({ connection_type: connectionType, ...body }) => ({
				url: `/connection/${connectionType}/`,
				method: 'POST',
				body,
			}),
			async onQueryStarted({ connection_type: connectionType }, { dispatch, queryFulfilled }) {
				try {
					const { data } = await queryFulfilled;
					dispatch(
						connectionApi.util.updateQueryData('getConnections', connectionType, draft => [...draft, data])
					);
				} catch {
					/* empty */
				}
			},
		}),
		updateConnections: builder.mutation<
			Connection[],
			(Partial<Connection> & { id: number; connection_type: string })[]
		>({
			query: body => ({
				url: `/connection/${body[0].connection_type}/`,
				method: 'PATCH',
				body,
			}),
			onQueryStarted(body, { dispatch, queryFulfilled }) {
				const patchResult = dispatch(
					connectionApi.util.updateQueryData('getConnections', body[0].connection_type, draft => {
						draft.forEach(connection => {
							const updatedConnection = body.find(({ id }) => id === connection.id);
							if (updatedConnection) {
								Object.assign(connection, updatedConnection);
							}
						});
					})
				);
				queryFulfilled.catch(patchResult.undo);
			},
		}),
		updateConnection: builder.mutation<Connection, Partial<Connection> & { id: number; connection_type: string }>({
			query: ({ id, connection_type: connectionType, ...body }) => ({
				url: `/connection/${connectionType}/${id}/`,
				method: 'PATCH',
				body,
			}),
			onQueryStarted({ id, connection_type: connectionType, ...patch }, { dispatch, queryFulfilled }) {
				const patchResultList = dispatch(
					connectionApi.util.updateQueryData('getConnections', connectionType, draft => {
						draft.forEach(connection => {
							if (connection.id === id) {
								Object.assign(connection, patch);
							}
						});
					})
				);
				const patchResultDetails = dispatch(
					connectionApi.util.updateQueryData('getConnection', { connectionType, connectionId: id }, draft => {
						Object.assign(draft, patch);
					})
				);
				queryFulfilled.catch(() => {
					patchResultList.undo();
					patchResultDetails.undo();
				});
			},
		}),
		updateConnectionOwner: builder.mutation<
			Connection,
			{
				connectionType: string;
				connectionId: number;
				owner_group_id: number | null;
				owner_user_id: number | null;
			}
		>({
			query: ({ connectionType, connectionId, ...body }) => ({
				url: `/connection/${connectionType}/${connectionId}/owner/`,
				method: 'PATCH',
				body,
			}),
			async onQueryStarted({ connectionType, connectionId }, { dispatch, queryFulfilled }) {
				try {
					const { data } = await queryFulfilled;
					dispatch(
						connectionApi.util.updateQueryData('getConnections', connectionType, draft => {
							draft.forEach(connection => {
								if (connection.id === data.id) {
									Object.assign(connection, data);
								}
							});
						})
					);
					dispatch(
						connectionApi.util.updateQueryData('getConnection', { connectionType, connectionId }, draft => {
							Object.assign(draft, data);
						})
					);
				} catch {
					/* empty */
				}
			},
		}),
		refreshConnection: builder.mutation<Connection, { connectionType: string; connectionId: number }>({
			query: ({ connectionType, connectionId }) =>
				`/connection/${connectionType}/${connectionId}/_refresh_schema/`,
			async onQueryStarted({ connectionType, connectionId }, { dispatch, queryFulfilled }) {
				try {
					const { data } = await queryFulfilled;
					dispatch(
						connectionApi.util.updateQueryData('getConnections', connectionType, draft => {
							draft.forEach(connection => {
								if (connection.id === data.id) {
									Object.assign(connection, data);
								}
							});
						})
					);
					dispatch(
						connectionApi.util.updateQueryData('getConnection', { connectionType, connectionId }, draft => {
							Object.assign(draft, data);
						})
					);
				} catch {
					/* empty */
				}
			},
		}),
		cancelRefreshConnection: builder.mutation<Connection, { connectionType: string; connectionId: number }>({
			query: ({ connectionType, connectionId }) =>
				`/connection/${connectionType}/${connectionId}/_cancel_refresh_schema/`,
			async onQueryStarted({ connectionType, connectionId }, { dispatch, queryFulfilled }) {
				try {
					const { data } = await queryFulfilled;
					dispatch(
						connectionApi.util.updateQueryData('getConnections', connectionType, draft => {
							draft.forEach(connection => {
								if (connection.id === data.id) {
									Object.assign(connection, data);
								}
							});
						})
					);
					dispatch(
						connectionApi.util.updateQueryData('getConnection', { connectionType, connectionId }, draft => {
							Object.assign(draft, data);
						})
					);
				} catch {
					/* empty */
				}
			},
		}),
		deleteConnection: builder.mutation<unknown, { connectionType: string; connectionId: number }>({
			query: ({ connectionType, connectionId }) => ({
				url: `/connection/${connectionType}/${connectionId}/`,
				method: 'DELETE',
			}),
			async onQueryStarted({ connectionType, connectionId }, { dispatch, queryFulfilled }) {
				try {
					await queryFulfilled;
					dispatch(
						connectionApi.util.updateQueryData('getConnections', connectionType, draft =>
							draft.filter(connection => connection.id !== connectionId)
						)
					);
				} catch {
					/* empty */
				}
			},
		}),
	}),
});

export const {
	useGetConnectionsQuery,
	useGetConnectionQuery,
	useGetRelatedTasksQuery,
	useCreateConnectionMutation,
	useUpdateConnectionMutation,
	useUpdateConnectionOwnerMutation,
	useRefreshConnectionMutation,
	useCancelRefreshConnectionMutation,
	useDeleteConnectionMutation,
} = connectionApi;
