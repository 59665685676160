import { LOCATION_CHANGE } from 'app/Router';

import { CalendarStatusConstants } from '../Constants';

const initialState = {
	calendarConnectedStatus: {},
	fetching: false,
};

export default (state = initialState, action = {}) => {
	switch (action.type) {
		case LOCATION_CHANGE:
			return initialState;

		case CalendarStatusConstants.CALENDAR_CONNECTED_STATUS_REQUEST:
			return { ...state, fetching: true };

		case CalendarStatusConstants.CALENDAR_CONNECTED_STATUS_SUCCESS:
			return { ...state, fetching: false, calendarConnectedStatus: action.data };

		case CalendarStatusConstants.CALENDAR_CONNECTED_STATUS_FAIL:
			return { ...state, fetching: false };

		default:
			return state;
	}
};
