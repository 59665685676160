import Link from '@mui/material/Link';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { useGetAdminUsersQuery } from 'app/admin/services/user';

function OrganizationAdminList() {
	const { currentData: adminUsers = [] } = useGetAdminUsersQuery();

	if (adminUsers.length === 0) {
		return null;
	}

	return (
		<List disablePadding>
			{adminUsers.map(adminUser => (
				<ListItem key={adminUser.id} dense disableGutters>
					<ListItemText
						primary={
							<>
								{adminUser.first_name} {adminUser.last_name} - RingLead Administrator -{' '}
								<Link
									href={`mailto:${adminUser.email}`}
									target="_blank"
									rel="noopener noreferrer"
									underline="hover"
								>
									{adminUser.email}{' '}
								</Link>
							</>
						}
					/>
				</ListItem>
			))}
		</List>
	);
}

export default OrganizationAdminList;
