import { LOCATION_CHANGE } from 'app/Router';

import { NormalizerRuleConstants } from '../Constants';

const initialState = {
	normalizerFields: [],
	fetchingNormalizerFields: false,
};

export default (state = initialState, action = {}) => {
	switch (action.type) {
		case LOCATION_CHANGE:
			return initialState;

		case NormalizerRuleConstants.NORMALIZER_FIELDS_GET:
			return { ...state, fetchingNormalizerFields: true };

		case NormalizerRuleConstants.NORMALIZER_FIELDS_GET_SUCCESS:
			return { ...state, normalizerFields: action.data, fetchingNormalizerFields: false };

		case NormalizerRuleConstants.NORMALIZER_FIELDS_GET_FAIL:
			return { ...state, normalizerFields: [], fetchingNormalizerFields: false };

		default:
			return state;
	}
};
