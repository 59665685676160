import { cloneElement, type MouseEvent, type ReactElement, useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, matchPath, useLocation, useMatch } from 'react-router-dom';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { amplitudeTrackEvent } from 'app/integrations/amplitude/amplitude';
import type { RootState } from 'app/utils/stores/store-tmp-type';
import parseForId from 'ringlead_utils/helpers/parseForId';

import { type MenuItem, type MenuItems } from '../Helper';
import { toggleNavItemOpen } from '../services/navbarSlice';

import NavItemIcon from './NavItemIcon';

interface NavItemProps {
	link: MenuItem | MenuItems;
	depth: number;
	hash: string;
	activeBorder?: boolean;
	children?: ReactElement;
}

function NavItem({ link, depth, hash, children, activeBorder }: NavItemProps) {
	const dispatch = useDispatch();
	const { pathname } = useLocation();

	const open = useSelector((state: RootState) => state.navbar.open);
	const opened = useSelector((state: RootState) => state.navbar.navbarState[hash]);

	const { label, help, external, href, tool } = link;

	const hrefToMatch = useMemo(() => {
		if (!href) {
			return '';
		}

		if (href === '/') {
			return '/';
		}

		return `${href}*`;
	}, [href]);

	const matchHref = useMatch(hrefToMatch);
	const matchExact = !!useMatch(href ?? '');
	const matched = !!matchHref;
	const matchedChild = useMemo(() => {
		const links = 'items' in link && link.items?.length ? link.items?.map(item => item.href ?? 'not_found') : [];
		return !!links.find(path => !!matchPath(`${path}*`, pathname));
	}, [pathname]);

	const ExpandIcon = opened ? ExpandLessIcon : ExpandMoreIcon;

	useEffect(() => {
		if (matched) {
			window.amplitudeSource = link.tool;
		}
	}, [matched]);

	const listItemButtonProps = useMemo(() => {
		if (!href) {
			return {};
		}

		return external ? { component: 'a', href } : { component: Link, to: href };
	}, [href, external]);

	const listItemSlotProps = useMemo(() => {
		const defaultListItemSlotProps = {
			primary: {
				sx: { pl: depth === 0 ? 0 : 3 + 2 * depth },
			},
		};
		if (!matched && !matchedChild) {
			return defaultListItemSlotProps;
		}
		return {
			primary: {
				color: 'primary',
				...(depth > 0 ? { fontWeight: 'bold' } : {}),
				...defaultListItemSlotProps.primary,
			},
		};
	}, [matched, matchedChild, depth]);

	const handleMenuClick = useCallback(
		(e: MouseEvent) => {
			if (matchExact) {
				e.preventDefault();
			}

			if (href) {
				amplitudeTrackEvent('OpenedTool', {
					source: window.amplitudeSource,
					tool,
					activity_group: 'Navigation',
				});
				window.amplitudeSource = tool;
			}

			if ((href && !opened) || !href) {
				dispatch(toggleNavItemOpen(hash));
			}
		},
		[dispatch, href, opened, tool, hash, matchExact]
	);

	const handleClickExpandIcon = useCallback(
		(e: MouseEvent) => {
			e.preventDefault();
			e.stopPropagation();
			dispatch(toggleNavItemOpen(hash));
		},
		[dispatch, hash]
	);

	return (
		<>
			<ListItem
				id={`nav-${parseForId(label)}`}
				disablePadding
				sx={theme => ({
					...(matched && depth !== 0
						? {
								backgroundColor: theme.palette.action.hover,
							}
						: {}),
					...(matched || matchedChild || activeBorder
						? {
								borderLeft: `6px solid ${theme.palette.primary.main}`,
							}
						: {}),
				})}
			>
				<ListItemButton
					{...listItemButtonProps}
					onClick={handleMenuClick}
					sx={{ ...(!children ? { pr: 0 } : {}), ...(depth === 0 ? { py: 2 } : {}) }}
				>
					<NavItemIcon link={link} open={open} matched={matched || matchedChild} />
					{help ? (
						<Tooltip
							enterDelay={1000}
							enterNextDelay={1000}
							title={<Typography variant="caption" dangerouslySetInnerHTML={{ __html: help }} />}
							placement="right"
						>
							<ListItemText slotProps={listItemSlotProps}>{label}</ListItemText>
						</Tooltip>
					) : (
						<ListItemText slotProps={listItemSlotProps}>{label}</ListItemText>
					)}
					{children && (
						<IconButton size="small" onClick={handleClickExpandIcon}>
							<ExpandIcon color={matched || matchedChild ? 'primary' : undefined} />
						</IconButton>
					)}
				</ListItemButton>
			</ListItem>
			{children && (
				<Collapse in={open && opened} timeout="auto" unmountOnExit>
					{cloneElement(children, { activeBorder: matchedChild })}
				</Collapse>
			)}
		</>
	);
}

export default NavItem;
