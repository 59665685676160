import ListItemIcon from '@mui/material/ListItemIcon';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import { type MenuItem, type MenuItems } from '../Helper';

interface NavItemIconProps {
	link: MenuItem | MenuItems;
	open: boolean;
	matched: boolean;
}

function NavItemIcon({ link, open, matched }: NavItemIconProps) {
	const { label, icon: Icon, help_page_text: helpPageText } = link;

	if (!Icon) {
		return null;
	}

	const helpTooltip = helpPageText ? ` - ${helpPageText}` : '';
	const tooltip = `${label} ${helpTooltip}`;

	return open ? (
		<ListItemIcon sx={{ minWidth: 40 }}>
			<Icon color={matched ? 'primary' : undefined} />
		</ListItemIcon>
	) : (
		<Tooltip
			title={<Typography variant="caption" dangerouslySetInnerHTML={{ __html: tooltip }} />}
			placement="right"
		>
			<ListItemIcon>
				<Icon color={matched ? 'primary' : undefined} />
			</ListItemIcon>
		</Tooltip>
	);
}

export default NavItemIcon;
