import { createContext, type ReactNode, useContext, useMemo, useRef, useState } from 'react';
import Box from '@mui/material/Box';
import Button, { type ButtonProps } from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FormControlLabel from '@mui/material/FormControlLabel';
import TextField from '@mui/material/TextField';

import parseForId from '../../helpers/parseForId';

interface ModalContextContent {
	title: string;
	message: ReactNode;
	confirmationText?: string;
	doNotShowAgainKey?: string;
	buttonColor?: ButtonProps['color'];
	cancelText?: string;
	okText?: string;
}

interface ModalContextType {
	showConfirmation: (content: ModalContextContent) => Promise<boolean>;
}

const ConfirmationModalContext = createContext<ModalContextType>({} as ModalContextType);

function ConfirmationModalContextProvider({ children }: { children: ReactNode }) {
	const [show, setShow] = useState<boolean>(false);
	const [content, setContent] = useState<ModalContextContent | null>();
	const [confirmationTextValue, setConfirmationTextValue] = useState<string>('');
	const [doNotShowAgain, setDoNotShowAgain] = useState<boolean>(false);
	const [doNotShowAgainState, setDoNotShowAgainState] = useState<Record<string, boolean>>({});
	const resolver = useRef<(a: boolean) => void>();

	const handleShow = (c: ModalContextContent): Promise<boolean> => {
		if (c.doNotShowAgainKey && doNotShowAgainState[c.doNotShowAgainKey]) {
			return Promise.resolve(true);
		}
		setContent(c);
		setShow(true);
		return new Promise(resolve => {
			resolver.current = resolve;
		});
	};

	const modalContext = useMemo<ModalContextType>(
		() => ({
			showConfirmation: handleShow,
		}),
		[handleShow]
	);

	const onClose = () => {
		setShow(false);
		setContent(null);
		setConfirmationTextValue('');
		setDoNotShowAgain(false);
	};

	const handleOk = () => {
		resolver.current?.(true);
		if (doNotShowAgain) {
			setDoNotShowAgainState(prevState =>
				content?.doNotShowAgainKey ? { ...prevState, [content.doNotShowAgainKey]: true } : prevState
			);
		}
		onClose();
	};

	const handleCancel = () => {
		resolver.current?.(false);
		onClose();
	};

	return (
		<ConfirmationModalContext.Provider value={modalContext}>
			{children}

			{content && (
				<Dialog open={show} onClose={onClose} maxWidth="md" fullWidth>
					<DialogTitle>{content.title}</DialogTitle>
					<DialogContent>
						{content.message}
						{content.confirmationText && (
							<>
								<DialogContentText style={{ marginTop: 40 }}>
									To confirm, type <b>{content.confirmationText}</b> in this input
								</DialogContentText>
								<TextField
									variant="standard"
									value={confirmationTextValue}
									onChange={e => setConfirmationTextValue(e.target.value)}
									autoFocus
									margin="dense"
									fullWidth
								/>
							</>
						)}
					</DialogContent>
					<DialogActions>
						<Box display="flex" flex={1} justifyContent="space-between">
							<Box>
								{content.doNotShowAgainKey && (
									<FormControlLabel
										label="Do not show again"
										control={
											<Checkbox
												checked={doNotShowAgain}
												onChange={e => setDoNotShowAgain(e.target.checked)}
												color="primary"
											/>
										}
										sx={{ ml: 1 }}
									/>
								)}
							</Box>
							<Box display="flex" gap={1}>
								<Button onClick={handleCancel} color="inherit" variant="contained">
									{content.cancelText ?? 'Close'}
								</Button>
								{content.okText && (
									<Button
										id={`${parseForId(content.okText)}-button`}
										color={content.buttonColor ?? 'primary'}
										variant="contained"
										onClick={handleOk}
										disabled={
											!!content.confirmationText &&
											content.confirmationText !== confirmationTextValue
										}
									>
										{content.okText}
									</Button>
								)}
							</Box>
						</Box>
					</DialogActions>
				</Dialog>
			)}
		</ConfirmationModalContext.Provider>
	);
}

const useConfirmationModalContext = (): ModalContextType => useContext(ConfirmationModalContext);

export { useConfirmationModalContext };

export default ConfirmationModalContextProvider;
