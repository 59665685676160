import type { Organization } from 'app/licensing/types/organization';
import dayjs from 'dayjs';

import type { Notification } from './types/notification';
import { NotificationTypes } from './NotificationConstants';

export function getNotifications(notifications: Notification[]) {
	return notifications.filter(notification => {
		const start = dayjs(notification.start);
		const end = dayjs(notification.end);

		return start.isSame(dayjs()) || (start.isBefore(dayjs()) && !end.isBefore(dayjs()));
	});
}

export function isNotificationToShow(notification: Notification) {
	return (
		(notification.type === NotificationTypes.NEW_MEDIA_CONTENT ||
			notification.type === NotificationTypes.TASK_ERROR) &&
		notification.unread
	);
}

export function getNotificationSoundValue(notificationsSettings: Organization['notifications_settings']) {
	if (notificationsSettings && 'SOUND_ON' in notificationsSettings) {
		return notificationsSettings.SOUND_ON as boolean;
	}

	return true;
}
