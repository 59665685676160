import { PureComponent } from 'react';
import * as Sentry from '@sentry/react';

export const withErrorBoundary = ComposedComponent =>
	class extends PureComponent {
		constructor(props) {
			super(props);

			this.state = { eventId: null };
		}

		static getDerivedStateFromError() {
			return { hasError: true };
		}

		componentDidCatch(error, errorInfo) {
			Sentry.withScope(scope => {
				scope.setExtras(errorInfo);
				const eventId = Sentry.captureException(error);
				this.setState({ eventId });
			});
		}

		render() {
			if (this.state.hasError) {
				// render fallback UI
				return (
					<p onClick={() => Sentry.showReportDialog({ eventId: this.state.eventId })} role="presentation">
						We're sorry — something's gone wrong (click here fill out a report).
					</p>
				);
			}

			return <ComposedComponent {...this.props} />;
		}
	};
