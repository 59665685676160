import capitalize from 'ringlead_utils/helpers/capitalize';

import { ConnectionTypes } from '../constants/ConnectionConstants';
import type { ConnectionType } from '../types/connectionTypes';

export function humanConnectionType(connectionType: ConnectionType | 'outreach' | 'salesloft' | null) {
	if (!connectionType) {
		return '';
	}
	if (connectionType === ConnectionTypes.HUBSPOT) {
		return 'HubSpot';
	}
	return capitalize(connectionType);
}
