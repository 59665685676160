import { LOCATION_CHANGE } from 'app/Router';
import includes from 'lodash-es/includes';
import map from 'lodash-es/map';
import reject from 'lodash-es/reject';

import { RoutingLicensingConstants } from '../Constants';

const initialState = {
	fetching: false,
	licensedResults: [],
	autoUpdateRecord: {},
};

export default (state = initialState, action = {}) => {
	switch (action.type) {
		case LOCATION_CHANGE:
			if (
				includes(action.payload.location.pathname, 'resolution') ||
				includes(action.payload.location.pathname, 'routing/workflows')
			) {
				return state;
			}

			return initialState;

		case RoutingLicensingConstants.ROUTING_LICENSED_USER_LIST_REQUEST:
			return { ...state, fetching: true };

		case RoutingLicensingConstants.ROUTING_LICENSED_USER_LIST_SUCCESS:
			return { ...state, licensedResults: action.data, fetching: false };

		case RoutingLicensingConstants.ROUTING_LICENSED_USER_LIST_FAIL:
			return { ...state, fetching: false };

		case RoutingLicensingConstants.ROUTING_LICENSED_USER_DELETE_SUCCESS:
			return {
				...state,
				licensedResults: map(
					reject(state.licensedResults, u =>
						action.deleteAll ? u.connection === action.connectionId : u.id === action.userId
					),
					user =>
						user.backup_owner &&
						user.backup_owner.crm_user === action.crmUser &&
						user.backup_owner.crm_user_name === action.crmUserName
							? { ...user, backup_owner: {} }
							: user
				),
			};

		case RoutingLicensingConstants.ROUTING_LICENSED_USER_DELETE_FAIL:
			return state;

		case RoutingLicensingConstants.ROUTING_LICENSED_USER_UPDATE_SUCCESS:
			return {
				...state,
				licensedResults: map(state.licensedResults, u =>
					action.data.id === u.id ? { ...u, ...action.data } : u
				),
			};

		case RoutingLicensingConstants.ROUTING_LICENSED_USER_UPDATE_FAIL:
			return state;

		case RoutingLicensingConstants.ROUTING_LICENSED_GET_AUTO_UPDATE_RECORD_SUCCESS:
			return { ...state, autoUpdateRecord: action.data };

		case RoutingLicensingConstants.ROUTING_LICENSED_GET_AUTO_UPDATE_RECORD_FAIL:
			return { ...state, autoUpdateRecord: {} };

		case RoutingLicensingConstants.ROUTING_LICENSED_UPDATE_AUTO_UPDATE_RECORD_SUCCESS:
			return { ...state, autoUpdateRecord: action.data };

		case RoutingLicensingConstants.ROUTING_LICENSED_UPDATE_AUTO_UPDATE_RECORD_FAIL:
			return state;

		default:
			return state;
	}
};
