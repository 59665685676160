import { LOCATION_CHANGE } from 'app/Router';
import concat from 'lodash-es/concat';
import filter from 'lodash-es/filter';
import find from 'lodash-es/find';
import includes from 'lodash-es/includes';
import map from 'lodash-es/map';

import { CustomPackageVendorFieldsConstants } from '../Constants';

const initialState = {
	vendorApiFields: [],
	fetchingVendorApiFields: true,
	packageFields: [],
	updatingPackageFields: false,
	packageFieldsIsMapping: false,
	snowflakeFieldTypes: [],
};

export default (state = initialState, action = {}) => {
	switch (action.type) {
		case LOCATION_CHANGE:
			return initialState;

		case CustomPackageVendorFieldsConstants.VENDOR_API_FIELD_LIST:
			return { ...state, fetchingVendorApiFields: true };

		case CustomPackageVendorFieldsConstants.VENDOR_API_FIELD_LIST_SUCCESS:
			return { ...state, vendorApiFields: action.data, fetchingVendorApiFields: false };

		case CustomPackageVendorFieldsConstants.VENDOR_API_FIELD_LIST_FAIL:
			return { ...state, vendorApiFields: [], fetchingVendorApiFields: false };

		case CustomPackageVendorFieldsConstants.PACKAGE_VENDOR_API_FIELD_LIST_SUCCESS:
			return { ...state, packageFields: action.data };

		case CustomPackageVendorFieldsConstants.PACKAGE_VENDOR_API_FIELD_LIST_FAIL:
			return { ...state, packageFields: [] };

		case CustomPackageVendorFieldsConstants.PACKAGE_VENDOR_API_FIELD_CREATE_SUCCESS:
			return { ...state, packageFields: concat([], state.packageFields, action.data) };

		case CustomPackageVendorFieldsConstants.PACKAGE_VENDOR_API_FIELD_CREATE_FAIL:
			return { ...state, updatingPackageFields: false };

		case CustomPackageVendorFieldsConstants.PACKAGE_VENDOR_API_FIELD_UPDATE_SUCCESS:
			return {
				...state,
				packageFields: map(state.packageFields, packageField => {
					const packageFieldData = find(action.data, ['id', packageField.id]);
					return packageFieldData ? { ...packageField, ...packageFieldData } : packageField;
				}),
			};

		case CustomPackageVendorFieldsConstants.PACKAGE_VENDOR_API_FIELD_UPDATE_FAIL:
			return state;

		case CustomPackageVendorFieldsConstants.PACKAGE_VENDOR_API_FIELD_DELETE_SUCCESS:
			return {
				...state,
				packageFields: filter(state.packageFields, packageField => !includes(action.ids, packageField.id)),
			};

		case CustomPackageVendorFieldsConstants.PACKAGE_VENDOR_API_FIELD_DELETE_FAIL:
			return state;

		case CustomPackageVendorFieldsConstants.PACKAGE_VENDOR_API_FIELDS_MAPPING:
			return { ...state, packageFieldsIsMapping: true };

		case CustomPackageVendorFieldsConstants.PACKAGE_VENDOR_API_FIELDS_MAPPING_SUCCESS:
			return { ...state, packageFields: action.data, packageFieldsIsMapping: false };

		case CustomPackageVendorFieldsConstants.PACKAGE_VENDOR_API_FIELDS_MAPPING_FAIL:
			return { ...state, packageFieldsIsMapping: false };

		case CustomPackageVendorFieldsConstants.SNOWFLAKE_PACKAGE_VENDOR_API_FIELDS_UPDATE:
			return { ...state, updatingPackageFields: true };

		case CustomPackageVendorFieldsConstants.SNOWFLAKE_PACKAGE_VENDOR_API_FIELDS_UPDATE_SUCCESS:
			return { ...state, packageFields: action.data, updatingPackageFields: false };

		case CustomPackageVendorFieldsConstants.SNOWFLAKE_FIELD_TYPES_LIST_SUCCESS:
			return { ...state, snowflakeFieldTypes: action.data };

		case CustomPackageVendorFieldsConstants.SNOWFLAKE_FIELD_TYPES_LIST_FAIL:
			return { ...state, snowflakeFieldTypes: [] };

		default:
			return state;
	}
};
