import { ICON_TYPES } from '../Constants';

import IconWithMenu from './IconWithMenu';

const MENU_ITEMS = [
	{
		label: 'Replace Dictionaries',
		href: '/replace-values/',
	},
	{
		label: 'Scheduling',
		href: '/scheduling/',
	},
	{
		label: 'System Dictionaries',
		href: '/system-dictionaries/',
	},
	{
		label: 'Templates',
		href: '/templates/',
	},
];

function UserSettingsMenu() {
	return <IconWithMenu iconTitle="Settings" iconType={ICON_TYPES.SETTINGS} items={MENU_ITEMS} />;
}

export default UserSettingsMenu;
