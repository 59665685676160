import { useSelector } from 'react-redux';
import { Route, Routes } from 'react-router';
import ForbiddenPage from 'ringlead_utils/components/ForbiddenPage';
import NotFoundPage from 'ringlead_utils/components/NotFoundPage';
import RingLeadLoadable from 'ringlead_utils/components/RingLeadLoadable/RingLeadLoadable';

import useHasFeatureAccess from '../../admin/hooks/useHasFeatureAccess';

const Packages = RingLeadLoadable({
	loader: () => import('../../vendors/components/package_management/list/Packages'),
});

const PackageDetail = RingLeadLoadable({
	loader: () => import('../../vendors/components/package_management/detail/PackageDetail'),
});

function PackageManagementRoutes() {
	const isAdmin = useSelector(state => state.auth.user.organization.is_beta || state.auth.user?.is_admin);
	const hasPermissions = useHasFeatureAccess(['DATA_EXCHANGE'], 'read:package', null);

	if (!isAdmin || !hasPermissions) {
		return <ForbiddenPage features={['DATA_EXCHANGE']} permissions={['read:package']} />;
	}

	return (
		<Routes>
			<Route index element={<Packages />} />
			<Route path=":packageId/*" element={<PackageDetail />} />
			<Route path="*" element={<NotFoundPage />} />
		</Routes>
	);
}

export default PackageManagementRoutes;
