import { Route, Routes } from 'react-router';
import NotFoundPage from 'ringlead_utils/components/NotFoundPage';
import RingLeadLoadable from 'ringlead_utils/components/RingLeadLoadable/RingLeadLoadable';

import useHasFeatureAccess from '../../admin/hooks/useHasFeatureAccess';
import ForbiddenPage from '../components/ForbiddenPage';

const RoundRobinList = RingLeadLoadable({
	loader: () => import('../../routing/components/roundrobin/RoundRobinList'),
});

const RoundRobinDetails = RingLeadLoadable({
	loader: () => import('../../routing/components/roundrobin/RoundRobinDetails'),
});

function RoundRobinRoutes() {
	const hasPermissions = useHasFeatureAccess(null, 'read:round_robin');

	if (!hasPermissions) {
		return <ForbiddenPage permissions={['read:round_robin']} />;
	}

	return (
		<Routes>
			<Route index element={<RoundRobinList />} />
			<Route path=":dtId" element={<RoundRobinDetails />} />
			<Route path="*" element={<NotFoundPage />} />
		</Routes>
	);
}

export default RoundRobinRoutes;
