import { Route, Routes } from 'react-router';
import ForbiddenPage from 'ringlead_utils/components/ForbiddenPage';
import NotFoundPage from 'ringlead_utils/components/NotFoundPage';
import RingLeadLoadable from 'ringlead_utils/components/RingLeadLoadable/RingLeadLoadable';

import useHasFeatureAccess from '../../admin/hooks/useHasFeatureAccess';

import DiscoverySearchRoutes from './DiscoverySearchRoutes';

const DiscoveryIELists = RingLeadLoadable({
	loader: () => import('../../dop/components/tasks/discovery/inclusion_exclusion/list/IELists'),
});

const DiscoveryIEListDetails = RingLeadLoadable({
	loader: () => import('../../dop/components/tasks/discovery/inclusion_exclusion/detail/InclusionExclusion'),
});

function DiscoveryRoutes() {
	const hasPermissions = useHasFeatureAccess(['DISCOVERY'], null);

	if (!hasPermissions) {
		return <ForbiddenPage features={['DISCOVERY']} />;
	}

	return (
		<Routes>
			<Route path="searches/*" element={<DiscoverySearchRoutes />} />
			<Route path="ie-lists">
				<Route index element={<DiscoveryIELists />} />
				<Route path=":listId" element={<DiscoveryIEListDetails />} />
				<Route path="*" element={<NotFoundPage />} />
			</Route>
			<Route path="*" element={<NotFoundPage />} />
		</Routes>
	);
}

export default DiscoveryRoutes;
