import List from '@mui/material/List';

import { type MenuItem, type MenuItems } from '../Helper';

import NavItem from './NavItem';

interface NavItemsProps {
	links: (MenuItem | MenuItems)[];
	depth: number;
	hash: string;
	activeBorder?: boolean;
}

function NavItems({ links, depth, hash, activeBorder }: NavItemsProps) {
	return (
		<List dense disablePadding sx={depth === 0 ? { overflowX: 'hidden', overflowY: 'auto' } : {}}>
			{links.map(link => {
				const subHash = (hash + link.label)
					.split('')
					.reduce((prevHash, currVal) => ((prevHash << 5) - prevHash + currVal.charCodeAt(0)) | 0, 0)
					.toString();

				return (
					<NavItem key={subHash} link={link} depth={depth} hash={subHash} activeBorder={activeBorder}>
						{'items' in link && link.items?.length ? (
							<NavItems hash={subHash} links={link.items} depth={depth + 1} />
						) : undefined}
					</NavItem>
				);
			})}
		</List>
	);
}

export default NavItems;
