import { LOCATION_CHANGE } from 'app/Router';
import concat from 'lodash-es/concat';
import filter from 'lodash-es/filter';
import find from 'lodash-es/find';
import includes from 'lodash-es/includes';
import map from 'lodash-es/map';
import pickBy from 'lodash-es/pickBy';
import reduce from 'lodash-es/reduce';

import { TaskFiltersConstants } from '../Constants';

const initialState = {
	filters: [],
	fetching: true,
	errors: {},
};

export default (state = initialState, action = {}) => {
	switch (action.type) {
		case LOCATION_CHANGE:
			return initialState;

		case TaskFiltersConstants.LIST_FILTER_SUCCESS:
			return { ...state, filters: action.data, fetching: false };

		case TaskFiltersConstants.LIST_FILTER_FAIL:
			return { ...state, filters: [], fetching: false };

		case TaskFiltersConstants.SYNC_FILTER_SUCCESS:
			return {
				...state,
				filters: [...action.data, ...action.invalidFilters],
				errors: reduce(
					action.invalidFilters,
					(result, recordFilter, index) => ({ ...result, [recordFilter.tmpId]: action.errors[index] || {} }),
					{}
				),
			};

		case TaskFiltersConstants.CREATE_FILTER_SUCCESS:
			return {
				...state,
				filters: find(state.filters, ['tmpId', action.filter.tmpId])
					? map(state.filters, recordFilter =>
							recordFilter.tmpId === action.filter.tmpId
								? { ...recordFilter, ...action.data }
								: recordFilter
						)
					: concat([], state.filters, action.data),
				errors: pickBy(state.errors, (_v, k) => k !== action.filter.tmpId),
			};

		case TaskFiltersConstants.CREATE_FILTER_FAIL:
			return {
				...state,
				filters: includes(map(state.filters, 'tmpId'), action.filter.tmpId)
					? map(state.filters, f => (f.tmpId === action.filter.tmpId ? { ...f, ...action.filter } : f))
					: concat([], state.filters, action.filter),
				errors: { ...state.errors, [action.filter.tmpId]: action.data },
			};

		case TaskFiltersConstants.UPDATE_FILTER_SUCCESS:
			return {
				...state,
				filters: map(state.filters, ff => (action.data.id === ff.id ? { ...ff, ...action.data } : ff)),
				errors: pickBy(state.errors, (_v, k) => k !== action.filter.id.toString()),
			};

		case TaskFiltersConstants.UPDATE_FILTER_FAIL:
			return {
				...state,
				filters: map(state.filters, ff => (action.filter.id === ff.id ? { ...ff, ...action.filter } : ff)),
				errors: { ...state.errors, [action.filter.id]: action.data },
			};

		case TaskFiltersConstants.REORDER_FILTERS:
			return {
				...state,
				filters: map(state.filters, ff => {
					const actionFilter = find(action.filters, f => (ff.id || ff.tmpId) === (f.id || f.tmpId));
					return actionFilter ? { ...ff, order: actionFilter.order } : ff;
				}),
			};

		case TaskFiltersConstants.DELETE_FILTER_SUCCESS:
			return {
				...state,
				filters: filter(state.filters, f => f.id !== action.pk && f.tmpId !== action.pk),
				errors: pickBy(state.errors, (_v, k) => k !== action.pk.toString()),
			};

		case TaskFiltersConstants.DELETE_FILTER_FAIL:
			return state;

		default:
			return state;
	}
};
