import { createApi } from '@reduxjs/toolkit/query/react';
import baseQueryWithNProgress from 'app/utils/services/baseQueryWithNProgress';

import type {
	DuplicateHealthCheck,
	DuplicateHealthCheckSelectedTask,
	DuplicateHealthCheckTask,
	GenericStatistic,
	OrgTask,
} from '../types/dashboardTypes';

export const dashboardApi = createApi({
	reducerPath: 'dashboardApi',
	baseQuery: baseQueryWithNProgress,
	tagTypes: ['DuplicateHealthCheck', 'DuplicateHealthCheckTasks'],
	endpoints: builder => ({
		getOrgTasks: builder.query<Record<string, OrgTask[]>, void>({
			query: () => '/stats/org_tasks/',
		}),
		getGenericStatistic: builder.query<GenericStatistic, void>({
			query: () => '/stats/generic/',
		}),
		getDuplicateHealthCheck: builder.query<DuplicateHealthCheck[], void>({
			query: () => '/stats/duplicate_health_check/',
			providesTags: ['DuplicateHealthCheck'],
		}),
		getDuplicateHealthCheckTasks: builder.query<DuplicateHealthCheckTask[], void>({
			query: () => '/stats/duplicate_health_check_tasks/',
			providesTags: ['DuplicateHealthCheckTasks'],
		}),
		getDuplicateHealthCheckSelectedTasks: builder.query<DuplicateHealthCheckSelectedTask[], void>({
			query: () => '/stats/duplicate_health_check_selected_tasks/',
		}),
		editDuplicateHealthCheckSelectedTasks: builder.mutation<
			DuplicateHealthCheckSelectedTask[],
			DuplicateHealthCheckSelectedTask[]
		>({
			query: body => ({
				url: '/stats/duplicate_health_check_selected_tasks/',
				method: 'PUT',
				body,
			}),
			onQueryStarted(body, { dispatch, queryFulfilled }) {
				const patchResult = dispatch(
					dashboardApi.util.updateQueryData('getDuplicateHealthCheckSelectedTasks', undefined, () => body)
				);
				queryFulfilled.catch(patchResult.undo);
			},
			invalidatesTags: ['DuplicateHealthCheck', 'DuplicateHealthCheckTasks'],
		}),
	}),
});

export const {
	useGetOrgTasksQuery,
	useGetGenericStatisticQuery,
	useGetDuplicateHealthCheckQuery,
	useGetDuplicateHealthCheckTasksQuery,
	useGetDuplicateHealthCheckSelectedTasksQuery,
	useEditDuplicateHealthCheckSelectedTasksMutation,
} = dashboardApi;
