import { LOCATION_CHANGE } from 'app/Router';
import concat from 'lodash-es/concat';
import filter from 'lodash-es/filter';
import find from 'lodash-es/find';
import includes from 'lodash-es/includes';
import map from 'lodash-es/map';
import pickBy from 'lodash-es/pickBy';
import reduce from 'lodash-es/reduce';

import { SurvivingFieldValueRulesConstants } from '../Constants';

const initialState = {
	survivingFieldValueRules: [],
	fetching: true,
	errors: {},
};

export default (state = initialState, action = {}) => {
	switch (action.type) {
		case LOCATION_CHANGE:
			return initialState;

		case SurvivingFieldValueRulesConstants.LOAD_SURVIVING_FIELD_VALUE_RULES_SUCCESS:
			return { ...state, survivingFieldValueRules: action.data, fetching: false };

		case SurvivingFieldValueRulesConstants.LOAD_SURVIVING_FIELD_VALUE_RULES_FAIL:
			return { ...state, survivingFieldValueRules: [], fetching: false };

		case SurvivingFieldValueRulesConstants.SYNC_SURVIVING_FIELD_VALUE_RULES_SUCCESS:
			return {
				...state,
				survivingFieldValueRules: [...action.data, ...action.invalidRules],
				errors: reduce(
					action.invalidRules,
					(result, rule, index) => ({ ...result, [rule.tmpId]: action.errors[index] || {} }),
					{}
				),
			};

		case SurvivingFieldValueRulesConstants.CREATE_SURVIVING_FIELD_VALUE_RULE_SUCCESS:
			return {
				...state,
				survivingFieldValueRules: find(state.survivingFieldValueRules, ['tmpId', action.rule.tmpId])
					? map(state.survivingFieldValueRules, survivingFieldValueRule =>
							survivingFieldValueRule.tmpId === action.rule.tmpId
								? { ...survivingFieldValueRule, ...action.data }
								: survivingFieldValueRule
						)
					: concat([], state.survivingFieldValueRules, action.data),
				errors: pickBy(state.errors, (_v, k) => k !== action.rule.tmpId),
			};

		case SurvivingFieldValueRulesConstants.CREATE_SURVIVING_FIELD_VALUE_RULE_FAIL:
			return {
				...state,
				survivingFieldValueRules: includes(map(state.survivingFieldValueRules, 'tmpId'), action.rule.tmpId)
					? map(state.survivingFieldValueRules, survivingFieldValueRule =>
							survivingFieldValueRule.tmpId === action.rule.tmpId
								? { ...survivingFieldValueRule, ...action.rule }
								: survivingFieldValueRule
						)
					: concat([], state.survivingFieldValueRules, action.rule),
				errors: { ...state.errors, [action.rule.tmpId]: action.data },
			};

		case SurvivingFieldValueRulesConstants.PATCH_SURVIVING_FIELD_VALUE_RULE_SUCCESS:
			return {
				...state,
				survivingFieldValueRules: map(state.survivingFieldValueRules, survivingFieldValueRule =>
					action.data.id === survivingFieldValueRule.id
						? { ...survivingFieldValueRule, ...action.data }
						: survivingFieldValueRule
				),
				errors: pickBy(state.errors, (_v, k) => k !== action.rule.id.toString()),
			};

		case SurvivingFieldValueRulesConstants.PATCH_SURVIVING_FIELD_VALUE_RULE_FAIL:
			return {
				...state,
				survivingFieldValueRules: map(state.survivingFieldValueRules, survivingFieldValueRule =>
					action.rule.id === survivingFieldValueRule.id
						? { ...survivingFieldValueRule, ...action.rule }
						: survivingFieldValueRule
				),
				errors: { ...state.errors, [action.rule.id]: action.data },
			};

		case SurvivingFieldValueRulesConstants.REORDER_SURVIVING_FIELD_VALUE_RULES:
			return {
				...state,
				survivingFieldValueRules: map(state.survivingFieldValueRules, survivingFieldValueRule => {
					const actionRule = find(
						action.rules,
						r => (survivingFieldValueRule.id || survivingFieldValueRule.tmpId) === (r.id || r.tmpId)
					);
					return actionRule
						? { ...survivingFieldValueRule, priority: actionRule.priority }
						: survivingFieldValueRule;
				}),
			};

		case SurvivingFieldValueRulesConstants.DELETE_SURVIVING_FIELD_VALUE_RULE_SUCCESS:
			return {
				...state,
				survivingFieldValueRules: filter(
					state.survivingFieldValueRules,
					survivingFieldValueRule =>
						survivingFieldValueRule.id !== action.id && survivingFieldValueRule.tmpId !== action.id
				),
				errors: pickBy(state.errors, (_v, k) => action.id.toString() !== k),
			};

		case SurvivingFieldValueRulesConstants.DELETE_SURVIVING_FIELD_VALUE_RULE_FAIL:
			return state;

		default:
			return state;
	}
};
