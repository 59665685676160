import { useSelector } from 'react-redux';
import { Route, Routes } from 'react-router';
import useHasFeatureAccess from 'app/admin/hooks/useHasFeatureAccess';
import type { RootState } from 'app/utils/stores/store-tmp-type';
import ForbiddenPage from 'ringlead_utils/components/ForbiddenPage';
import NotFoundPage from 'ringlead_utils/components/NotFoundPage';
import RingLeadLoadable from 'ringlead_utils/components/RingLeadLoadable/RingLeadLoadable';

const UserManagement = RingLeadLoadable({
	loader: () => import('../../admin/components/users/UserManagement'),
});

const GroupsPage = RingLeadLoadable({
	loader: () => import('../../admin/components/groups/GroupsPage'),
});

const RolesPage = RingLeadLoadable({
	loader: () => import('../../admin/components/roles/RolesPage'),
});

const RolePage = RingLeadLoadable({
	loader: () => import('../../admin/components/roles/RolePage'),
});

function UserRoutes() {
	const isBeta = useSelector((state: RootState) => state.auth.user.organization.is_beta);
	const isAdmin = useSelector(
		(state: RootState) => state.auth.user.organization.is_beta || state.auth.user?.is_admin
	);
	const hasPermissions = useHasFeatureAccess(null, 'read:user', null);
	const hasInvitationPermissions = useHasFeatureAccess(null, 'read:invitation', null);
	const hasOrganizationPermissions = useHasFeatureAccess(null, 'read:organization', null);
	const hasRolePermissions = useHasFeatureAccess(null, 'read:role', null);
	const hasUserGroupPermissions = useHasFeatureAccess(null, 'read:user_group', null);

	if (
		!isAdmin ||
		(!hasPermissions &&
			!hasInvitationPermissions &&
			!hasOrganizationPermissions &&
			!hasRolePermissions &&
			!hasUserGroupPermissions)
	) {
		return (
			<ForbiddenPage
				permissions={['read:user', 'read:invitation', 'read:organization', 'read:role', 'read:user_group']}
			/>
		);
	}

	return (
		<Routes>
			<Route index element={<UserManagement />} />
			{isBeta && (
				<>
					<Route path="roles">
						<Route
							index
							element={hasRolePermissions ? <RolesPage /> : <ForbiddenPage permissions={['read:role']} />}
						/>
						<Route
							path=":roleId"
							element={hasRolePermissions ? <RolePage /> : <ForbiddenPage permissions={['read:role']} />}
						/>
					</Route>
					<Route
						path="groups"
						element={
							hasUserGroupPermissions ? (
								<GroupsPage />
							) : (
								<ForbiddenPage permissions={['read:user_group']} />
							)
						}
					/>
				</>
			)}
			<Route path="*" element={<NotFoundPage />} />
		</Routes>
	);
}

export default UserRoutes;
