import { Fragment } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Typography from '@mui/material/Typography';

import { NotificationTypes } from '../NotificationConstants';
import type { Notification } from '../types/notification';

interface NotificationModalProps {
	notifications: Notification[];
	openType: 'auto' | 'manual';
	onClose: () => void;
}

function NotificationModal({ notifications, openType, onClose }: NotificationModalProps) {
	return (
		<Dialog open={Boolean(notifications.length)} onClose={onClose} fullWidth maxWidth="lg">
			<DialogContent sx={{ p: 1 }}>
				<List disablePadding>
					{notifications.map((notification, index) => (
						<Fragment key={notification.id}>
							<ListItem>
								<Typography
									variant="h6"
									color={notification.type === NotificationTypes.TASK_ERROR ? 'error' : 'textPrimary'}
									dangerouslySetInnerHTML={{ __html: notification.title }}
								/>
							</ListItem>
							<ListItem divider={index !== notifications.length - 1}>
								<div
									style={{ width: '100%' }}
									// eslint-disable-next-line react/no-danger
									dangerouslySetInnerHTML={{ __html: notification.body }}
								/>
							</ListItem>
						</Fragment>
					))}
				</List>
			</DialogContent>
			<DialogActions>
				{openType === 'auto' && (
					<Button onClick={onClose} color="primary">
						Skip
					</Button>
				)}
			</DialogActions>
		</Dialog>
	);
}

export default NotificationModal;
