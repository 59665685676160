import { createApi } from '@reduxjs/toolkit/query/react';
import baseQueryWithNProgress from 'app/utils/services/baseQueryWithNProgress';

import type { AdminUser, OwnerOption, SimpleUser, User } from '../types/user';

export const userApi = createApi({
	reducerPath: 'userApi',
	baseQuery: baseQueryWithNProgress,
	endpoints: builder => ({
		getUsers: builder.query<User[], void>({
			query: () => '/account/users/',
		}),
		getSimpleUsers: builder.query<SimpleUser[], void>({
			query: () => '/account/users/simple/',
		}),
		getAdminUsers: builder.query<AdminUser[], void>({
			query: () => '/account/admins/',
		}),
		getOwnerOptions: builder.query<OwnerOption[], void>({
			query: () => '/account/owner_options/',
		}),
		editUsers: builder.mutation<User, (Partial<User> & { id: number })[]>({
			query: body => ({
				url: '/account/users/',
				method: 'PATCH',
				body,
			}),
			onQueryStarted(data, { dispatch, queryFulfilled }) {
				const patchResult = dispatch(
					userApi.util.updateQueryData('getUsers', undefined, draft => {
						data.forEach(user => {
							const updatedUser = draft.find(u => u.id === user.id);
							if (updatedUser) {
								Object.assign(updatedUser, user);
							}
						});
					})
				);
				queryFulfilled.catch(patchResult.undo);
			},
		}),
		deleteUser: builder.mutation<unknown, number>({
			query: id => ({
				url: `/account/users/${id}/`,
				method: 'DELETE',
			}),
			onQueryStarted(id, { dispatch }) {
				dispatch(
					userApi.util.updateQueryData('getUsers', undefined, draft => {
						draft.forEach(user => {
							if (user.id === id) {
								Object.assign(user, { is_active: false });
							}
						});
					})
				);
			},
		}),
	}),
});

export const {
	useGetUsersQuery,
	useGetSimpleUsersQuery,
	useGetAdminUsersQuery,
	useGetOwnerOptionsQuery,
	useEditUsersMutation,
	useDeleteUserMutation,
} = userApi;
