import { type ComponentType } from 'react';
import AssignmentTurnedInOutlinedIcon from '@mui/icons-material/AssignmentTurnedInOutlined';
import CloudIcon from '@mui/icons-material/CloudOutlined';
import DirectionsOutlinedIcon from '@mui/icons-material/DirectionsOutlined';
import GrainOutlinedIcon from '@mui/icons-material/GrainOutlined';
import LandscapeOutlinedIcon from '@mui/icons-material/LandscapeOutlined';
import OpacityOutlinedIcon from '@mui/icons-material/OpacityOutlined';
import PhotoLibraryOutlinedIcon from '@mui/icons-material/PhotoLibraryOutlined';
import PollOutlinedIcon from '@mui/icons-material/PollOutlined';
import SpaOutlinedIcon from '@mui/icons-material/SpaOutlined';
import { type SvgIconProps } from '@mui/material/SvgIcon';
import { humanConnectionType } from 'app/settings/helpers/ConnectionHelper';
import { type LDFlagSet } from 'launchdarkly-js-client-sdk';
import {
	HELP_CENTER_DISCOVER_URL,
	HELP_CENTER_ROUTE_URL,
	HELP_CENTER_SEGMENT_URL,
} from 'ringlead_utils/HelpCenterConstants';

import { hasFeatureAccess } from '../admin/helpers/AccessHelper';
import type { User } from '../auth/types/user';
import { ConnectionTypes } from '../settings/constants/ConnectionConstants';
import { filterConnectionList } from '../settings/Helper';

import { PAGE_ACTIVITY_REPORTS, PAGE_NORMALIZE, PAGE_SCORE, PAGE_SEGMENT } from './Constants';

export interface MenuItem {
	label: string;
	tool: string;
	href?: string;
	help?: string;
	help_page_text?: string;
	help_center_url?: string;
	external?: boolean;
	icon?: undefined;
}

export interface MenuItems extends Omit<MenuItem, 'icon'> {
	icon: ComponentType<SvgIconProps>;
	items?: MenuItem[];
}

function getMyTaskMenu(): MenuItems {
	return {
		label: 'Tasks',
		tool: 'Tasks',
		href: '/',
		icon: AssignmentTurnedInOutlinedIcon,
	};
}

function getAnalyzeMenu(): MenuItems | null {
	return {
		label: 'Analyze',
		tool: 'Analyze',
		href: '/analyze/',
		help_page_text:
			'Gain deep insight into the quality of your data, ' +
			'RingLead processing and its contribution to enhancing your data quality and related outcomes.',
		help_center_url: '',
		icon: PollOutlinedIcon,
		items: [
			{
				label: 'Performance Dashboard',
				tool: 'Analyze - Performance Dashboard',
				href: '/performance-dashboard/',
				help_page_text:
					'Quickly see top level RingLead performance metrics for Cleanse, Prevent and Enrich ' +
					'over the past 12 months.',
				help_center_url: 'https://help.zoominfo.com/s/article/Overview-of-the-RingLead-Performance-Dashboard',
			},
			{
				label: 'Activity Log',
				tool: 'Analyze - Activity Log',
				href: '/activity_logs/',
				help_page_text:
					'A centralized and filterable log of all RingLead activities including merge, enrich, normalize, ' +
					'segment, route and more.',
				help_center_url: 'https://help.zoominfo.com/s/article/Overview-of-RingLead-Activity-Logs',
			},
			PAGE_ACTIVITY_REPORTS,
			{
				label: 'Data Quality Report Card',
				tool: 'Analyze - Data Quality Report Card',
				href: '/report-card/',
				help_page_text:
					'Provides insight into the current state of your data quality including duplicates and field usage.',
				help_center_url: 'https://help.zoominfo.com/s/article/Overview-of-Data-Quality-Report-Card',
			},
		],
	};
}

function getCleanseMenu(): MenuItems | null {
	return {
		label: 'Cleanse',
		tool: 'Cleanse',
		icon: OpacityOutlinedIcon,
		href: '/cleanse/',
		items: [
			{
				label: 'Deduplicate',
				tool: 'Cleanse - Deduplicate',
				href: '/deduplication/',
				help: 'Easy find and merge duplicate records in Salesforce, Marketo or Eloqua',
				help_page_text:
					'Scan your Salesforce, Marketo or Eloqua for duplicates based on specific criteria that you define. ' +
					'Once you have identified duplicates RingLead makes it easy to merge them saving ' +
					'you storage costs and time.',
				help_center_url: 'https://help.zoominfo.com/s/article/How-to-Create-a-Deduplication-Task',
			},
			{
				label: 'Normalize/Segment',
				tool: 'Cleanse - Normalize/Segment',
				href: '/normalization/',
				help: 'Easy find and normalize records in Salesforce, Marketo, Eloqua or Pardot',
				help_page_text:
					'Standardize your addresses, websites, phone numbers and more to keep data easy to navigate, ' +
					'report and search on.',
			},
			{
				label: 'Mass Update',
				tool: 'Cleanse - Mass Update',
				href: '/mass-update/',
				help: 'Easy find and update records in Salesforce, Marketo, Eloqua or Pardot',
				help_page_text:
					'Update fields on your records on both custom and standard objects by moving data ' +
					'from another field, defining a formula or simply typing in a value.',
				help_center_url: 'https://help.zoominfo.com/s/article/How-to-Create-and-Run-a-Mass-Update-Task',
			},
			{
				label: 'Mass Delete',
				tool: 'Cleanse - Mass Delete',
				href: '/mass-delete/',
				help: 'Easy find and delete records in Salesforce, Marketo, Eloqua or Pardot',
				help_page_text:
					'Clean up your Salesforce, Marketo, Eloqua or Pardot by deleting records on both custom and standard objects.',
			},
		],
	};
}

function getPreventionMenu(): MenuItems | null {
	return {
		label: 'Prevent',
		tool: 'Prevent',
		icon: PhotoLibraryOutlinedIcon,
		href: '/prevent/',
		items: [
			{
				label: 'List Import',
				tool: 'Prevent - List Import',
				href: '/list-import/',
				help: 'Easy upload a list to Salesforce or Eloqua',
				help_page_text: 'Prevent duplicates, enrich, normalize, and segment with file uploads',
				help_center_url: 'https://help.zoominfo.com/s/article/Overview-of-RingLead-List-Import',
			},
			{
				label: 'Web Submission',
				tool: 'Prevent - Web Submission',
				href: '/web-submission/',
				help:
					'Process leads submitted via web forms and send to Salesforce, directly created ' +
					'in Salesforce through AppExchange, Third Party API, etc. ' +
					'Process new leads created in Marketo using the webhook. ' +
					'Process prospects submitted via web forms and send to Pardot. ' +
					'Process contacts submitted via web forms and send to Eloqua',
				help_page_text:
					'Enrich, normalize, segment, detect and resolve duplicates, match / link new Leads to Accounts and ' +
					'route new and updated records in CRM and Marketing Automation Platforms in real time.',
				help_center_url: 'https://help.zoominfo.com/s/article/Overview-of-RingLead-Web-Submission',
			},
		],
	};
}

function getEnrichmentMenu(user: User, flags: LDFlagSet): MenuItems | null {
	const { showLegacyProduct, hasEnrichApi } = flags;

	const items: MenuItem[] = [
		{
			label: 'Mass Enrich',
			tool: 'Enrich - Mass Enrich',
			href: '/enrichment/',
			help: 'Easy find and enrich records in Salesforce, Marketo, Pardot or Eloqua',
			help_page_text: 'Enhance the data on all or a segment of existing records.',
		},
	];

	if (hasEnrichApi) {
		items.push({
			label: 'Enrich API',
			tool: 'Enrich - Enrich API',
			href: '/webapi/',
			help: 'Enable Enrich in your own application',
			help_page_text:
				'Configure dedicated enrichment, normalization and segmentation rules that you can call ' +
				'from any external application.',
		});
	}

	if (hasFeatureAccess(user, ['ENRICHMENT_API'], null) && showLegacyProduct) {
		items.push({
			label: 'Unique Entry Enrich',
			tool: 'Enrich - Unique Entry Enrich',
			href: '/unique-entry/',
			help: 'Configure API Endpoint for Enrich in Unique Entry',
			help_page_text: 'Setup your Unique Entry enrichment tasks to be used within your Salesforce organization.',
			help_center_url: 'https://help.zoominfo.com/s/article/Salesforce-Unique-Entry-Explained',
		});
	}

	if (user.is_admin || user.organization?.is_beta) {
		items.push({
			label: 'Vendor Key Manage',
			tool: 'Enrich - Vendor Key Manage',
			href: '/vendor-manage/vendor-keys/',
			help_page_text:
				'Manage all of your vendor authentications in one spot to help enable them in Enrich services and ' +
				'create your own packages.',
			help_center_url: 'https://help.zoominfo.com/lightning/articles/help/Vendor-Key-Management-Explained',
		});
		items.push({
			label: 'Package Manage',
			tool: 'Enrich - Package Manage',
			href: '/package-management/',
			help_page_text:
				'Blended recipes and custom waterfalls to help action all of your data vendors in one spot. ' +
				'Templates available and advanced settings such as custom Array and Object logic to make the most of your data.',
			help_center_url: 'https://help.zoominfo.com/lightning/articles/help/Package-Manage-Explained',
		});
	}

	if (hasFeatureAccess(user, ['INTELLIGENT_FORMS'], null) && showLegacyProduct) {
		items.push({
			label: 'Intelligent Forms',
			tool: 'Enrich - Intelligent Forms',
			href: '/intelligent_forms/',
			help_page_text:
				'Enhance your forms with a pop-up suggestion of standard company names and locations, ' +
				'which ensures better matching to duplicate records or Accounts for ABM and ' +
				'eliminates the need for the visitor to enter address information.',
		});
	}

	if (showLegacyProduct) {
		items.push({
			label: 'DataExchange',
			tool: 'Enrich - DataExchange',
			href: 'https://dataexchange.ringlead.com',
			external: true,
			help_page_text:
				'DataExchange is a website that helps you select data vendors that integrate with RingLead and ' +
				'best meet your business data needs.',
			help_center_url: 'https://dataexchange.ringlead.com',
		});
	}

	return {
		label: 'Enrich',
		tool: 'Enrich',
		icon: SpaOutlinedIcon,
		href: '/enrich/',
		items,
	};
}

function getDiscoverMenu(user: User): MenuItems | null {
	if (!hasFeatureAccess(user, ['DISCOVERY'], null)) {
		return null;
	}

	const items: MenuItem[] = [
		{
			label: 'Searches',
			tool: 'Discover - Searches',
			href: '/discovery/searches/',
			help: 'Find new Contacts or Companies and include it in your Salesforce',
			help_page_text: '',
			help_center_url: '',
		},
	];

	items.push({
		label: 'Inclusion/Exclusion Lists',
		tool: 'Discover - Inclusion/Exclusion Lists',
		href: '/discovery/ie-lists/',
		help_page_text: '',
		help_center_url: '',
	});

	return {
		label: 'Discover',
		tool: 'Discover',
		icon: LandscapeOutlinedIcon,
		href: '/discover/',
		help_page_text: '',
		help_center_url: HELP_CENTER_DISCOVER_URL,
		items,
	};
}

function getRouteMenu(): MenuItems | null {
	return {
		label: 'Route',
		tool: 'Route',
		icon: DirectionsOutlinedIcon,
		href: '/route/',
		help_page_text:
			'Create custom rules for RingLead Prevent tasks to assign new or updated Leads, Contacts Accounts or ' +
			'other objects to the desired owner.',
		help_center_url: HELP_CENTER_ROUTE_URL,
		items: [
			{
				label: 'Licensed Users',
				tool: 'Route - Licensed Users',
				href: '/routing/licensing/',
				help_page_text: 'Select active Salesforce Users that will be assigned as owners.',
				help_center_url: HELP_CENTER_ROUTE_URL,
			},
			{
				label: 'Workflows',
				tool: 'Route - Workflows',
				href: '/routing/workflows/',
				help_page_text:
					'Define how a record should flow through Route in order to assign to the correct user based ' +
					'on your defined business process.',
				help_center_url: HELP_CENTER_ROUTE_URL,
			},
			{
				label: 'Round Robin',
				tool: 'Route - Round Robin',
				href: '/routing/round-robin/',
				help_page_text: 'Identify groups of Users that will be sequentially assigned as owners.',
				help_center_url: HELP_CENTER_ROUTE_URL,
			},
			{
				label: 'Top Off Queue',
				tool: 'Route - Top Off Queue',
				href: '/routing/top-off/',
				help_page_text: 'Identify groups of Users that will be sequentially assigned as owners.',
				help_center_url: 'https://help.zoominfo.com/s/article/Overview-of-Top-Off-Queue-and-Record-Limits',
			},
			{
				label: 'Working Hours',
				tool: 'Route - Working Hours',
				href: '/routing/working-hours/',
				help_page_text:
					'Skip Users in round robin groups and/or specify a “Backup Owner” for times outside ' +
					'of a user’s designated working hours.',
				help_center_url: HELP_CENTER_ROUTE_URL,
			},
			{
				label: 'Teams',
				tool: 'Route - Teams',
				href: '/routing/team/',
				help_page_text:
					'Create Account or Opportunity teams to add dynamically in case of Account Match or Opportunity Creation',
				help_center_url: HELP_CENTER_ROUTE_URL,
			},
			{
				label: 'Time Off',
				tool: 'Route - Time Off',
				href: '/routing/time-off/',
				help_page_text:
					'Skip Users in round robin groups and specify a “Backup Owner” for Users that are taking time off',
				help_center_url: HELP_CENTER_ROUTE_URL,
			},
			{
				label: 'Route Logs',
				tool: 'Route - Route Logs',
				href: '/routing/logs/',
				help_page_text: 'Review records that have gone through your Route logic by owner or workflow rules.',
				help_center_url: HELP_CENTER_ROUTE_URL,
			},
		],
	};
}

function getSegmentationMenu(): MenuItems | null {
	return {
		label: 'Transform',
		tool: 'Transform',
		icon: GrainOutlinedIcon,
		href: '/transform/',
		help_page_text:
			'Create custom rules for RingLead Cleanse, Prevent and Enrich tasks ' +
			'to transform data into new values, such as the following: ' +
			'<ul>' +
			'<li>SIC and/or NAIC -> Industry</li>' +
			'<li>Country, State and/or Postal Code -> Region</li>' +
			'<li>Job Title, Title Level, Department  and/or Job Function -> Persona</li>' +
			'<li>Non-standard Country field values (e.g. USA) -> standard Country values (e.g. United States)</li>' +
			'</ul>',
		help_center_url: HELP_CENTER_SEGMENT_URL,
		items: [PAGE_SEGMENT, PAGE_SCORE, PAGE_NORMALIZE],
	};
}

function getConnectionMenu(user: User): MenuItems | null {
	const ConnectionsItem: MenuItem[] = filterConnectionList(
		[
			ConnectionTypes.SALESFORCE,
			ConnectionTypes.MARKETO,
			ConnectionTypes.PARDOT,
			ConnectionTypes.ELOQUA,
			ConnectionTypes.HUBSPOT,
			ConnectionTypes.DYNAMICS,
			ConnectionTypes.SNOWFLAKE,
		],
		user
	).map(connectionType => ({
		label: humanConnectionType(connectionType),
		tool: `Connections - ${humanConnectionType(connectionType)}`,
		href: `/connections/${connectionType}/`,
	}));

	return ConnectionsItem.length > 0
		? {
				label: 'Connections',
				tool: 'Connections',
				icon: CloudIcon,
				items: ConnectionsItem,
			}
		: null;
}

export function getNavigationMenuLinks(user: User, flags: LDFlagSet): MenuItems[] {
	return [
		getMyTaskMenu(),
		getAnalyzeMenu(),
		getCleanseMenu(),
		getPreventionMenu(),
		getSegmentationMenu(),
		getEnrichmentMenu(user, flags),
		getDiscoverMenu(user),
		getRouteMenu(),
		getConnectionMenu(user),
	]
		.filter(i => !!i)
		.map(i => i);
}

export function getMenuByType(menuType: string, user: User, flags: LDFlagSet) {
	switch (menuType) {
		case 'analyze':
			return getAnalyzeMenu();
		case 'prevent':
			return getPreventionMenu();
		case 'cleanse':
			return getCleanseMenu();
		case 'enrich':
			return getEnrichmentMenu(user, flags);
		case 'discover':
			return getDiscoverMenu(user);
		case 'route':
			return getRouteMenu();
		case 'transform':
			return getSegmentationMenu();
		default:
			return null;
	}
}
