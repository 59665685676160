import { Identify } from '@amplitude/analytics-browser';
import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/react';
import { setAdaFields } from 'app/integrations/ada/ada';
import { initPendo } from 'app/integrations/pendo/pendo';
import axios from 'axios';

import { environment } from '../../../environments/environment';
import { mainInstance, proxyInstance } from '../../integrations/amplitude/amplitude';
import type { User } from '../types/user';
import cookie from '../utils/cookie';

import { authApi } from './auth';

interface AuthState {
	token: string | null;
	user: User;
	tokenIsRefreshing: boolean;
}

const initialState: AuthState = {
	token: cookie.get(`jwtToken${environment.serverName}`) || null,
	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	// @ts-ignore
	user: null,
	tokenIsRefreshing: false,
};
if (initialState.token) {
	axios.defaults.headers.common.Authorization = `JWT ${initialState.token}`;
}

function initUserWithToken({ token, user }: { token: string; user: User }) {
	const identify = new Identify()
		.set('userId', user.id)
		.set('Email', user.email)
		.set('First Name', user.first_name)
		.set('Last Name', user.last_name)
		.set('Account Id', user.organization.id);

	cookie.set(`jwtToken${environment.serverName}`, token);
	axios.defaults.headers.common.Authorization = `JWT ${token}`;
	mainInstance.identify(identify);
	proxyInstance.identify(identify);
	initPendo(user);
	Sentry.getCurrentScope().setUser({ email: user.email });
	setAdaFields(user);
}

function clearUser() {
	const identify = new Identify();

	cookie.remove(`jwtToken${environment.serverName}`);
	axios.defaults.headers.common.Authorization = null;
	mainInstance.identify(identify);
	proxyInstance.identify(identify);
	Sentry.getCurrentScope().setUser(null);
}

export const authSlice = createSlice({
	name: 'auth',
	initialState,
	reducers: {
		updateUser: (state, action: PayloadAction<Partial<User>>) => {
			if (state.user) {
				Object.assign(state.user, action.payload);
			}
		},
		logout: state => {
			state.token = null;
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			state.user = null;

			clearUser();
		},
	},
	extraReducers: builder => {
		builder.addMatcher(authApi.endpoints.obtainToken.matchFulfilled, (state, { payload }) => {
			state.token = payload.token;
			state.user = payload.user;

			initUserWithToken(payload);
		});
		builder.addMatcher(authApi.endpoints.socialLogin.matchFulfilled, (state, { payload }) => {
			state.token = payload.token;
			state.user = payload.user;

			initUserWithToken(payload);
		});
		builder.addMatcher(authApi.endpoints.loginChangePassword.matchFulfilled, (state, { payload }) => {
			state.token = payload.token;
			state.user = payload.user;

			initUserWithToken(payload);
		});
		builder.addMatcher(authApi.endpoints.register.matchFulfilled, (state, { payload }) => {
			state.token = payload.token;
			state.user = payload.user;

			initUserWithToken(payload);
		});
		builder.addMatcher(authApi.endpoints.refreshToken.matchFulfilled, (state, { payload }) => {
			state.token = payload.token;
			state.user = payload.user;

			initUserWithToken(payload);
		});
		builder.addMatcher(authApi.endpoints.refreshToken.matchRejected, state => {
			state.token = null;
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			state.user = null;

			clearUser();
		});
		builder.addMatcher(authApi.endpoints.refreshToken.matchPending, state => {
			state.tokenIsRefreshing = true;
		});
		builder.addMatcher(authApi.endpoints.refreshToken.matchFulfilled, state => {
			state.tokenIsRefreshing = false;
		});
		builder.addMatcher(authApi.endpoints.refreshToken.matchRejected, state => {
			state.tokenIsRefreshing = false;
		});
		builder.addMatcher(authApi.endpoints.changePassword.matchFulfilled, (state, { payload }) => {
			state.token = payload.token;
			state.user = payload.user;

			initUserWithToken(payload);
		});
		builder.addMatcher(authApi.endpoints.maskLogin.matchFulfilled, (state, { payload }) => {
			state.token = payload.token;
			state.user = payload.user;

			initUserWithToken(payload);
		});
		builder.addMatcher(authApi.endpoints.maskLogout.matchFulfilled, (state, { payload }) => {
			state.token = payload.token;
			state.user = payload.user;

			initUserWithToken(payload);
		});
		builder.addMatcher(authApi.endpoints.updateCurrentUser.matchFulfilled, (state, { payload }) => {
			if (state.user) {
				Object.assign(state.user, payload);
			}
		});
		builder.addMatcher(authApi.endpoints.logout.matchPending, state => {
			state.token = null;
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			state.user = null;
		});
		builder.addMatcher(authApi.endpoints.logout.matchFulfilled, () => {
			clearUser();
		});
		builder.addMatcher(authApi.endpoints.logout.matchRejected, () => {
			clearUser();
		});
	},
});

export const { updateUser, logout } = authSlice.actions;

export default authSlice.reducer;
