import { LOCATION_CHANGE } from 'app/Router';
import concat from 'lodash-es/concat';
import includes from 'lodash-es/includes';
import map from 'lodash-es/map';
import reject from 'lodash-es/reject';
import unionBy from 'lodash-es/unionBy';

import { RoutingWorkFlowConstants } from '../Constants';

const initialState = {
	workflows: [],
	fetching: true,
	locked: false,
	errors: [],
};

export default (state = initialState, action = {}) => {
	switch (action.type) {
		case LOCATION_CHANGE:
			if (
				(includes(action.payload.location.pathname, 'resolution') ||
					(includes(action.payload.location.pathname, '/routing/workflows/') &&
						action.payload.location.pathname !== '/routing/workflows/')) &&
				!includes(action.payload.location.pathname, 'auth')
			) {
				return state;
			}

			return initialState;

		case RoutingWorkFlowConstants.WORKFLOW_REQUEST:
			return { ...state, fetching: true };

		case RoutingWorkFlowConstants.WORKFLOW_LIST_SUCCESS:
			return { ...state, workflows: action.data, fetching: false };

		case RoutingWorkFlowConstants.WORKFLOW_LIST_FAIL:
			return { ...state, workflows: [], fetching: false };

		case RoutingWorkFlowConstants.WORKFLOW_BULK_CREATE:
		case RoutingWorkFlowConstants.WORKFLOW_CREATE:
			return { ...state, locked: true };

		case RoutingWorkFlowConstants.WORKFLOW_BULK_CREATE_SUCCESS:
			return { ...state, locked: false };

		case RoutingWorkFlowConstants.WORKFLOW_CREATE_SUCCESS:
			return { ...state, workflows: concat([], state.workflows, [action.data]), locked: false };

		case RoutingWorkFlowConstants.WORKFLOW_BULK_CREATE_FAIL:
			return { ...state, errors: action, locked: false };

		case RoutingWorkFlowConstants.WORKFLOW_CREATE_FAIL:
			return { ...state, locked: false };

		case RoutingWorkFlowConstants.WORKFLOW_RETRIEVE_SUCCESS:
			return { ...state, workflows: unionBy(state.workflows, [action.data], 'id'), fetching: false };

		case RoutingWorkFlowConstants.WORKFLOW_RETRIEVE_FAIL:
			return { ...state, fetching: false };

		case RoutingWorkFlowConstants.WORKFLOW_UPDATE_SUCCESS:
			return {
				...state,
				workflows: map(state.workflows, workflow =>
					workflow.id === action.data.id ? { ...workflow, ...action.data } : workflow
				),
			};

		case RoutingWorkFlowConstants.WORKFLOW_UPDATE_FAIL:
			return state;

		case RoutingWorkFlowConstants.WORKFLOW_DELETE_SUCCESS:
			return { ...state, workflows: reject(state.workflows, { id: action.id }) };

		case RoutingWorkFlowConstants.WORKFLOW_DELETE_FAIL:
			return state;

		default:
			return state;
	}
};
