import { Route, Routes } from 'react-router';
import useHasFeatureAccess from 'app/admin/hooks/useHasFeatureAccess';
import ForbiddenPage from 'ringlead_utils/components/ForbiddenPage';
import NotFoundPage from 'ringlead_utils/components/NotFoundPage';
import RingLeadLoadable from 'ringlead_utils/components/RingLeadLoadable/RingLeadLoadable';

import RoundRobinRoutes from './RoundRobinRoutes';
import RoutingWorkflowRoutes from './RoutingWorkflowRoutes';
import RoutingWorkingHoursRoutes from './RoutingWorkingHoursRoutes';
import TaskRoutes from './TaskRoutes';

const RoutingLog = RingLeadLoadable({
	loader: () => import('../../routing/components/activity/RoutingLog'),
});

const RoutingLicensing = RingLeadLoadable({
	loader: () => import('../../routing/components/licensing/RoutingLicensing'),
});

const RoutingTeam = RingLeadLoadable({
	loader: () => import('../../routing/components/teams/List'),
});

const RoutingTimeOff = RingLeadLoadable({
	loader: () => import('../../routing/components/timeoff/List'),
});

function RoutingRoutes() {
	const hasPermissions = useHasFeatureAccess(['ROUTING'], null);

	if (!hasPermissions) {
		return <ForbiddenPage features={['ROUTING']} />;
	}

	return (
		<Routes>
			<Route path="workflows/*" element={<RoutingWorkflowRoutes />} />
			<Route path="logs" element={<RoutingLog />} />
			<Route path="licensing" element={<RoutingLicensing />} />
			<Route path="working-hours/*" element={<RoutingWorkingHoursRoutes />} />
			<Route path="time-off" element={<RoutingTimeOff />} />
			<Route path="team" element={<RoutingTeam />} />
			<Route path="round-robin/*" element={<RoundRobinRoutes />} />
			<Route path="top-off/*" element={<TaskRoutes features={['ROUTING']} permission="read:top_off_queue" />} />
			<Route path="*" element={<NotFoundPage />} />
		</Routes>
	);
}

export default RoutingRoutes;
