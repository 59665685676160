import { LOCATION_CHANGE } from 'app/Router';

import { ResolutionSettingsConstants } from '../Constants';

const initialState = {
	configureFieldsPopup: false,
};

export default (state = initialState, action = {}) => {
	switch (action.type) {
		case LOCATION_CHANGE:
			return state;

		case ResolutionSettingsConstants.CONFIGURE_FIELDS_POPUP_SHOW:
			return { ...state, configureFieldsPopup: true };

		case ResolutionSettingsConstants.CONFIGURE_FIELDS_POPUP_CLOSE:
		case ResolutionSettingsConstants.UPDATE_RESOLUTION_SETTINGS:
			return { ...state, configureFieldsPopup: false };

		default:
			return state;
	}
};
