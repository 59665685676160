import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';

const StyledAppBar = styled(AppBar)(({ theme }) => ({
	backgroundColor: theme.palette.background.paper,
	top: 'auto',
	bottom: 0,
	zIndex: 1,
}));

function Footer() {
	return (
		<StyledAppBar elevation={0} position="fixed">
			<Toolbar variant="dense">
				<Box display="flex" flex={1} justifyContent="flex-end" alignItems="center">
					<Typography color="textSecondary" variant="caption" sx={{ mr: 2 }}>
						Version: 2.0.0. Build: {process.env.NX_PUBLIC_BUILD || '-'}
					</Typography>
					<Typography color="textPrimary" variant="subtitle2">
						&copy; Copyright RingLead, Inc. {new Date().getFullYear()}
					</Typography>
				</Box>
			</Toolbar>
		</StyledAppBar>
	);
}

export default Footer;
