import { type ChangeEvent, useEffect, useState } from 'react';
import TextField, { type TextFieldProps } from '@mui/material/TextField';
import { useDebouncedCallback } from 'use-debounce';

export type DebounceInputProps = TextFieldProps & { wait?: number };

function DebounceInput({ value, wait, ...props }: DebounceInputProps) {
	const [val, setVal] = useState<TextFieldProps['value']>(value);

	const debounced = useDebouncedCallback((e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
		props.onChange?.(e);
	}, wait ?? 2000);

	useEffect(() => {
		setVal(value);
	}, [value]);

	useEffect(
		() => () => {
			debounced.flush();
		},
		[debounced]
	);

	const onChange = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
		let newValue = e.target.value;
		if (props.type === 'number') {
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			const min = props.slotProps?.htmlInput?.min;
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			const max = props.slotProps?.htmlInput?.max;

			if (min && newValue < min) {
				newValue = min;
			}
			if (max && newValue > max) {
				newValue = max;
			}
		}

		setVal(newValue);
		debounced(e);
	};

	return <TextField {...props} {...(value !== undefined ? { value: val } : {})} onChange={onChange} />;
}

export default DebounceInput;
