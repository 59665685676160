import { useSelector } from 'react-redux';
import type { BasePermissionObject } from 'app/admin/types/permissions';
import type { RootState } from 'app/utils/stores/store-tmp-type';

import { hasFeatureAccess } from '../helpers/AccessHelper';

function useHasFeatureAccess(
	features: string[] | null,
	permission: string | null,
	object: BasePermissionObject | null = null
) {
	return useSelector((state: RootState) => hasFeatureAccess(state.auth.user, features, permission, object));
}

export default useHasFeatureAccess;
