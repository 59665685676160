import { LOCATION_CHANGE } from 'app/Router';
import find from 'lodash-es/find';
import includes from 'lodash-es/includes';
import map from 'lodash-es/map';

import { FieldMappingConstants } from '../Constants';

const initialState = {
	fieldMapping: [],
	fetching: true,
};

export default (state = initialState, action = {}) => {
	switch (action.type) {
		case LOCATION_CHANGE:
			if (
				includes(action.payload.location.pathname, 'resolution') &&
				!includes(action.payload.location.pathname, 'auth')
			) {
				return state;
			}

			return initialState;

		case FieldMappingConstants.FILE_FIELD_MAPPING_LIST_SUCCESS:
			return { ...state, fieldMapping: action.data, fetching: false };

		case FieldMappingConstants.FILE_FIELD_MAPPING_LIST_FAIL:
			return { ...state, fieldMapping: [], fetching: false };

		case FieldMappingConstants.FILE_FIELD_MAPPING_UPDATE_SUCCESS:
			return {
				...state,
				fieldMapping: map(state.fieldMapping, fm => {
					const mapping = find(action.data, ['id', fm.id]);

					return mapping ? { ...fm, ...mapping } : fm;
				}),
			};

		case FieldMappingConstants.FILE_FIELD_MAPPING_UPDATE_FAIL:
			return state;

		default:
			return state;
	}
};
