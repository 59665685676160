import ContactSupportOutlinedIcon from '@mui/icons-material/ContactSupportOutlined';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';
import { bindMenu, bindTrigger, usePopupState } from 'material-ui-popup-state/hooks';
import { HELP_CENTER_URL } from 'ringlead_utils/HelpCenterConstants';

import ModalRenew from './ModalRenew';

function SupportMenu() {
	const supportPopupState = usePopupState({ variant: 'popover', popupId: 'supportMenu', disableAutoFocus: true });
	const modalPopupState = usePopupState({ variant: 'dialog' });

	return (
		<>
			<Tooltip title="Support" enterDelay={300}>
				<IconButton id="nav-support" color="inherit" size="large" {...bindTrigger(supportPopupState)}>
					<ContactSupportOutlinedIcon />
				</IconButton>
			</Tooltip>
			<Menu id="support-menu" {...bindMenu(supportPopupState)} onClick={supportPopupState.close}>
				<MenuItem {...bindTrigger(modalPopupState)}>Renew License</MenuItem>
				<MenuItem component="a" href={HELP_CENTER_URL} target="_blank" rel="noopener noreferrer">
					Help Center
				</MenuItem>
				<MenuItem
					component="a"
					href="https://www.zoominfo.com/about/contact"
					target="_blank"
					rel="noopener noreferrer"
				>
					Support/Suggestion
				</MenuItem>
			</Menu>
			<ModalRenew popupState={modalPopupState} />
		</>
	);
}

export default SupportMenu;
