import { Route, Routes } from 'react-router';
import { useLocation } from 'react-router-dom';
import useHasFeatureAccess from 'app/admin/hooks/useHasFeatureAccess';
import ForbiddenPage from 'ringlead_utils/components/ForbiddenPage';
import NotFoundPage from 'ringlead_utils/components/NotFoundPage';
import RingLeadLoadable from 'ringlead_utils/components/RingLeadLoadable/RingLeadLoadable';

const ConnectionList = RingLeadLoadable({
	loader: () => import('../../settings/components/connections/ConnectionList'),
});

const ConnectionDetails = RingLeadLoadable({
	loader: () => import('../../settings/components/connections/details/ConnectionDetails'),
});

function ConnectionsBasicRoutes() {
	const { pathname } = useLocation();
	const connectionType = pathname.split('/')[2];
	const permissionName = `read:${connectionType}_connection`;
	const featureName = `${connectionType.toUpperCase()}_INTEGRATION`;
	const hasPermissions = useHasFeatureAccess([featureName], permissionName);

	if (!hasPermissions) {
		return <ForbiddenPage features={[featureName]} permissions={[permissionName]} />;
	}

	return (
		<Routes>
			<Route index element={<ConnectionList />} />
			<Route path=":connectionId/*" element={<ConnectionDetails />} />
			<Route path="*" element={<NotFoundPage />} />
		</Routes>
	);
}

export default (
	<>
		<Route path="salesforce/*" element={<ConnectionsBasicRoutes />} />
		<Route path="marketo/*" element={<ConnectionsBasicRoutes />} />
		<Route path="pardot/*" element={<ConnectionsBasicRoutes />} />
		<Route path="eloqua/*" element={<ConnectionsBasicRoutes />} />
		<Route path="hubspot/*" element={<ConnectionsBasicRoutes />} />
		<Route path="dynamics/*" element={<ConnectionsBasicRoutes />} />
		<Route path="snowflake/*" element={<ConnectionsBasicRoutes />} />
		<Route path="*" element={<NotFoundPage />} />
	</>
);
