import { Route, Routes } from 'react-router';
import useHasFeatureAccess from 'app/admin/hooks/useHasFeatureAccess';
import ForbiddenPage from 'app/utils/components/ForbiddenPage';
import NotFoundPage from 'ringlead_utils/components/NotFoundPage';
import RingLeadLoadable from 'ringlead_utils/components/RingLeadLoadable/RingLeadLoadable';

const NormalizeTemplatesPage = RingLeadLoadable({
	loader: () => import('app/normalize/components/NormalizeTemplatesPage'),
});

const NormalizeTemplateDetailsPage = RingLeadLoadable({
	loader: () => import('app/normalize/components/NormalizeTemplateDetailsPage'),
});

function NormalizeRoutes() {
	const hasPermissions = useHasFeatureAccess(['NORMALIZATION'], 'read:template');

	if (!hasPermissions) {
		return <ForbiddenPage features={['NORMALIZATION']} permissions={['read:template']} />;
	}

	return (
		<Routes>
			<Route index element={<NormalizeTemplatesPage />} />
			<Route path=":id" element={<NormalizeTemplateDetailsPage />} />
			<Route path="*" element={<NotFoundPage />} />
		</Routes>
	);
}

export default NormalizeRoutes;
