import { LOCATION_CHANGE } from 'app/Router';
import concat from 'lodash-es/concat';
import filter from 'lodash-es/filter';
import find from 'lodash-es/find';
import includes from 'lodash-es/includes';
import map from 'lodash-es/map';

import { ResolutionConstants } from '../Constants';

const initialState = {
	fetching: true,
	fetchingRecords: true,
	records: [],
	recordsCount: 0,
	recordsNext: false,
};

export default (state = initialState, action = {}) => {
	switch (action.type) {
		case LOCATION_CHANGE:
			if (
				includes(action.payload.location.pathname, 'resolution') &&
				!includes(action.payload.location.pathname, 'auth')
			) {
				return state;
			}

			return initialState;

		case ResolutionConstants.RECORDS_LIST:
			return { ...state, fetchingRecords: true };

		case ResolutionConstants.RECORDS_LIST_SUCCESS:
			return {
				...state,
				fetching: false,
				fetchingRecords: false,
				records: action.data.results || action.data,
				recordsCount: action.data.results ? action.data.count : 0,
				recordsNext: !!action.data.next || false,
			};

		case ResolutionConstants.RECORDS_LIST_FAIL:
			return {
				...state,
				fetching: false,
				fetchingRecords: false,
				records: [],
				recordsCount: 0,
				recordsNext: false,
			};

		case ResolutionConstants.RECORDS_GET_SUCCESS:
			return {
				...state,
				fetching: false,
				fetchingRecords: false,
				records: includes(map(state.records, 'id'), action.data.id)
					? map(state.records, record =>
							action.data.id === record.id ? { ...record, ...action.data } : record
						)
					: concat([], state.records, action.data),
			};

		case ResolutionConstants.RECORDS_GET_FAIL:
			return { ...state, fetching: false, fetchingRecords: false };

		case ResolutionConstants.RECORDS_UPDATE_SUCCESS:
			return {
				...state,
				records: map(state.records, record =>
					action.data.id === record.id ? { ...record, ...action.data } : record
				),
			};

		case ResolutionConstants.RECORDS_UPDATE_FAIL:
			return state;

		case ResolutionConstants.RECORDS_SKIP_UNSKIP_SUCCESS:
			return {
				...state,
				records: filter(state.records, record => !includes(action.data, record.id)),
				recordsCount: state.recordsCount - action.data.length,
			};

		case ResolutionConstants.RECORDS_SKIP_UNSKIP_FAIL:
			return state;

		case ResolutionConstants.RECORDS_SKIP_MATCH_SUCCESS:
			const recordIds = action.object_name ? map(action.data, 'id') : [];

			return {
				...state,
				records: action.object_name
					? filter(state.records, record => !includes(recordIds, record.id))
					: map(state.records, record => find(action.data, ['id', record.id]) || record),
			};

		case ResolutionConstants.RECORDS_SKIP_MATCH_FAIL:
			return state;

		case ResolutionConstants.RECORDS_PROCESS_SUCCESS:
			const clusterObject = action.cluster_object === 'null' ? null : action.cluster_object;

			return {
				...state,
				records: map(state.records, record =>
					includes(action.data, record.id) ||
					(action.cluster_object && record.cluster_object === clusterObject)
						? { ...record, status: action.status }
						: record
				),
			};

		case ResolutionConstants.RECORDS_PROCESS_FAIL:
			return state;

		case ResolutionConstants.RECORDS_PROCESS_ALL_SUCCESS:
			return {
				...state,
				records: map(state.records, record => ({ ...record, status: action.status })),
			};

		case ResolutionConstants.RECORDS_PROCESS_ALL_FAIL:
			return state;

		case ResolutionConstants.RECORDS_SKIP_SUCCESS:
			return { ...state, records: [], recordsCount: 0, recordsNext: false };

		case ResolutionConstants.RECORDS_SKIP_FAIL:
			return state;

		default:
			return state;
	}
};
