import { LOCATION_CHANGE } from 'app/Router';
import find from 'lodash-es/find';
import includes from 'lodash-es/includes';
import isArray from 'lodash-es/isArray';
import map from 'lodash-es/map';

import { ReportConstants } from '../Constants';

const initialState = {
	reports: [],
	reportsCount: 0,
	report: {},
	fetching: true,
};

export default (state = initialState, action = {}) => {
	switch (action.type) {
		case LOCATION_CHANGE:
			if (
				includes(action.payload.location.pathname, 'resolution') &&
				!includes(action.payload.location.pathname, 'auth')
			) {
				return state;
			}

			return initialState;

		case ReportConstants.REPORT_LIST_SUCCESS:
			return {
				...state,
				fetching: false,
				reports: action.data.results || action.data,
				reportsCount: action.data.results ? action.data.count : 0,
			};

		case ReportConstants.REPORT_LIST_FAIL:
			return { ...state, fetching: false, reports: [], reportsCount: 0 };

		case ReportConstants.REPORT_GET:
			return { ...state, report: {}, fetching: true };

		case ReportConstants.REPORT_GET_SUCCESS:
			return { ...state, report: action.data, fetching: false };

		case ReportConstants.REPORT_GET_FAIL:
			return { ...state, report: {}, fetching: false };

		case ReportConstants.REPORT_ACTION_SUCCESS:
		case ReportConstants.REPORT_UPDATE_SUCCESS: {
			const reports = isArray(action.data) ? action.data : [action.data];
			return {
				...state,
				reports: map(state.reports, report => {
					const actReport = find(reports, ['id', report.id]);
					return actReport ? { ...report, ...actReport } : report;
				}),
			};
		}

		default:
			return state;
	}
};
