import { LOCATION_CHANGE } from 'app/Router';
import concat from 'lodash-es/concat';
import filter from 'lodash-es/filter';
import find from 'lodash-es/find';
import includes from 'lodash-es/includes';
import map from 'lodash-es/map';
import pickBy from 'lodash-es/pickBy';

import { UpdateRulesConstants } from '../Constants';

const initialState = {
	updateRules: [],
	fetching: true,
	errors: {},
};

export default (state = initialState, action = {}) => {
	switch (action.type) {
		case LOCATION_CHANGE:
			return initialState;

		case UpdateRulesConstants.LOAD_UPDATEVALUERULES_SUCCESS:
			return { ...state, updateRules: action.data, fetching: false };

		case UpdateRulesConstants.LOAD_UPDATEVALUERULES_FAIL:
			return { ...state, updateRules: [], fetching: false };

		case UpdateRulesConstants.CREATE_UPDATEVALUERULE_SUCCESS:
			return {
				...state,
				updateRules: map(state.updateRules, updateRule =>
					updateRule.tmpId === action.rule.tmpId ? { ...updateRule, ...action.data } : updateRule
				),
				errors: pickBy(state.errors, (_v, k) => k !== action.rule.tmpId),
			};

		case UpdateRulesConstants.CREATE_UPDATEVALUERULE_FAIL:
			return {
				...state,
				updateRules: includes(map(state.updateRules, 'tmpId'), action.rule.tmpId)
					? map(state.updateRules, updateRule => {
							const actionRule = find(action.filters, ['tmpId', updateRule.tmpId]);

							return actionRule ? { ...updateRule, ...actionRule } : updateRule;
						})
					: concat([], state.updateRules, action.rule),
				errors: { ...state.errors, [action.rule.tmpId]: action.data },
			};

		case UpdateRulesConstants.PATCH_UPDATEVALUERULE_SUCCESS:
			return {
				...state,
				updateRules: map(state.updateRules, updateRule =>
					updateRule.id === action.pk ? { ...updateRule, ...action.data } : updateRule
				),
				errors: pickBy(state.errors, (_v, k) => k !== action.pk.toString()),
			};

		case UpdateRulesConstants.PATCH_UPDATEVALUERULE_FAIL:
			return {
				...state,
				updateRules: map(state.updateRules, updateRule =>
					updateRule.id === action.pk ? { ...updateRule, ...action.rule } : updateRule
				),
				errors: { ...state.errors, [action.pk]: action.data },
			};

		case UpdateRulesConstants.DELETE_UPDATEVALUERULE_SUCCESS:
			return {
				...state,
				updateRules: filter(
					state.updateRules,
					updateRule => updateRule.id !== action.pk && updateRule.tmpId !== action.pk
				),
				errors: pickBy(state.errors, (_v, k) => k !== action.pk.toString()),
			};

		case UpdateRulesConstants.DELETE_UPDATEVALUERULE_FAIL:
			return state;

		default:
			return state;
	}
};
