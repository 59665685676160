export const EnrichmentConstants = {
	PACKAGES_GET_SUCCESS: 'PACKAGES_GET_SUCCESS',
	PACKAGES_GET_FAIL: 'PACKAGES_GET_FAIL',
	TASK_ENRICH_FIELDS_GET_SUCCESS: 'TASK_ENRICH_FIELDS_GET_SUCCESS',
	TASK_ENRICH_FIELDS_GET_FAIL: 'TASK_ENRICH_FIELDS_GET_FAIL',
	TASK_ENRICHMENT_MAPPINGS_GET_SUCCESS: 'TASK_ENRICHMENT_MAPPINGS_GET_SUCCESS',
	TASK_ENRICHMENT_MAPPINGS_GET_FAIL: 'TASK_ENRICHMENT_MAPPINGS_GET_FAIL',
	TASK_ENRICHMENT_MAPPINGS_UPDATE_SUCCESS: 'TASK_ENRICHMENT_MAPPINGS_UPDATE_SUCCESS',
	TASK_ENRICHMENT_MAPPINGS_UPDATE_FAIL: 'TASK_ENRICHMENT_MAPPINGS_UPDATE_FAIL',
};

export const TaskConstants = {
	MAPPED_OBJECTS_GET: 'MAPPED_OBJECTS_GET',
	MAPPED_OBJECTS_GET_SUCCESS: 'MAPPED_OBJECTS_GET_SUCCESS',
	MAPPED_OBJECTS_GET_FAIL: 'MAPPED_OBJECTS_GET_FAIL',
	FILE_FIELDS_GET: 'FILE_FIELDS_GET',
	FILE_FIELDS_GET_SUCCESS: 'FILE_FIELDS_GET_SUCCESS',
	FILE_FIELDS_GET_FAIL: 'FILE_FIELDS_GET_FAIL',
};

export const NormalizerRuleConstants = {
	NORMALIZER_FIELDS_GET: 'NORMALIZER_FIELDS_GET',
	NORMALIZER_FIELDS_GET_SUCCESS: 'NORMALIZER_FIELDS_GET_SUCCESS',
	NORMALIZER_FIELDS_GET_FAIL: 'NORMALIZER_FIELDS_GET_FAIL',
};

export const TaskFiltersConstants = {
	LIST_FILTER_SUCCESS: 'LIST_FILTER_SUCCESS',
	LIST_FILTER_FAIL: 'LIST_FILTER_FAIL',
	SYNC_FILTER_SUCCESS: 'SYNC_FILTER_SUCCESS',
	SYNC_FILTER_FAIL: 'SYNC_FILTER_FAIL',
	CREATE_FILTER_SUCCESS: 'CREATE_FILTER_SUCCESS',
	CREATE_FILTER_FAIL: 'CREATE_FILTER_FAIL',
	UPDATE_FILTER_SUCCESS: 'UPDATE_FILTER_SUCCESS',
	UPDATE_FILTER_FAIL: 'UPDATE_FILTER_FAIL',
	REORDER_FILTERS: 'REORDER_FILTERS',
	REORDER_FILTERS_SUCCESS: 'REORDER_FILTERS_SUCCESS',
	REORDER_FILTERS_FAIL: 'REORDER_FILTERS_FAIL',
	DELETE_FILTER_SUCCESS: 'DELETE_FILTER_SUCCESS',
	DELETE_FILTER_FAIL: 'DELETE_FILTER_FAIL',
	COUNT_RECORDS_FILTER: 'COUNT_RECORDS_FILTER',
	COUNT_RECORDS_FILTER_SUCCESS: 'COUNT_RECORDS_FILTER_SUCCESS',
	COUNT_RECORDS_FILTER_FAIL: 'COUNT_RECORDS_FILTER_FAIL',
};

export const MatchingRulesConstants = {
	LOAD_MATCHING_RULES_SUCCESS: 'LOAD_MATCHING_RULES_SUCCESS',
	LOAD_MATCHING_RULES_FAIL: 'LOAD_MATCHING_RULES_FAIL',
	SYNC_MATCHING_RULES_SUCCESS: 'SYNC_MATCHING_RULES_SUCCESS',
	SYNC_MATCHING_RULES_FAIL: 'SYNC_MATCHING_RULES_FAIL',
	PATCH_MATCHING_RULE_SUCCESS: 'PATCH_MATCHING_RULE_SUCCESS',
	PATCH_MATCHING_RULE_FAIL: 'PATCH_MATCHING_RULE_FAIL',
	REORDER_MATCHING_RULES: 'REORDER_MATCHING_RULES',
	REORDER_MATCHING_RULES_SUCCESS: 'REORDER_MATCHING_RULES_SUCCESS',
	REORDER_MATCHING_RULES_FAIL: 'REORDER_MATCHING_RULES_FAIL',
	CREATE_MATCHING_RULE_SUCCESS: 'CREATE_MATCHING_RULE_SUCCESS',
	CREATE_MATCHING_RULE_FAIL: 'CREATE_MATCHING_RULE_FAIL',
	DELETE_MATCHING_RULE_SUCCESS: 'DELETE_MATCHING_RULE_SUCCESS',
	DELETE_MATCHING_RULE_FAIL: 'DELETE_MATCHING_RULE_FAIL',
};

export const MasterRecordRulesConstants = {
	LOAD_MASTER_RECORD_RULES_SUCCESS: 'LOAD_MASTER_RECORD_RULES_SUCCESS',
	LOAD_MASTER_RECORD_RULES_FAIL: 'LOAD_MASTER_RECORD_RULES_FAIL',
	SYNC_MASTER_RECORD_RULES_SUCCESS: 'SYNC_MASTER_RECORD_RULES_SUCCESS',
	SYNC_MASTER_RECORD_RULES_FAIL: 'SYNC_MASTER_RECORD_RULES_FAIL',
	CREATE_MASTER_RECORD_RULE_SUCCESS: 'CREATE_MASTER_RECORD_RULE_SUCCESS',
	CREATE_MASTER_RECORD_RULE_FAIL: 'CREATE_MASTER_RECORD_RULE_FAIL',
	PATCH_MASTER_RECORD_RULE_SUCCESS: 'PATCH_MASTER_RECORD_RULE_SUCCESS',
	PATCH_MASTER_RECORD_RULE_FAIL: 'PATCH_MASTER_RECORD_RULE_FAIL',
	REORDER_MASTER_RECORD_RULES: 'REORDER_MASTER_RECORD_RULES',
	REORDER_MASTER_RECORD_RULES_SUCCESS: 'REORDER_MASTER_RECORD_RULES_SUCCESS',
	REORDER_MASTER_RECORD_RULES_FAIL: 'REORDER_MASTER_RECORD_RULES_FAIL',
	DELETE_MASTER_RECORD_RULE_SUCCESS: 'DELETE_MASTER_RECORD_RULE_SUCCESS',
	DELETE_MASTER_RECORD_RULE_FAIL: 'DELETE_MASTER_RECORD_RULE_FAIL',
};

export const SurvivingFieldValueRulesConstants = {
	LOAD_SURVIVING_FIELD_VALUE_RULES_SUCCESS: 'LOAD_SURVIVING_FIELD_VALUE_RULES_SUCCESS',
	LOAD_SURVIVING_FIELD_VALUE_RULES_FAIL: 'LOAD_SURVIVING_FIELD_VALUE_RULES_FAIL',
	SYNC_SURVIVING_FIELD_VALUE_RULES_SUCCESS: 'SYNC_SURVIVING_FIELD_VALUE_RULES_SUCCESS',
	SYNC_SURVIVING_FIELD_VALUE_RULES_FAIL: 'SYNC_SURVIVING_FIELD_VALUE_RULES_FAIL',
	CREATE_SURVIVING_FIELD_VALUE_RULE_SUCCESS: 'CREATE_SURVIVING_FIELD_VALUE_RULE_SUCCESS',
	CREATE_SURVIVING_FIELD_VALUE_RULE_FAIL: 'CREATE_SURVIVING_FIELD_VALUE_RULE_FAIL',
	PATCH_SURVIVING_FIELD_VALUE_RULE_SUCCESS: 'PATCH_SURVIVING_FIELD_VALUE_RULE_SUCCESS',
	PATCH_SURVIVING_FIELD_VALUE_RULE_FAIL: 'PATCH_SURVIVING_FIELD_VALUE_RULE_FAIL',
	REORDER_SURVIVING_FIELD_VALUE_RULES: 'REORDER_SURVIVING_FIELD_VALUE_RULES',
	REORDER_SURVIVING_FIELD_VALUE_RULES_SUCCESS: 'REORDER_SURVIVING_FIELD_VALUE_RULES_SUCCESS',
	REORDER_SURVIVING_FIELD_VALUE_RULES_FAIL: 'REORDER_SURVIVING_FIELD_VALUE_RULES_FAIL',
	DELETE_SURVIVING_FIELD_VALUE_RULE_SUCCESS: 'DELETE_SURVIVING_FIELD_VALUE_RULE_SUCCESS',
	DELETE_SURVIVING_FIELD_VALUE_RULE_FAIL: 'DELETE_SURVIVING_FIELD_VALUE_RULE_FAIL',
};

export const GroupFiltersConstants = {
	LOAD_GROUP_FILTERS_SUCCESS: 'LOAD_GROUP_FILTERS_SUCCESS',
	LOAD_GROUP_FILTERS_FAIL: 'LOAD_GROUP_FILTERS_FAIL',
	SYNC_GROUP_FILTERS_SUCCESS: 'SYNC_GROUP_FILTERS_SUCCESS',
	SYNC_GROUP_FILTERS_FAIL: 'SYNC_GROUP_FILTERS_FAIL',
	CREATE_GROUP_FILTER_SUCCESS: 'CREATE_GROUP_FILTER_SUCCESS',
	CREATE_GROUP_FILTER_FAIL: 'CREATE_GROUP_FILTER_FAIL',
	PATCH_GROUP_FILTER_SUCCESS: 'PATCH_GROUP_FILTER_SUCCESS',
	PATCH_GROUP_FILTER_FAIL: 'PATCH_GROUP_FILTER_FAIL',
	REORDER_GROUP_FILTERS: 'REORDER_GROUP_FILTERS',
	REORDER_GROUP_FILTERS_SUCCESS: 'REORDER_GROUP_FILTERS_SUCCESS',
	REORDER_GROUP_FILTERS_FAIL: 'REORDER_GROUP_FILTERS_FAIL',
	DELETE_GROUP_FILTER_SUCCESS: 'DELETE_GROUP_FILTER_SUCCESS',
	DELETE_GROUP_FILTER_FAIL: 'DELETE_GROUP_FILTER_FAIL',
};

export const UpdateRulesConstants = {
	LOAD_UPDATEVALUERULES_SUCCESS: 'LOAD_UPDATEVALUERULES_SUCCESS',
	LOAD_UPDATEVALUERULES_FAIL: 'LOAD_UPDATEVALUERULES_FAIL',
	CREATE_UPDATEVALUERULE_SUCCESS: 'CREATE_UPDATEVALUERULE_SUCCESS',
	CREATE_UPDATEVALUERULE_FAIL: 'CREATE_UPDATEVALUERULE_FAIL',
	PATCH_UPDATEVALUERULE_SUCCESS: 'PATCH_UPDATEVALUERULE_SUCCESS',
	PATCH_UPDATEVALUERULE_FAIL: 'PATCH_UPDATEVALUERULE_FAIL',
	DELETE_UPDATEVALUERULE_SUCCESS: 'DELETE_UPDATEVALUERULE_SUCCESS',
	DELETE_UPDATEVALUERULE_FAIL: 'DELETE_UPDATEVALUERULE_FAIL',
};

export const FormulaConstants = {
	LOAD_FORMULAS_SUCCESS: 'LOAD_FORMULAS_SUCCESS',
	LOAD_FORMULAS_FAIL: 'LOAD_FORMULAS_FAIL',
	VALIDATE_FORMULA_SUCCESS: 'VALIDATE_FORMULA_SUCCESS',
	VALIDATE_FORMULA_FAIL: 'VALIDATE_FORMULA_FAIL',
	VALIDATE_FORMULA_RESET: 'VALIDATE_FORMULA_RESET',
};

export const ReportConstants = {
	REPORT_LIST_SUCCESS: 'REPORT_LIST_SUCCESS',
	REPORT_LIST_FAIL: 'REPORT_LIST_FAIL',
	REPORT_GET: 'REPORT_GET',
	REPORT_GET_SUCCESS: 'REPORT_GET_SUCCESS',
	REPORT_GET_FAIL: 'REPORT_GET_FAIL',
	REPORT_ACTION_SUCCESS: 'REPORT_ACTION_SUCCESS',
	REPORT_ACTION_FAIL: 'REPORT_ACTION_FAIL',
	REPORT_UPDATE_SUCCESS: 'REPORT_UPDATE_SUCCESS',
};

export const ResolutionConstants = {
	RECORDS_LIST: 'RECORDS_LIST',
	RECORDS_LIST_SUCCESS: 'RECORDS_LIST_SUCCESS',
	RECORDS_LIST_FAIL: 'RECORDS_LIST_FAIL',
	RECORDS_GET_SUCCESS: 'RECORDS_GET_SUCCESS',
	RECORDS_GET_FAIL: 'RECORDS_GET_FAIL',
	RECORDS_UPDATE_SUCCESS: 'RECORDS_UPDATE_SUCCESS',
	RECORDS_UPDATE_FAIL: 'RECORDS_UPDATE_FAIL',
	RECORDS_SKIP_UNSKIP_SUCCESS: 'RECORDS_SKIP_UNSKIP_SUCCESS',
	RECORDS_SKIP_UNSKIP_FAIL: 'RECORDS_SKIP_UNSKIP_FAIL',
	RECORDS_SKIP_MATCH_SUCCESS: 'RECORDS_SKIP_MATCH_SUCCESS',
	RECORDS_SKIP_MATCH_FAIL: 'RECORDS_SKIP_MATCH_FAIL',
	RECORDS_PROCESS_SUCCESS: 'RECORDS_PROCESS_SUCCESS',
	RECORDS_PROCESS_FAIL: 'RECORDS_PROCESS_FAIL',
	RECORDS_PROCESS_ALL_SUCCESS: 'RECORDS_PROCESS_ALL_SUCCESS',
	RECORDS_PROCESS_ALL_FAIL: 'RECORDS_PROCESS_ALL_FAIL',
	RECORDS_SKIP_SUCCESS: 'RECORDS_SKIP_SUCCESS',
	RECORDS_SKIP_FAIL: 'RECORDS_SKIP_FAIL',
};

export const ResolutionSettingsConstants = {
	GET_RESOLUTION_SETTINGS: 'GET_RESOLUTION_SETTINGS',
	UPDATE_RESOLUTION_SETTINGS: 'UPDATE_RESOLUTION_SETTINGS',
	CONFIGURE_FIELDS_POPUP_SHOW: 'CONFIGURE_FIELDS_POPUP_SHOW',
	CONFIGURE_FIELDS_POPUP_CLOSE: 'CONFIGURE_FIELDS_POPUP_CLOSE',
};

export const FieldMappingConstants = {
	FILE_FIELD_MAPPING_LIST_SUCCESS: 'FILE_FIELD_MAPPING_LIST_SUCCESS',
	FILE_FIELD_MAPPING_LIST_FAIL: 'FILE_FIELD_MAPPING_LIST_FAIL',
	FILE_FIELD_MAPPING_SUBMIT: 'FILE_FIELD_MAPPING_SUBMIT',
	FILE_FIELD_MAPPING_UPDATE_SUCCESS: 'FILE_FIELD_MAPPING_UPDATE_SUCCESS',
	FILE_FIELD_MAPPING_UPDATE_FAIL: 'FILE_FIELD_MAPPING_UPDATE_FAIL',
};

export const DiscoveryConstants = {
	DISCOVERY_SEARCH_RESULT: 'DISCOVERY_SEARCH_RESULT',
	DISCOVERY_SEARCH_RESULT_SUCCESS: 'DISCOVERY_SEARCH_RESULT_SUCCESS',
	DISCOVERY_SEARCH_RESULT_FAIL: 'DISCOVERY_SEARCH_RESULT_FAIL',
	DISCOVERY_GET_METADATA_SUCCESS: 'DISCOVERY_GET_METADATA_SUCCESS',
	DISCOVERY_GET_METADATA_FAIL: 'DISCOVERY_GET_METADATA_FAIL',
	DISCOVERY_EXPORT_TO_LIST_IMPORT: 'DISCOVERY_EXPORT_TO_LIST_IMPORT',
	DISCOVERY_EXPORT_TO_LIST_IMPORT_SUCCESS: 'DISCOVERY_EXPORT_TO_LIST_IMPORT_SUCCESS',
	DISCOVERY_EXPORT_TO_LIST_IMPORT_FAIL: 'DISCOVERY_EXPORT_TO_LIST_IMPORT_FAIL',
	DISCOVERY_GET_LIST_IMPORT_TASKS: 'DISCOVERY_GET_LIST_IMPORT_TASKS',
	DISCOVERY_GET_LIST_IMPORT_TASKS_SUCCESS: 'DISCOVERY_GET_LIST_IMPORT_TASKS_SUCCESS',
	DISCOVERY_GET_LIST_IMPORT_TASKS_FAIL: 'DISCOVERY_GET_LIST_IMPORT_TASKS_FAIL',
	DISCOVERY_GET_FILTERS: 'DISCOVERY_GET_FILTERS',
	DISCOVERY_GET_FILTERS_SUCCESS: 'DISCOVERY_GET_FILTERS_SUCCESS',
	DISCOVERY_GET_FILTERS_FAIL: 'DISCOVERY_GET_FILTERS_FAIL',
	DISCOVERY_GET_FILTER_SUCCESS: 'DISCOVERY_GET_FILTER_SUCCESS',
	DISCOVERY_GET_FILTER_FAIL: 'DISCOVERY_GET_FILTER_FAIL',
	DISCOVERY_TMP_FILTER_CREATE: 'DISCOVERY_TMP_FILTER_CREATE',
	DISCOVERY_TMP_VALID_FILTER_CREATE: 'DISCOVERY_TMP_VALID_FILTER_CREATE',
	DISCOVERY_FILTER_CREATE: 'DISCOVERY_FILTER_CREATE',
	DISCOVERY_FILTER_CREATE_SUCCESS: 'DISCOVERY_FILTER_CREATE_SUCCESS',
	DISCOVERY_FILTER_CREATE_FAIL: 'DISCOVERY_FILTER_CREATE_FAIL',
	DISCOVERY_TMP_FILTER_UPDATE: 'DISCOVERY_TMP_FILTER_UPDATE',
	DISCOVERY_TMP_VALID_FILTER_UPDATE: 'DISCOVERY_TMP_VALID_FILTER_UPDATE',
	DISCOVERY_FILTER_UPDATE_SUCCESS: 'DISCOVERY_FILTER_UPDATE_SUCCESS',
	DISCOVERY_FILTER_UPDATE_FAIL: 'DISCOVERY_FILTER_UPDATE_FAIL',
	DISCOVERY_TMP_FILTERS_UPDATE: 'DISCOVERY_TMP_FILTERS_UPDATE',
	DISCOVERY_FILTERS_ORDER_UPDATE: 'DISCOVERY_FILTERS_ORDER_UPDATE',
	DISCOVERY_FILTERS_ORDER_UPDATE_SUCCESS: 'DISCOVERY_FILTERS_ORDER_UPDATE_SUCCESS',
	DISCOVERY_FILTERS_ORDER_UPDATE_FAIL: 'DISCOVERY_FILTERS_ORDER_UPDATE_FAIL',
	DISCOVERY_FILTERS_UPDATE_SUCCESS: 'DISCOVERY_FILTERS_UPDATE_SUCCESS',
	DISCOVERY_FILTERS_UPDATE_FAIL: 'DISCOVERY_FILTERS_UPDATE_FAIL',
	DISCOVERY_TMP_FILTER_DELETE: 'DISCOVERY_TMP_FILTER_DELETE',
	DISCOVERY_FILTER_DELETE_SUCCESS: 'DISCOVERY_FILTER_DELETE_SUCCESS',
	DISCOVERY_FILTER_DELETE_FAIL: 'DISCOVERY_FILTER_DELETE_FAIL',
	DISCOVERY_ALL_FILTERS_DELETE_SUCCESS: 'DISCOVERY_ALL_FILTERS_DELETE_SUCCESS',
	DISCOVERY_ALL_FILTERS_DELETE_FAIL: 'DISCOVERY_ALL_FILTERS_DELETE_FAIL',
	DISCOVERY_SET_PARENTS_FILTERS: 'DISCOVERY_SET_PARENTS_FILTERS',
	DISCOVERY_UPDATE_NESTED_BOOLEAN_LOGIC: 'DISCOVERY_UPDATE_NESTED_BOOLEAN_LOGIC',
	DISCOVERY_SET_NESTED_BOOLEAN_LOGIC: 'DISCOVERY_SET_NESTED_BOOLEAN_LOGIC',
	DISCOVERY_GET_VENDOR_DATA: 'DISCOVERY_GET_VENDOR_DATA',
	DISCOVERY_GET_VENDOR_DATA_SUCCESS: 'DISCOVERY_GET_VENDOR_DATA_SUCCESS',
	DISCOVERY_GET_VENDOR_DATA_FAIL: 'DISCOVERY_GET_VENDOR_DATA_FAIL',
	DISCOVERY_DATA_REBUILD: 'DISCOVERY_DATA_REBUILD',
	DISCOVERY_DATA_REBUILD_SUCCESS: 'DISCOVERY_DATA_REBUILD_SUCCESS',
	DISCOVERY_DATA_REBUILD_FAIL: 'DISCOVERY_DATA_REBUILD_FAIL',
	DISCOVERY_DATA_SEARCH_RESULTS_REBUILD_SUCCESS: 'DISCOVERY_DATA_SEARCH_RESULTS_REBUILD_SUCCESS',
	DISCOVERY_DATA_SEARCH_RESULTS_REBUILD_FAIL: 'DISCOVERY_DATA_SEARCH_RESULTS_REBUILD_FAIL',
	DISCOVERY_GET_RECENT_FILTERS_SUCCESS: 'DISCOVERY_GET_RECENT_FILTERS_SUCCESS',
	DISCOVERY_GET_RECENT_FILTERS_FAIL: 'DISCOVERY_GET_RECENT_FILTERS_FAIL',
	DISCOVERY_ENRICH_RECORDS_SUCCESS: 'DISCOVERY_ENRICH_RECORDS_SUCCESS',
	DISCOVERY_ENRICH_RECORDS_FAIL: 'DISCOVERY_ENRICH_RECORDS_FAIL',
	DISCOVERY_GET_USED_IELISTS_SUCCESS: 'DISCOVERY_GET_USED_IELISTS_SUCCESS',
	DISCOVERY_GET_USED_IELISTS_FAIL: 'DISCOVERY_GET_USED_IELISTS_FAIL',
	DISCOVERY_UPDATE_USED_IELISTS_SUCCESS: 'DISCOVERY_UPDATE_USED_IELISTS_SUCCESS',
	DISCOVERY_UPDATE_USED_IELISTS_FAIL: 'DISCOVERY_UPDATE_USED_IELISTS_FAIL',
	DISCOVERY_GET_IELISTS: 'DISCOVERY_GET_IELISTS',
	DISCOVERY_GET_IELISTS_SUCCESS: 'DISCOVERY_GET_IELISTS_SUCCESS',
	DISCOVERY_GET_IELISTS_FAIL: 'DISCOVERY_GET_IELISTS_FAIL',
	DISCOVERY_RETRIEVE_IELIST_SUCCESS: 'DISCOVERY_RETRIEVE_IELIST_SUCCESS',
	DISCOVERY_RETRIEVE_IELIST_FAIL: 'DISCOVERY_RETRIEVE_IELIST_FAIL',
	DISCOVERY_CREATE_IELIST_SUCCESS: 'DISCOVERY_CREATE_IELIST_SUCCESS',
	DISCOVERY_CREATE_IELIST_FAIL: 'DISCOVERY_CREATE_IELIST_FAIL',
	DISCOVERY_UPDATE_IELIST_SUCCESS: 'DISCOVERY_UPDATE_IELIST_SUCCESS',
	DISCOVERY_UPDATE_IELIST_FAIL: 'DISCOVERY_UPDATE_IELIST_FAIL',
	DISCOVERY_DELETE_IELIST_SUCCESS: 'DISCOVERY_DELETE_IELIST_SUCCESS',
	DISCOVERY_DELETE_IELIST_FAIL: 'DISCOVERY_DELETE_IELIST_FAIL',
	DISCOVERY_COPY_IELIST_SUCCESS: 'DISCOVERY_COPY_IELIST_SUCCESS',
	DISCOVERY_COPY_IELIST_FAIL: 'DISCOVERY_COPY_IELIST_FAIL',
	DISCOVERY_GET_CSV_HEADERS: 'DISCOVERY_GET_CSV_HEADERS',
	DISCOVERY_GET_CSV_HEADERS_SUCCESS: 'DISCOVERY_GET_CSV_HEADERS_SUCCESS',
	DISCOVERY_GET_CSV_HEADERS_FAIL: 'DISCOVERY_GET_CSV_HEADERS_FAIL',
	DISCOVERY_IMPORT_IELIST_SUCCESS: 'DISCOVERY_IMPORT_IELIST_SUCCESS',
	DISCOVERY_IMPORT_IELIST_FAIL: 'DISCOVERY_IMPORT_IELIST_FAIL',
	DISCOVERY_IMPORT_CSV_SUCCESS: 'DISCOVERY_IMPORT_CSV_SUCCESS',
	DISCOVERY_IMPORT_CSV_FAIL: 'DISCOVERY_IMPORT_CSV_FAIL',
	DISCOVERY_IMPORT_SF_SUCCESS: 'DISCOVERY_IMPORT_SF_SUCCESS',
	DISCOVERY_IMPORT_SF_FAIL: 'DISCOVERY_IMPORT_SF_FAIL',
	DISCOVERY_GET_IELIST_ITEMS: 'DISCOVERY_GET_IELIST_ITEMS',
	DISCOVERY_GET_IELIST_ITEMS_SUCCESS: 'DISCOVERY_GET_IELIST_ITEMS_SUCCESS',
	DISCOVERY_GET_IELIST_ITEMS_FAIL: 'DISCOVERY_GET_IELIST_ITEMS_FAIL',
	DISCOVERY_CREATE_IELIST_ITEM_SUCCESS: 'DISCOVERY_CREATE_IELIST_ITEM_SUCCESS',
	DISCOVERY_CREATE_IELIST_ITEM_FAIL: 'DISCOVERY_CREATE_IELIST_ITEM_FAIL',
	DISCOVERY_UPDATE_IELIST_ITEM_SUCCESS: 'DISCOVERY_UPDATE_IELIST_ITEM_SUCCESS',
	DISCOVERY_UPDATE_IELIST_ITEM_FAIL: 'DISCOVERY_UPDATE_IELIST_ITEM_FAIL',
	DISCOVERY_DELETE_IELIST_ITEM_SUCCESS: 'DISCOVERY_DELETE_IELIST_ITEM_SUCCESS',
	DISCOVERY_DELETE_IELIST_ITEM_FAIL: 'DISCOVERY_DELETE_IELIST_ITEM_FAIL',
	DISCOVERY_COPY_IELIST_ITEM_SUCCESS: 'DISCOVERY_COPY_IELIST_ITEM_SUCCESS',
	DISCOVERY_COPY_IELIST_ITEM_FAIL: 'DISCOVERY_COPY_IELIST_ITEM_FAIL',
	DISCOVERY_IMPORT_SUBMIT_FORM: 'DISCOVERY_IMPORT_SUBMIT_FORM',
	DISCOVERY_GET_LINKED_TASKS_SUCCESS: 'DISCOVERY_GET_LINKED_TASKS_SUCCESS',
	DISCOVERY_GET_LINKED_TASKS_FAIL: 'DISCOVERY_GET_LINKED_TASKS_FAIL',
	DISCOVERY_GET_TOTAL_SUPPRESSED_SUCCESS: 'DISCOVERY_GET_TOTAL_SUPPRESSED_SUCCESS',
	DISCOVERY_GET_TOTAL_SUPPRESSED_FAIL: 'DISCOVERY_GET_TOTAL_SUPPRESSED_FAIL',
	DISCOVERY_CANCEL_GET_TOTAL_SUPPRESSED_SUCCESS: 'DISCOVERY_CANCEL_GET_TOTAL_SUPPRESSED_SUCCESS',
	DISCOVERY_CANCEL_GET_TOTAL_SUPPRESSED_FAIL: 'DISCOVERY_CANCEL_GET_TOTAL_SUPPRESSED_FAIL',
	DISCOVERY_UPDATE_FETCHING_TOTAL_SUPPRESSED: 'DISCOVERY_UPDATE_FETCHING_TOTAL_SUPPRESSED',
	DISCOVERY_IELIST_SET_PREVIEW_MODE: 'DISCOVERY_IELIST_SET_PREVIEW_MODE',
};

export const ModalConstants = {
	DO_NOT_SHOW: 'DO_NOT_SHOW',
};

export const VisibilitySensorConstants = {
	SCROLLING: 'SCROLLING',
};

export const MeetingSchedulerConstants = {
	MEETING_SCHEDULER_REQUEST: 'MEETING_SCHEDULER_REQUEST',
	MEETING_SCHEDULER_SETTINGS_CREATE_SUCCESS: 'MEETING_SCHEDULER_SETTINGS_CREATE_SUCCESS',
	MEETING_SCHEDULER_SETTINGS_CREATE_FAIL: 'MEETING_SCHEDULER_SETTINGS_CREATE_FAIL',
	MEETING_SCHEDULER_RETRIEVE_SUCCESS: 'MEETING_SCHEDULER_RETRIEVE_SUCCESS',
	MEETING_SCHEDULER_RETRIEVE_FAIL: 'MEETING_SCHEDULER_RETRIEVE_FAIL',
	MEETING_SCHEDULER_UPDATE_SUCCESS: 'MEETING_SCHEDULER_UPDATE_SUCCESS',
	MEETING_SCHEDULER_UPDATE_FAIL: 'MEETING_SCHEDULER_UPDATE_FAIL',
	MEETING_SCHEDULER_CALENDAR_INVITE_SUCCESS: 'MEETING_SCHEDULER_CALENDAR_INVITE_SUCCESS',
	MEETING_SCHEDULER_CALENDAR_INVITE_FAIL: 'MEETING_SCHEDULER_CALENDAR_INVITE_FAIL',
	MEETING_SCHEDULER_TEAM_MEMBER_SUCCESS: 'MEETING_SCHEDULER_TEAM_MEMBER_SUCCESS',
	MEETING_SCHEDULER_TEAM_MEMBER_FAIL: 'MEETING_SCHEDULER_TEAM_MEMBER_FAIL',
	MEETING_SCHEDULER_FETCH_FORM_FIELDS_SUCCESS: 'MEETING_SCHEDULER_FETCH_FORM_FIELDS_SUCCESS',
	MEETING_SCHEDULER_FETCH_FORM_FIELDS_FAIL: 'MEETING_SCHEDULER_FETCH_FORM_FIELDS_FAIL',
};

export const EventsConstants = {
	GET_TASK_EVENTS: 'GET_TASK_EVENTS',
	GET_TASK_EVENTS_SUCCESS: 'GET_TASK_EVENTS_SUCCESS',
	GET_TASK_EVENTS_FAIL: 'GET_TASK_EVENTS_FAIL',
	CREATE_EVENT_SUCCESS: 'CREATE_EVENT_SUCCESS',
	CREATE_EVENT_FAIL: 'CREATE_EVENT_FAIL',
	COPY_EVENT_SUCCESS: 'COPY_EVENT_SUCCESS',
	COPY_EVENT_FAIL: 'COPY_EVENT_FAIL',
	UPDATE_EVENT_SUCCESS: 'UPDATE_EVENT_SUCCESS',
	UPDATE_EVENT_FAIL: 'UPDATE_EVENT_FAIL',
	DELETE_EVENT_SUCCESS: 'DELETE_EVENT_SUCCESS',
	DELETE_EVENT_FAIL: 'DELETE_EVENT_FAIL',
	DELETE_EVENTS_SUCCESS: 'DELETE_EVENTS_SUCCESS',
	DELETE_EVENTS_FAIL: 'DELETE_EVENTS_FAIL',
	GET_EVENT_SOURCES: 'GET_EVENT_SOURCES',
	GET_EVENT_SOURCES_SUCCESS: 'GET_EVENT_SOURCES_SUCCESS',
	GET_EVENT_SOURCES_FAIL: 'GET_EVENT_SOURCES_FAIL',
	GET_EVENT_ACTION_TYPES: 'GET_EVENT_ACTION_TYPES',
	GET_EVENT_ACTION_TYPES_SUCCESS: 'GET_EVENT_ACTION_TYPES_SUCCESS',
	GET_EVENT_ACTION_TYPES_FAIL: 'GET_EVENT_ACTION_TYPES_FAIL',
	GET_EVENT_NOTIFICATION_TYPES: 'GET_EVENT_NOTIFICATION_TYPES',
	GET_EVENT_NOTIFICATION_TYPES_SUCCESS: 'GET_EVENT_NOTIFICATION_TYPES_SUCCESS',
	GET_EVENT_NOTIFICATION_TYPES_FAIL: 'GET_EVENT_NOTIFICATION_TYPES_FAIL',
	GET_EVENT_CREATE_RECORD_TYPES: 'GET_EVENT_CREATE_RECORD_TYPES',
	GET_EVENT_CREATE_RECORD_TYPES_SUCCESS: 'GET_EVENT_CREATE_RECORD_TYPES_SUCCESS',
	GET_EVENT_CREATE_RECORD_TYPES_FAIL: 'GET_EVENT_CREATE_RECORD_TYPES_FAIL',
	GET_EVENT_TRIGGERS: 'GET_EVENT_TRIGGERS',
	GET_EVENT_TRIGGERS_SUCCESS: 'GET_EVENT_TRIGGERS_SUCCESS',
	GET_EVENT_TRIGGERS_FAIL: 'GET_EVENT_TRIGGERS_FAIL',
	GET_EVENT_MAPPING_TARGET_FIELDS: 'GET_EVENT_MAPPING_TARGET_FIELDS',
	GET_EVENT_MAPPING_TARGET_FIELDS_SUCCESS: 'GET_EVENT_MAPPING_TARGET_FIELDS_SUCCESS',
	GET_EVENT_MAPPING_TARGET_FIELDS_FAIL: 'GET_EVENT_MAPPING_TARGET_FIELDS_FAIL',
	GET_EVENT_USE_EXISTING_FIELDS: 'GET_EVENT_USE_EXISTING_FIELDS',
	GET_EVENT_CONDITIONS: 'GET_EVENT_CONDITIONS',
	GET_EVENT_CONDITIONS_SUCCESS: 'GET_EVENT_CONDITIONS_SUCCESS',
	GET_EVENT_CONDITIONS_FAIL: 'GET_EVENT_CONDITIONS_FAIL',
	GET_EVENT_ENRICH_CONDITIONS: 'GET_EVENT_ENRICH_CONDITIONS',
	GET_EVENT_ENRICH_CONDITIONS_SUCCESS: 'GET_EVENT_ENRICH_CONDITIONS_SUCCESS',
	GET_EVENT_ENRICH_CONDITIONS_FAIL: 'GET_EVENT_ENRICH_CONDITIONS_FAIL',
	GET_EVENT_CHANGED_FIELD_CONDITIONS: 'GET_EVENT_CHANGED_FIELD_CONDITIONS',
	GET_EVENT_CHANGED_FIELD_CONDITIONS_SUCCESS: 'GET_EVENT_CHANGED_FIELD_CONDITIONS_SUCCESS',
	GET_EVENT_CHANGED_FIELD_CONDITIONS_FAIL: 'GET_EVENT_CHANGED_FIELD_CONDITIONS_FAIL',
	CREATE_TMP_CONDITION: 'CREATE_TMP_CONDITION',
	CREATE_CONDITION_SUCCESS: 'CREATE_CONDITION_SUCCESS',
	CREATE_CONDITION_FAIL: 'CREATE_CONDITION_FAIL',
	CREATE_CHANGED_FIELD_CONDITION_SUCCESS: 'CREATE_CHANGED_FIELD_CONDITION_SUCCESS',
	CREATE_CHANGED_FIELD_CONDITION_FAIL: 'CREATE_CHANGED_FIELD_CONDITION_FAIL',
	UPDATE_TMP_CONDITIONS: 'UPDATE_TMP_CONDITIONS',
	UPDATE_CONDITIONS_SUCCESS: 'UPDATE_CONDITIONS_SUCCESS',
	UPDATE_CONDITIONS_FAIL: 'UPDATE_CONDITIONS_FAIL',
	UPDATE_CHANGED_FIELD_CONDITIONS_SUCCESS: 'UPDATE_CHANGED_FIELD_CONDITIONS_SUCCESS',
	UPDATE_CHANGED_FIELD_CONDITIONS_FAIL: 'UPDATE_CHANGED_FIELD_CONDITIONS_FAIL',
	REMOVE_TMP_CONDITION: 'REMOVE_TMP_CONDITION',
	REMOVE_CONDITION_SUCCESS: 'REMOVE_CONDITION_SUCCESS',
	REMOVE_CONDITION_FAIL: 'REMOVE_CONDITION_FAIL',
	REMOVE_EVENT_CONDITIONS_SUCCESS: 'REMOVE_EVENT_CONDITIONS_SUCCESS',
	REMOVE_EVENT_CONDITIONS_FAIL: 'REMOVE_EVENT_CONDITIONS_FAIL',
	REMOVE_CHANGED_FIELD_CONDITION_SUCCESS: 'REMOVE_CHANGED_FIELD_CONDITION_SUCCESS',
	REMOVE_CHANGED_FIELD_CONDITION_FAIL: 'REMOVE_CHANGED_FIELD_CONDITION_FAIL',
	GET_EVENT_DYNAMIC_FIELDS_SUCCESS: 'GET_EVENT_DYNAMIC_FIELDS_SUCCESS',
	GET_EVENT_DYNAMIC_FIELDS_FAIL: 'GET_EVENT_DYNAMIC_FIELDS_FAIL',
	GET_EVENT_DISCOVERY_RELATED_SEARCH_RESULT: 'GET_EVENT_DISCOVERY_RELATED_SEARCH_RESULT',
	GET_EVENT_DISCOVERY_RELATED_SEARCH_RESULT_SUCCESS: 'GET_EVENT_DISCOVERY_RELATED_SEARCH_RESULT_SUCCESS',
	GET_EVENT_DISCOVERY_RELATED_SEARCH_RESULT_FAIL: 'GET_EVENT_DISCOVERY_RELATED_SEARCH_RESULT_FAIL',
	GET_EVENT_DISCOVERY_RELATED_DESCRIBE: 'GET_EVENT_DISCOVERY_RELATED_DESCRIBE',
	GET_EVENT_DISCOVERY_RELATED_DESCRIBE_SUCCESS: 'GET_EVENT_DISCOVERY_RELATED_DESCRIBE_SUCCESS',
	GET_EVENT_DISCOVERY_RELATED_DESCRIBE_FAIL: 'GET_EVENT_DISCOVERY_RELATED_DESCRIBE_FAIL',
	GET_EVENT_DISCOVERY_TASKS: 'GET_EVENT_DISCOVERY_TASKS',
	GET_EVENT_DISCOVERY_TASKS_SUCCESS: 'GET_EVENT_DISCOVERY_TASKS_SUCCESS',
	GET_EVENT_DISCOVERY_TASKS_FAIL: 'GET_EVENT_DISCOVERY_TASKS_FAIL',
	GET_EVENT_WEBSUBMISSION_TASKS: 'GET_EVENT_WEBSUBMISSION_TASKS',
	GET_EVENT_WEBSUBMISSION_TASKS_SUCCESS: 'GET_EVENT_WEBSUBMISSION_TASKS_SUCCESS',
	GET_EVENT_WEBSUBMISSION_TASKS_FAIL: 'GET_EVENT_WEBSUBMISSION_TASKS_FAIL',
	GET_EVENT_LIST_IMPORT_TASKS: 'GET_EVENT_LIST_IMPORT_TASKS',
	GET_EVENT_LIST_IMPORT_TASKS_SUCCESS: 'GET_EVENT_LIST_IMPORT_TASKS_SUCCESS',
	GET_EVENT_LIST_IMPORT_TASKS_FAIL: 'GET_EVENT_LIST_IMPORT_TASKS_FAIL',
	USE_EVENTS_TEMPLATE_SUCCESS: 'USE_EVENTS_TEMPLATE_SUCCESS',
	USE_EVENTS_TEMPLATE_FAIL: 'USE_EVENTS_TEMPLATE_FAIL',
};
